.el-main-container {
  width: 359px;
  flex-shrink: 0;

  overflow: hidden;

  z-index: 991;
  background: var(--grey-grey-900);
  border-radius: 14px;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px rgba(37, 14, 14, 0.32),
    120px 90px 150px rgba(37, 14, 14, 0.28),
    271px 202px 203px rgba(37, 14, 14, 0.16),
    481px 359px 240px rgba(37, 14, 14, 0.05),
    752px 561px 263px rgba(37, 14, 14, 0.01);
}
.el-main-container.open {
  /* right: 40px; */
}
.energy-ledger-wrapper {
  width: 100%;
  border-radius: 12px;
  background-image: url("../../images/energy-ledger-noise.png");
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.el-main-container .close {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.28571px;
  gap: 6.43px;
  width: 18px;
  height: 18px;
  background: #1c1c1c;
  border: 1px solid #444444;
  border-radius: 100%;

  cursor: pointer;
  z-index: 99999;
}
.el-main-container .close:hover {
  background: #121212;
}
.el-main-container .graph {
  width: 100%;
}
.el-wrapper {
  width: 100%;
}
.e-ledger-used {
  font-size: 1rem;
  color: var(--find);
  font-family: var(--primary-font-light);
  display: flex;
  align-items: flex-end;
  margin: 11px 0;
}
.e-ledger-used span {
  font-size: 11px;
  color: #757575;
  text-transform: uppercase;
  font-family: var(--primary-font);
}
.react-draggable-dragging {
  transition: unset;
}
.game-character-wrap {
  width: 100%;
  text-align: center;
  display: flex;
}
.game-character-wrap img {
  width: 100%;
  object-fit: contain;
}
.energy-mech-detail {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.energy-mech-detail h2 {
  color: var(--secondary-color);
  font-family: var(--primary-font);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  letter-spacing: 4.43px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.energy-mech-detail p {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: 0.12px;
  color: var(--secondary-color);
  font-family: var(--primary-font-regular);
}
.energy-ledger-divider {
  width: 100%;
  height: 1px;
  background-color: var(--grey-grey-700);
}

.energy-mech-performance-wrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 8px;
}
.emp-title {
  color: var(--secondary-color);
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: 0.12px;
}
.energy-mech-performance {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  border-radius: 4px;
  padding: 6px 4px;
  gap: 10px;
}
.emp-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.emp-row > .emp-row-title {
  flex: 0 0 70px;
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
  color: var(--secondary-color);
}
.emp-row > .emp-row-unit {
  flex: 0 0 60px;
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
  color: var(--secondary-color);
}
.emp-row > .emp-row-progress {
  flex: 1;
}
.emp-row > .emp-row-progress > .emp-progress {
  width: 100%;
  border-radius: 2px;
  height: 4px;
  background-color: #d9d9d9;
  overflow: hidden;
  position: relative;
}
.emp-row > .emp-row-progress > .emp-progress > .progress {
  height: 4px;
  border-radius: 2px;
  background: var(--status-green);
  position: relative;
  z-index: 1;
}
.emp-row > .emp-row-progress > .emp-progress > .emp-noise {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: url(../../images/noise.png) repeat 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}

.energy-ledger-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.height-exceed {
  height: 100%;
}
.el-main-container.height-exceed .energy-ledger-content-wrap {
  height: 100%;
}
.accordion-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  width: 100%;
}
.accordion-wrap {
  scrollbar-width: thin;
  scrollbar-color: transparent !important;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.accordion-wrap::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.accordion-wrap::-webkit-scrollbar-track {
  background-color: transparent;
}
.accordion-wrap::-webkit-scrollbar-thumb:vertical {
  background: transparent !important;
  border-radius: 10px;
}
.energy-ledger-content-wrap .accordion-wrap .accordion-toggle {
  border: 0px;
}

.pl-wrapper.pl-content-wrap.energy-ledger-content {
  background: var(--primary-color);
}

.ledger-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.energy-meter-wrap {
  padding: 4px 10px;
  background: rgba(0, 255, 133, 0.1);
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.em-icon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.em-text {
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: var(--status-green);
}
