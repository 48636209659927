.col.left{ width:305px; flex-shrink:0; flex-grow: 0; display: flex; flex-direction:column;padding-right:18px !important; align-items: flex-end; }
.stater-text{ font-size: 12px; color: var(--secondary-color); text-align: center; line-height: 1.65; text-transform: uppercase;}
 .col-left-inner{  -webkit-overflow-scrolling: touch;   overflow-y: scroll; width:100%;}
.left-bar-content-wrap{ width:100%;  }
.col-left-inner {
  scrollbar-width: thin;
  scrollbar-color: transparent !important;
  overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}


.col-left-inner::-webkit-scrollbar {
  width:0px;
  height: 0px;
}

.col-left-inner::-webkit-scrollbar-track {
  background-color:transparent;
}
.col-left-inner::-webkit-scrollbar-thumb:vertical{
  background: transparent !important;
  border-radius: 10px;

} 
.left-bar-content{ width:100%; height: 100%; display: flex; flex-direction: column; padding-top: 9px; position: relative; }


@media(max-width:1023px){
    .col.left{ position: absolute; top:0; width:390px; left: -100%; transition: all 0.3s ease; padding-bottom: 0 !important;}
    .col.left .left-bar-content{ opacity:0; transition:all 0.5s ease ; transition-delay: 0.6s; left:-100px; margin-left: -100%;}
    .col.left.open{left: 0;z-index: 666;}
    .col.left.open .left-bar-content{ opacity: 1; left:-3%;margin-left:0;}
    .left-bar-content{ width:300px}
}

@media(max-width:767px){
  .col.left{ position: absolute; top:0; width:100%; left: -100%; transition: all 0.3s ease; padding-bottom: 0 !important;}
  .col.left .left-bar-content{ opacity:0; transition:all 0.5s ease ; transition-delay: 0.6s; left:-100px; margin-left: -100%;}
  .col.left.open{left: 0;z-index: 666;}
  .col.left.open .left-bar-content{ opacity: 1; left:-3%;margin-left:0;}
  .left-bar-content{ width:300px}
}
