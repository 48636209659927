.progress-wrap-main{ width:100%;position: relative; margin: 3px 0;}
.progress-wrap{ width:100%; height:46px; background:#757575 url(../../images/noise.png) repeat 0 0 ; border-radius: 4px; position: relative;}
.progress-bar{height:46px;overflow: hidden;}
.progress-bar-bg{ width:279px;height:46px;border-radius: 4px; background-image: url(../../images/noise.png); background-repeat: repeat; background-position: 0 0; background: linear-gradient(270deg, #1F7E17 0%, #999C14 46.87%,#731111  100%)}
.line-wrapper{ width:100%; height: 100%; position: absolute;top:0;left:0;z-index:2; display: flex; justify-content: space-between; align-items: center;}
.line{ flex:0 0 1px; height:46px; background: #1C1C1C; opacity: 0.2; display: inline-block;}
.line:nth-child(1){ height: calc(46px - 4px);}
.line:nth-child(26){ height: calc(46px - 4px);}
.progress-indicator{ width:1px; height:calc(100% + 2px); display: inline-block; background: #fff; -webkit-box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.75); position: absolute; top:-1px;z-index: 3; border-radius: 10px;}
    .energy-indicator{ width:33px; height: 46px; background:rgb(255, 77, 77, 0.6); position: absolute; top:0;z-index: 1; display: flex; align-items: center; justify-content: flex-end;flex-wrap: nowrap;flex-direction: row;}
.energy-indicator2 {
    height: 30px;
    background: rgb(255, 77, 77, 0.6);
    position: absolute;
    top: 2px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
}
