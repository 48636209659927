
.report-container{ background: #A9A8A8 !important; position: relative;  width:calc(100% -  16px); height:calc(100% - 8px); margin:0 auto; border-radius: 20px; overflow: hidden; display: flex; justify-content: center;  background:#D4D4D4; align-items: center;}
.report-container .loader-container{ position: absolute !important;z-index: 1;}

.report-content-wrap{ width:100%; height: 100%; display: flex; justify-content: center; align-items: center;}

.report-page-heading{ position: fixed; top: 8.9775rem; left:4.5rem; z-index: 9;}
.grid-box{width:100%; height: 100%; border: 1px solid transparent; background: transparent; position: absolute; top:0; left: 0;z-index: 1; display: flex; justify-content: center; align-items: center;transition: all 0.3s ease;}
.grid-box > img{ opacity: 0;transition: all 0.3s ease;}
.grid-find{ border: 1px solid var(--status-green); background-color:rgba(0, 255, 133, 0.10);}
.grid-find:hover{ background: rgba(0, 255, 133, 0.70); }


.grid-nofind{ border: 1px solid #FF4D4D; background-color:rgba(250, 90, 90, 0.20);}
.grid-nofind:hover{ background: rgba(255, 77, 77, 0.70); }

.grid-restricted{ background: rgba(250, 90, 90, 0.08); }
.grid-restricted:hover{background: rgba(28, 28, 20, 0.70);}


.grid-unexplored:hover{background: rgba(170, 170, 170, 0.70);}

.grid-location{ width: auto; height:auto; display: flex; justify-content: space-between; align-items: center; position: absolute; bottom:-28px; background-color:transparent; border-radius: 20px;font-size: 12px;font-style: normal;font-weight: normal;line-height: normal;letter-spacing: 0.36px;text-transform: uppercase; font-family: var(--primary-font); color: var(--secondary-color); gap:10px; padding:4px 12px; border: 1px solid transparent; opacity: 0;transition: all 0.3s ease;}
.grid-location > *{ white-space: nowrap;}

.grid-find > .grid-location{box-shadow: 2px 2px 24px 0px rgba(0, 255, 133, 0.40);border: 1px solid rgba(0, 255, 133, 0.70);background-color: var(--primary-color)}
.grid-nofind > .grid-location{box-shadow: 2px 2px 24px 0px  rgba(255, 77, 77, 0.40);border: 1px solid rgba(255, 77, 77, 0.70);background-color: var(--primary-color)}
.grid-restricted> .grid-location{box-shadow: 2px 2px 24px 0px rgba(255, 77, 77, 1);border: 1px solid rgba(255, 77, 77, 0.70);background-color: #FF4D4D}
.grid-unexplored > .grid-location{box-shadow: 2px 2px 24px 0px rgba(170, 170, 170, 0.40);;border: 1px solid rgba(170, 170, 170, 0.70);background-color: var(--primary-color)}


.grid-find > .grid-location > .gl-area{ color: var(--status-green);}
.grid-nofind > .grid-location > .gl-area{ color: var(--nofind);}
.grid-restricted > .grid-location > .gl-area{ color: var(--primary-color);}
.grid-unexplored > .grid-location > .gl-area{ color: #AAAAAA;}


.grid-box:hover{ z-index: 3;}
.grid-box:hover > .grid-location{opacity: 1; }
.grid-box:hover > img{ opacity:1;}


/* Discovery Modal */
.discoveries-modal-wrapper{ width:100%; height: 100%; position: absolute; z-index:4; background: #A9A8A8;background: rgba(0, 0, 0, 0.50);backdrop-filter: blur(7px); display: flex; justify-content: center; align-items: center; }
.discoveries-modal{ max-width:728px; width:90%; display:flex; flex-direction: column; gap: 1.5rem;padding: 2rem;align-items: flex-start;border-radius: 8px;
border: 1px solid #9F9F9F;background: var(--primary-color);box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33), 30px 22px 83px 0px rgba(37, 14, 14, 0.32), 120px 90px 150px 0px rgba(37, 14, 14, 0.28), 271px 202px 203px 0px rgba(37, 14, 14, 0.16), 481px 359px 240px 0px rgba(37, 14, 14, 0.05), 752px 561px 263px 0px rgba(37, 14, 14, 0.01);}
.discoveries-options{ width:100%; height: auto; display: flex; justify-content: center; align-items: flex-start; gap: 4.8125rem;}
.d-box{ width:72px; height: 72px; display: flex; justify-content: center; align-items: center; border: 1px solid transparent; position: relative;}
.d-box.restricted{background: rgba(28, 28, 28, 0.70);}
.d-box.unexplored{background: rgba(170, 170, 170, 0.70);}
.d-box.nofind{background: rgba(255, 77, 77, 0.70);}
.d-box.find{background: rgba(0, 255, 133, 0.70);}
.d-box > .d-tag{ width: auto; height:auto; display: flex; justify-content: space-between; align-items: center; position: absolute; bottom:-28px; background-color:transparent; border-radius: 20px;font-size: 12px;font-style: normal;font-weight: normal;line-height: normal;letter-spacing: 0.36px;text-transform: uppercase; font-family: var(--primary-font); color: var(--secondary-color); gap:10px; padding:4px 12px; border: 1px solid transparent; white-space: nowrap; }

.d-box.find> .d-tag{box-shadow: 2px 2px 24px 0px rgba(0, 255, 133, 0.40);border: 1px solid rgba(0, 255, 133, 0.70);background-color: var(--primary-color)}
.d-box.nofind > .d-tag{box-shadow: 2px 2px 24px 0px  rgba(255, 77, 77, 0.40);border: 1px solid rgba(255, 77, 77, 0.70);background-color: var(--primary-color)}
.d-box.restricted > .d-tag{box-shadow: 2px 2px 24px 0px rgba(255, 77, 77, 1);border: 1px solid rgba(255, 77, 77, 0.70);background-color: #FF4D4D}
.d-box.unexplored > .d-tag{box-shadow: 2px 2px 24px 0px rgba(170, 170, 170, 0.40);;border: 1px solid rgba(170, 170, 170, 0.70);background-color: var(--primary-color)}


.dm-title{color: var(--secondary-color);text-align: center;text-shadow:0px 0px 6px rgba(255, 255, 255, 0.6);font-size: 4rem;font-family:var(--secondary-font);font-style: normal;font-weight: 700;line-height: 85%;letter-spacing: -0.64px;text-transform: uppercase; width: 100%; display: flex; justify-content: center; margin-top: 34px;}
.dm-info{ text-align: center;font-size: 14px;font-family: var(--primary-font-light);font-style: normal;font-weight: 400;line-height: 140%;letter-spacing: -0.07px; color: var(--secondary-color);  width: 100%;}
.dm-footer{ width: 100%; display: flex; flex-direction: column; gap: 8px;}
.dm-footer .btn{ background: transparent; border:1px solid var(--grey-grey-600)}
.dm-footer .btn:hover{background-color: var(--secondary-color); border-color: var(--secondary-color);}



/* Select Modal */
.select-modal-wrap{width:100%;  position: fixed; top:0; left: 0; z-index:1000; padding-top: 70px; padding-bottom: 4rem; overflow-y: auto; overflow-x: hidden; height: 100%;}
.select-modal-title{ width:100%; font-family:var(--secondary-font);font-style: normal;font-weight: normal;font-size: 4rem;text-align: center;letter-spacing: -0.01em; text-transform: uppercase;
  color: #FFFFFF;text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);line-height: 104%;}
.select-modal-backdrop{ width:100%; height: 100%;z-index: 999; position: fixed; top:0; left: 0;bottom:0; right: 0; background: rgba(0, 0, 0,0.70) ; -webkit-backdrop-filter: blur(60px);
backdrop-filter: blur(60px);}
.select-modal-wrap{-webkit-overflow-scrolling: touch;   overflow-y: scroll; width:100%;}
.select-modal-wrap {scrollbar-width: thin;scrollbar-color: #292828 !important;overflow: auto;
-ms-overflow-style: none; /* IE 11 */
scrollbar-width: none; /* Firefox 64 */}
.select-modal-wrap::-webkit-scrollbar {width: 6px; height: 6px;}
.select-modal-wrap::-webkit-scrollbar-track {background-color:#292828;}
.select-modal-wrap::-webkit-scrollbar-thumb:vertical{background: #0c0c0c  !important;border-radius: 10px;} 
.select-close-modal{display: flex;justify-content: center;align-items: center;padding: 1.28571px;gap: 6.43px;width: 24px;height: 24px;background: #1C1C1C;border: 1px solid #444444;border-radius: 100%;position: fixed;top: 1rem;right: 2rem;cursor: pointer;z-index: 999;overflow: hidden;}
.select-report-wrap{ max-width: 780px; width: 90%; margin:0 auto; margin-top: 3.375rem; display: flex; justify-content: space-between; gap:1.5rem; flex-wrap: wrap;}
.select-report-wrap > .select-report{ width:calc(50% - 1.5rem); border: 1px solid transparent;display: flex;padding: 1rem;align-items: center; gap: .9375rem;border-radius: 2px; cursor: pointer; transition: all 0.3s ease;}
.select-report-wrap > .select-report.find{border: 1px solid  rgba(0, 255, 133, 0.10);}
.select-report-wrap > .select-report.nofind{border: 1px solid  rgba(250, 90, 90, 0.20);}
.select-report-wrap > .select-report:hover.find{border: 1px solid var(--find); background:  rgba(0, 255, 133, 0.10);}
.select-report-wrap > .select-report:hover.nofind{border: 1px solid var(--nofind); background:  rgba(250, 90, 90, 0.20);;}


.sr-content{ flex: 1; display: flex; flex-direction: column; gap:5px;}
.sr-content-title{font-size:1rem;font-style: normal;font-weight:normal;line-height: normal;letter-spacing: 0.48px;text-transform: uppercase; font-family: var(--primary-font); color: var(--secondary-color);text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.60);}
.sr-content-date{font-family: var(--primary-font);font-size: 10px;font-style: normal;font-weight: 400;line-height: 104%;text-transform: uppercase;text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.60)}

.select-report-wrap > .select-report.nofind .sr-content-date{color: var(--nofind);}
.select-report-wrap > .select-report.find .sr-content-date{color: var(--find);}



@media(max-width:768px){
    .discoveries-options{ display: none;}
    .select-report-wrap > .select-report{ width:calc(50% - 1rem);}
    .sr-content-title{font-size:.875rem;}
}

@media(max-width:650px){
    .select-report-wrap > .select-report{ width:100%;}
}
