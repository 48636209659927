.ilyrium-content-wrap{ width:100%; height: auto; display: flex; justify-content: space-between; gap: 2rem; padding-bottom: 5rem;}

/*---Discoveries---*/
.ilyrium-discoveries{ flex:0 0 152px; display: flex; flex-direction: column; gap: 1.5rem;}
.ilyd-info-wrap{ width:100%; height: auto; display: flex; flex-direction: column; gap:6px}
.ilyd-title{ width:100%;font-family: var(--primary-font); font-size: 12px; color: var(--grey-grey-400); text-transform: uppercase;line-height: 100%;letter-spacing: -0.48px;}
.ily-detail-wrap{ width:100%; height: auto; display:flex; gap:8px;}
.ily-icon-wrap{ flex: 0 0 auto;}
.ily-icon-wrap svg{ fill: var(--status-green);}
.ily-detail{ flex:1; font-family: var(--primary-font); font-size: 1rem;line-height: 100%;text-transform: uppercase; color: var(--status-green);}

/*---Mine---*/
.ilyrium-mine{ flex:0 1 512px; display:flex; flex-direction: column; gap: 9px;}
.ily-mine-title{ font-size: 12px; font-family: var(--primary-font); color: var(--secondary-color);text-transform: uppercase;line-height: 100%;letter-spacing: -0.48px;}
.ily-mine-wrap{ width:100%; height: auto; display: flex; flex-direction: column; gap:1rem;}
.ily-mine-container{ width:100%;}
.ily-mine-wrapper{ width:100%; display: flex; flex-direction: column; gap:8px;}
.ily-mine-wrapper > .ily-mine-row{ width:100%;padding:2px; height: 22px; display: flex; flex-direction:row; align-items: center; font-size: .625rem; color:rgba(255,255,255,0.8); letter-spacing: -0.1px; border:1px solid transparent; cursor: default; gap:8px;line-height: 104%; position: relative; text-transform: uppercase;}
.ily-mine-wrapper > .ily-mine-row.ily-mine-header{ color:var(--grey-grey-600); text-transform: uppercase; font-size: 9px;}
.ily-mine-wrapper > .ily-mine-row.ily-mine-header > .ily-mine-depth{text-align: center;}
.ily-mine-wrapper > .ily-mine-row.ily-mine-header > .ily-mine-result{text-align: center;}
.ily-mine-wrapper > .ily-mine-row > .ily-mine-number{  flex: 0 0 45px; position: relative;}
.ily-mine-wrapper > .ily-mine-row > .ily-mine-status{  flex:0 0 75px;}
.ily-mine-wrapper > .ily-mine-row > .ily-mine-depth{  flex:0 0 36px; text-align: center; }
.ily-mine-wrapper > .ily-mine-row > .ily-mine-result{  flex:1;}
.ily-mine-wrapper > .ily-mine-row > .ily-mine-result > span.find{background: var(--find); text-align: center; display: block; padding:6px; line-height:100%; border-radius:2px; color: var(--primary-color); text-transform: uppercase;}

.ily-mine-wrapper > .ily-mine-row > .ily-mine-number:after {content: '';width: 1px;height: 100%;background: var(--grey-grey-600);position: absolute;top: 0;right: 0;}
.ily-mine-wrapper > .ily-mine-row.ily-mine-header > .ily-mine-number:after{ content: none;}

/*---Energy Ledger---*/
.ilyrium-energy-ledger{ flex:0 1 376px;display:flex; flex-direction: column; gap:9px;}
.ily-el-title{ font-size: 12px; font-family: var(--primary-font); color: var(--secondary-color);text-transform: uppercase;line-height: 100%;letter-spacing: -0.48px;}
.ily-energy-used{font-size: 1rem;color: var(--find);font-family: var(--primary-font-light);display: flex;align-items: flex-end;}
.ily-energy-used span {font-size: 11px;color: #757575;text-transform: uppercase;font-family: var(--primary-font); margin-left:2px;}

.ilyrium-energy-ledger-content{ width:100%; height: auto; display: flex; flex-direction: column; gap: 8px;}

.ily-el-content-wrap {background:var(--grey-grey-900);border: 1px solid #323232;border-radius: 2px;padding:2px; display: flex; flex-direction: column; gap: 8px;}
.ily-el-row{ width:100%; display: flex; justify-content: space-between; gap: 10px;display: flex; flex-direction:row; align-items: center; font-size: .625rem; color:rgba(255,255,255,0.8); letter-spacing: -0.1px; border:1px solid transparent; cursor: default; gap:8px;line-height: 104%; position: relative; text-transform: uppercase; padding:4px;}
.ily-el-row:hover {background: rgba(0, 255, 133, 0.10);border: 1px solid var(--find); border-radius: 4px}
.ily-el-row.ily-el-header {color: var(--grey-grey-600);text-transform: uppercase;font-size: 9px; padding: 0 !important;}
.ily-el-process{ flex: 0 1 51px;display: flex; gap: 5px; align-items: center;}
.ily-el-number{ flex: 0 1 56px;}
.ily-el-detail{ flex: 0 1 60px;}
.ily-el-usage{ flex: 0 1 65px; text-align: right;}
.ily-el-icon { width:10px; height: 11px; display: flex; justify-content: center; }
.ily-el-icon > div{width:10px; height: 11px;}
.ily-el-icon svg{fill:var(--secondary-color); }

.ily-gradient-bottom{ position: fixed; bottom: 0; left: 0; width: 100%; z-index: 2;background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%); height: 120px;}


@media(max-width:1023px){
    .ilyrium-content-wrap{ flex-wrap: wrap;}
    .ilyrium-energy-ledger{ flex:1 1 376px;}
    .ilyrium-mine{ flex:1 1 512px;}
    .ilyrium-discoveries{flex:1 1 100%; flex-direction: row; flex-wrap: wrap;}
    .ilyrium-discoveries > div{ width:calc(33% - 2rem);}
}

@media(max-width:600px){
    .ilyrium-discoveries{ justify-content: space-between;column-gap:0.5rem }
    .ilyrium-discoveries > div{ width:calc(50% - 0.5rem); }
}
