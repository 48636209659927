.is-mobile-view {
  display: none !important;
}
.is-dektop-view {
  display: block !important;
}
.landscape-main-container {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #d4d4d4;
  align-items: center;
}
.draggable-scroll-container {
  overflow: auto;
}
.landscape-container {
  transition: 0.6s all;
}

.landscape-container {
  margin: auto;
}
.landscape-container {
  width: 1278px;
  height: 852px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  transform: scale(1);
  overflow: auto;
}
.landscape-container.disable-origin {
  transform-origin: initial !important;
}
.cnt1 {
  width: 1458px !important;
  height: 972px !important;
  top: 22% !important;
}
.cnt2 {
  width: 1638px !important;
  height: 1092px !important;
  top: 30% !important;
  left: 5% !important;
}
.ln1 {
  width: 1458px !important;
  height: 972px !important;
}
.ln2 {
  width: 1638px !important;
  height: 1092px !important;
}
.landscape-container > .ruler {
  position: absolute;
  z-index: 2;
  display: flex;
}
.landscape-container > .ruler.top,
.ruler.bottom {
  width: 100%;
  height: 71px;
}
.landscape-container > .ruler.top {
  top: 0;
  left: 0;
}
.landscape-container > .ruler.bottom {
  bottom: 0;
  left: 0;
}
.landscape-container > .ruler.left {
  top: 0;
  left: 0;
}
.landscape-container > .ruler.right {
  top: 0;
  right: 0;
}
.landscape-container > .ruler.left,
.ruler.right {
  width: 71px;
  height: 100%;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}
.landscape-container > .ruler > .ruler-item {
  width: 71px;
  height: 71px;
  position: relative;
  color: var(--grey-grey-900);
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 8px;
  font-family: var(--primary-font-regular);
}
.ruler-item1 {
  width: 81px !important;
  height: 81px !important;
}
.ruler-item2 {
  width: 91px !important;
  height: 91px !important;
}
.landscape-container > .ruler.top > .ruler-item {
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}
.landscape-container > .ruler.left > .ruler-item {
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
.landscape-container > .ruler.right > .ruler-item {
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}
.landscape-container > .ruler.bottom > .ruler-item {
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
}
.landscape-container > .ruler.top > .ruler-item {
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}
.landscape-container > .ruler.left > .ruler-item::before,
.landscape-container > .ruler.right > .ruler-item::before {
  content: none !important;
}
.landscape-container > .ruler.right > .ruler-item::before {
  right: 0;
  left: unset;
}
.landscape-container > .ruler.top > .ruler-item::before,
.landscape-container > .ruler.bottom > .ruler-item::before {
  content: none !important;
}
.landscape-container > .ruler.bottom > .ruler-item::before {
  bottom: 0;
  top: unset;
}
.landscape-container > .ruler.top > .ruler-item:first-child::before,
.ruler.bottom > .ruler-item:first-child:before {
  content: none;
}
.landscape-container > .ruler.left > .ruler-item:last-child::before,
.ruler.right > .ruler-item:last-child:before {
  content: none;
}
.landscape-grid-container {
  width: 1136px;
  height: 710px;
  margin: auto;
  margin-top: 71px;
  position: relative;
}
.ld1 {
  width: 1296px !important;
  height: 810px !important;
  margin: auto;
  margin-top: 81px !important;
}
.ld2 {
  width: 1456px !important;
  height: 910px !important;
  margin: auto;
  margin-top: 91px !important;
}
.landscape {
  width: 1136px;
  height: 710px;
  position: absolute;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  left: 0;
}
.landscape1 {
  width: 1296px !important;
  height: 810px !important;
}
.landscape2 {
  width: 1456px !important;
  height: 910px !important;
}
.landscape .grid-item {
  width: 71px;
  height: 71px;
  position: relative;
  cursor: pointer;
}
.gi1 {
  width: 81px !important;
  height: 81px !important;
}
.gi2 {
  width: 91px !important;
  height: 91px !important;
}
.landscape .grid-item::before {
  content: none !important;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.landscape .grid-item::after {
  content: none !important;
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
}
.grid-lines {
  width: 1278px;
  height: 852px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  margin: auto;
  transform: translateX(-50%);
}
.grid-lines .horizontal-grid-line {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.grid-line1 {
  flex: 0 0 81px !important;
}
.grid-line2 {
  flex: 0 0 91px !important;
}
.grid-linev1 {
  flex: 0 0 81px !important;
  height: 71px !important;
}
.grid-linev2 {
  flex: 0 0 91px !important;
  height: 71px !important;
}
.grid-lines .vertical-grid-line {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.grid-lines .horizontal-grid-line .grid-line {
  height: 100%;
  position: relative;
  flex: 0 0 71px;
}
.grid-lines .horizontal-grid-line .grid-line:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}
.grid-lines .vertical-grid-line .grid-line {
  height: 71px;
  position: relative;
  flex: 0 0 71px;
}
.grid-lines .vertical-grid-line .grid-line::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}
.grid-lines .horizontal-grid-line .grid-line:first-child:after,
.grid-lines .vertical-grid-line .grid-line:first-child:after {
  content: none;
}
.grid {
  background: rgba(250, 90, 90, 0.08);
}
.rejected {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
}
.rejected span {
  width: 130%;
  height: auto;
  background-color: #ff4d4d;
  transform: rotate(-45deg);
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: normal;
  line-height: 80%;
  letter-spacing: -0.109px;
  color: var(--secondary-color);
  font-size: 9px;
  padding: 8px 7px 8px 6px;
  text-transform: uppercase;
  display: flex;
  position: absolute;
  justify-content: center;
  top: 25px;
}
.rejected:after {
  content: "";
  width: 67px;
  height: 67px;
  border: 2px solid #ff4d4d;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.rj1:after {
  width: 77px !important;
  height: 77px !important;
}
.rj2:after {
  width: 87px !important;
  height: 87px !important;
}
.enter {
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
  opacity: 0;
}
.enter:after {
  content: "";
  width: 64px;
  height: 64px;
  border: 4px solid var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.er1:after {
  width: 74px !important;
  height: 74px !important;
}
.er2:after {
  width: 84px !important;
  height: 84px !important;
}
.enter span {
  width: 100%;
  height: auto;
  background-color: var(--primary-color);
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  color: var(--secondary-color);
  font-size: 9px;
  padding: 6px 4px;
  justify-content: center;
  text-transform: uppercase;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}
.landscape .grid-item:hover > .rejected,
.landscape .grid-item:hover > .enter {
  opacity: 1;
}
.landscape .grid-item.active .enter {
  opacity: 1;
}

.zoom-controller {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 180px;
  height: 32px;
  overflow: hidden;
  border-radius: 70px;
  border: 1px solid var(--grey-grey-700);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.24) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
    0px -2px 4px 0px rgba(255, 255, 255, 0.09) inset;
  background: var(--primary-color);
  display: flex;
  justify-content: space-between;
  z-index: 100;
}
.zoom-controller > .zoom-distance {
  flex: 0 0 60px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9a9a9a;
  text-align: center;
  font-size: 10px;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
  cursor: pointer;
}
/* Define enter transition */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;

  transition: opacity 1000ms;
}

/* Define exit transition */
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;

  transition: opacity 1000ms;
}

.zoom-controller > .zoom-distance .unit {
  font-size: 7px;
  align-self: center;
  height: 9px;
}
.zoom-controller > .zoom-distance.active {
  background-image: url(../../images/zoom-noise.png);
  color: var(--primary-color);
}
.zoom-controller > .zoom-distance.active:hover {
  color: var(--primary-color);
}
.zoom-controller > .zoom-distance:hover {
  color: var(--secondary-color);
}
.zoom-controller > .zoom-distance:nth-of-type(1).active {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.34) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.35) inset,
    0px -2px 4px 0px rgba(255, 255, 255, 0.09) inset;
}
.zoom-controller > .zoom-distance:nth-of-type(2).active {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.34) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.35) inset,
    0px -2px 4px 0px rgba(255, 255, 255, 0.09) inset;
}
.zoom-controller > .zoom-distance:nth-of-type(3).active {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.34) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.35) inset,
    0px -2px 4px 0px rgba(255, 255, 255, 0.09) inset;
}
.landscape-footer-wrap {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 8px;

  right: 0;
  z-index: 99;
}

.logo-bird {
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 27px;
  width: 50px;
}
.landscape-footer-wrap:after {
  content: "";
  width: 100%;
  height: 8px;
  background: #000;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: auto;
}
.landscapefooter {
  max-width: 300px;
  height: 42px;
  position: relative;

  background: var(--primary-color);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-top: 10px;
}
.landscapefooter::before {
  content: "";
  width: 49px;
  height: 43px;
  position: absolute;
  top: 0px;
  left: -49px;
  z-index: 2;
  background: url("../../images/bottom-left-corner.svg") no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  vertical-align: top;
}
.landscapefooter::after {
  content: "";
  width: 49px;
  height: 43px;
  position: absolute;
  top: 0px;
  right: -49px;
  z-index: 2;
  background: url("../../images/bottom-right-corner.svg") no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  vertical-align: top;
}
.landscapefooter h2 {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: (var(--primary-font));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}
.landscapefooter p {
  margin: 5px 0 0;
  font-size: 0.625rem;
  font-family: var(--primary-font);
  font-weight: normal;
  color: #969696;
  text-align: center;
}
.landscapenav-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  height: calc(100% - 9px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  width: 35px;
}
.landscapenav-wrap.left {
  left: 0;
}
.landscapenav-wrap.right {
  right: 0;
}
.landscapenav {
  width: 35px;
  height: 190px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 33px;
  background: var(--primary-color);
  position: relative;
}
.landscapenav-wrap.left .landscapenav::before {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  top: -33px;
  left: 7px;
  z-index: 2;
  background: url("../../images/nav-left-top-corner.svg") no-repeat 0 0;
}
.landscapenav-wrap.left .landscapenav::after {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  bottom: -32px;
  left: 7px;
  z-index: 2;
  background: url("../../images/nav-left-bottom-corner.svg") no-repeat 0 0;
}
.landscapenav-wrap.right .landscapenav::before {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  top: -33px;
  right: 7.8px;
  z-index: 2;
  background: url("../../images/nav-right-top-corner.svg") no-repeat 0 0;
}
.landscapenav-wrap.right .landscapenav::after {
  content: "";
  width: 28px;
  height: 33px;
  position: absolute;
  bottom: -32px;
  right: 7.8px;
  z-index: 2;
  background: url("../../images/nav-right-bottom-corner.svg") no-repeat 0 0;
}
.landscapenav li {
  cursor: pointer;
  position: relative;
}
.landscapenav li {
  position: relative;
  z-index: 2;
}
.landscapenav li svg {
  fill: #808080;
}
.landscapenav li:hover svg {
  fill: var(--secondary-color);
}
.landscapenav li.active svg {
  fill: var(--secondary-color);
}
.landscapenav li span {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--primary-font-regular);
  text-transform: uppercase;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  z-index: 1;
  transition: all 0.2s ease;
  background-color: var(--primary-color);
  font-size: 10px;
  color: var(--secondary-color);
}
.landscapenav li:hover span {
  opacity: 1;
  position: absolute;
  left: 30px;
  pointer-events: none;
}
.landscapenav-icon div {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landscapenav-wrap.right .landscapenav li span {
  right: 20px;
  left: unset;
}
.landscapenav-wrap.right .landscapenav li:hover span {
  right: 30px;
  left: unset;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation: fadeIn 5s;
}
/*Faction Selectors*/
.faction-selector-wrap {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  background: #0d0d0d;
  backdrop-filter: blur(60px);
  z-index: 9;
  border-radius: 20px;
  margin: 0 auto;
}
.faction-content-wrap {
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  margin-top: 2rem;
  position: relative;
}
.faction-title-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  text-transform: uppercase;
  line-height: 84%;
  letter-spacing: -0.64px;
}
.faction-slider {
  width: 100%;
  height: calc(100% - 140px);
  margin-top: 2.5rem;
}
.faction-slider > .swiper {
  width: 100%;
  height: 100%;
}
.faction-slider > .swiper > .swiper-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}
.faction-slider > .swiper > .swiper-wrapper > .swiper-slide {
  position: relative;
  width: 25% !important;
  height: calc(100% - 95px);
  transition: all 0.3s ease;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.swiper-slide-active {
  width: 40% !important;
}
.faction-slider > .swiper > .swiper-wrapper > .swiper-slide > .availability {
  position: absolute;
  top: 8px;
  width: calc(100% - 14px);
  left: 0;
  right: 0;
  margin: auto;
  background: transparent;
  z-index: 10;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
  color: transparent;
  font-family: var(--primary-font);
  text-transform: uppercase;
  padding: 11px 15px;
  line-height: normal;
  letter-spacing: 0.349px;
  opacity: 0;
  transition: all 0.3s ease;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide
  > .availability.available {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    rgba(4, 233, 13, 0.8);
  color: var(--status-green);
  border-color: var(--status-green);
  box-shadow: 0px 0px 32.946571350097656px rgba(4, 233, 13, 0.3);
  z-index: 10;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen
  > .availability {
  width: 72%;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide
  > .availability.none {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    rgba(250, 90, 90, 0.7);
  color: var(--status-red);
  border-color: var(--status-red);
  box-shadow: 0px 0px 32.946571350097656px 0px rgba(255, 0, 0, 0.3);
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide:hover
  > .availability {
  opacity: 1;
}
.faction-slider > .swiper > .swiper-wrapper > .swiper-slide > .slide-box {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  transform: scale(0.8);
  transform-origin: center bottom;
  opacity: 0;
  transition: all 0.2s ease;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25) inset;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .slide-box {
  transform: scale(1);
  opacity: 1;
}

.faction-slider > .swiper > .swiper-wrapper > .swiper-slide.fallen.active {
  transition: all 0.3s ease;
  height: 100%;
}
.faction-slider > .swiper > .swiper-wrapper > .swiper-slide.fallen:hover {
  height: 100%;
}

.game-character {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transform: scale(0.9);
  transition: all 0.3s ease;
  transform-origin: bottom;
  z-index: 999;
}
.game-character img {
  align-self: flex-end;
  width: auto !important;
  height: 90%;
  display: block;
  object-fit: contain;
  object-position: center;
  filter: brightness(0.5);
  cursor: pointer;
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen
  > .slide-box {
  width: 74%;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .game-character {
  transform: scale(1);
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .game-character
  img {
  filter: none;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen.active
  > .game-character {
  transform: scale(1.02);
  transform-origin: bottom;
  bottom: -5px;
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.apostles.active
  > .game-character {
  transform: scale(1.1);
  transform-origin: bottom;
  bottom: -20px;
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen
  > .game-character
  img {
  object-position: bottom;
  transform: scale(1.1);
  z-index: 99999;
}

.game-character.apostles img {
  filter: drop-shadow(52px 3px 26px black) brightness(0.5);
  width: 60%;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .game-character.apostles
  img {
  filter: drop-shadow(52px 3px 26px black);
}
.game-character.apostles:before {
  content: "";
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  background: radial-gradient(
    40.82% 40.82% at 40% 48.18%,
    #ed6c6c 0%,
    rgba(240, 64, 64, 0.59) 46.35%,
    rgba(237, 108, 108, 0) 100%
  );
  background-size: 60%;
  background-position: bottom center;
  background-repeat: no-repeat;
  width: calc(80%);
  height: calc(80%);
  z-index: -1;
  border-radius: 10px;
}
.game-character.apostles:before {
  filter: blur(40px);
}
.game-character.humanx:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  background: radial-gradient(
    60% 60% at 60% 60%,
    #fcf547 0%,
    rgba(252, 245, 71, 0) 100%
  );
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  width: calc(100%);
  height: calc(50%);
  z-index: -1;
}
.game-character.humanx:before {
  filter: blur(40px);
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.humanx
  > .game-character {
  top: 0;
}
.faction-btn-wrap {
  width: 100%;
  position: absolute;
  bottom: 1.9rem;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.faction-btn-wrap .btn {
  max-width: 505px;
  width: 90%;
}

.coming-wrap {
  width: auto;
  position: absolute;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  top: 4.6875rem;
  left: 2.5rem;
  font-size: 2.0625rem;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  line-height: 84%;
  letter-spacing: -0.337px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.game-info-wrap {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 1rem;
  left: 2.5rem;
  z-index: 1000000;
  transition: all 0.3s ease;
  opacity: 0.7;
}
.game-title {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 85%;
  color: var(--secondary-color);
  letter-spacing: -0.64px;
  text-transform: uppercase;
}
.game-btn-wrap .btn {
  width: 108px;
  height: 36px;
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen
  > .game-info-wrap {
  left: 4rem;
}

.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .game-info-wrap {
  opacity: 1;
  bottom: -2rem;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.fallen.active
  > .game-info-wrap {
  opacity: 1;
  bottom: 1rem;
  left: 1rem;
}
.faction-slider
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.active
  > .coming-wrap {
  top: 2.0625rem;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.faction-slider > .swiper > .swiper-pagination {
  display: none !important;
  bottom: 0;
}

/* Faction  Badge  */
.faction-badge-background {
  display: flex;
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 8px;

  background: url(../../images/faction-badge-bg.jpg) no-repeat 0 0 / cover;
}
/* effects */

.faction-badge-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.faction-badge-wrapper > .fb-content-wrapper {
  display: flex;
  width: 784px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  user-select: none;
}

.fb-content-wrapper img {
  width: 280px;
  height: auto;
  border-radius: 150px;
}

.fb-content-wrapper > .fb-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.fb-text-wrapper > h5 {
  align-self: stretch;
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  color: var(--secondary-color);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 54.4px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.fb-text-wrapper > p {
  align-self: stretch;
  color: #fff;
  text-align: center;

  /* Paragraph/Large Text */
  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.16px;
}

.fb-btn-wrapper {
  display: flex;
  padding: 0px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.fb-btn-wrapper button {
  display: flex;
  height: 37px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: rgba(131, 123, 123, 0.3);
  backdrop-filter: blur(12px);
  cursor: pointer; /* Add cursor pointer for clickable behavior */
}

.fb-btn-wrapper button:hover {
  background-color: var(--secondary-color);

  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
}

@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fb-btn-wrapper button h5 {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.fb-btn-wrapper button:hover h5 {
  color: var(--brand-black);
}

/* Mech NFT */
.mech-nft-wrapper {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  background: #1f1f1f;
  display: flex;
  align-items: center;
  padding-top: 3.5rem;
}
.mnft-content-wrap {
  max-width: 1140px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 6.25rem;
  align-items: center;
  height: 100%;
}
.mnft-wrap {
  flex: 0 1 646px;
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}
.mnft {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.mnft img {
  object-fit: contain;
}
.mnft-content-scroller {
  flex: 0 0 481px;
  display: flex;
  flex-direction: column;
}
.mnft-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  justify-content: center;
}
.mnft-content h2 {
  font-family: var(--secondary-font);
  color: var(--secondary-color);
  text-transform: uppercase;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 85%;
  letter-spacing: -0.64px;
}
.mnft-content p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.16px;
  color: var(--secondary-color);
  font-family: var(--primary-font-regular);
}
.mnft-content .btn-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mnft-content .btn-wrap .btn {
  width: 100%;
}
.mnft-card-image-upload-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  gap: 3.75rem;
}
.mnft-card-image-upload {
  flex: 0 0 98px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn-confirm {
  transition: all 0.3s ease;
}

.mnft-id-card {
  flex: 1;
}
.mnft-card-image-upload .btn {
  border-radius: 2px;
  padding: 8px;
  height: auto;
  font-size: 12px;
  background: transparent;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}
.mnft-card-image-upload .btn:hover {
  background-color: var(--secondary-color);
}
.mnft-user-profile-pic-wrap {
  width: 98px;
  height: 98px;
  border-radius: 100%;
  background: var(--status-green);
  position: relative;
}
.mnft-user-profile-pic-wrap > .avatar-thumnail {
  width: 98px;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}
.mnft-img-wrap {
  width: 98px;
  height: 98px;
  overflow: hidden;
  border-radius: 100%;
  /*position: absolute;*/
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
}
.mnft-img-wrap-profile {
  width: 98px;
  height: 98px;
  overflow: hidden;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
}
.mnft-img-wrap > .user-name {
  width: 98px;
  height: 98px;
  overflow: hidden;
  border-radius: 100%;
  font-size: 3.125rem;
  color: var(--primary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--status-green);
}
.mnft-img-wrap > img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  clip-path: circle(98px);
}
.mnft-img-wrap > .remove-btn {
  cursor: pointer;
  position: absolute;
  top: 31%;
  left: 47%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 98px;
  opacity: 0;
  transition: all 0.3s ease;
}

.mnft-img-wrap > .remove-btn:hover {
  opacity: 1;
}

.remove-btn img {
  margin: auto !important;
}

.remo .mnft-img-wrap:hover > .remove-btn {
  opacity: 1;
}
.mnft-content-scroller,
.mnft-content-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: transparent !important;
  overflow: auto;
  -ms-overflow-style: none !important; /* IE 11 */
  scrollbar-width: none !important; /* Firefox 64 */
}

.mnft-content-scroller::-webkit-scrollbar,
.mnft-content-wrap::-webkit-scrollbar {
  width: 0px;
}
.mnft-content-scroller::-webkit-scrollbar-thumb,
.mnft-content-wrap::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}
.mnft-content-scroller::-webkit-scrollbar-thumb:hover,
.mnft-content-wrap::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

/* Faction File */
.faction-file-wrapper {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 8px;
  z-index: 0;
  opacity: 0;
  transition-delay: 0.5s;
  transition: opacity 1s, z-index 0.1s;
}
.faction-file-wrapper.active {
  opacity: 1;
  z-index: 9;
}
.faction-file-wrapper .swiper {
  width: 100%;
  height: 100%;
}
.ff-bg-container {
  width: 100%;
  height: 100%;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 0px;
  display: flex;
  align-items: center;
}
.ff-slider-wrap {
  max-width: 1290px;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  max-height: 800px;
  height: calc(100% - 50px);
}
.ff-slider-wrap > .ff-content-container {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  z-index: 1;
  border-radius: 12px;
  padding: 1rem;
  backdrop-filter: blur(50px);
}

.ff-thumb-img-wrap {
  flex: 0 1 471px;
  border-radius: 12px;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.ff-thumb-img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.ff-footer-spacing {
  width: 24px;
  align-self: stretch;
}
.ff-content-left,
.ff-content-right {
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-btn-wrap {
  flex: 1;
  display: flex;
  gap: 9px;
  padding: 7px;
}

.ff-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.ff-content-wrap > .ff-content-detail-wrap {
  flex: 1;
  display: flex;
  padding: 2rem 1rem;
}
.ff-content-detail-wrap > .ff-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
}
.ff-content-detail-wrap > .ff-content > .ff-title {
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}
.ff-content-detail-wrap > .ff-content > .ff-tagline-wrap {
  width: 100%;
}
.ff-content-detail-wrap > .ff-content > .ff-description {
  font-family: var(--primary-font-regular);
  font-size: 1rem;
}
.ff-content-detail-wrap > .ff-content > .ff-tagline-wrap > .ff-tagline {
  padding: 8px;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 110%;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  display: inline-block;
  width: 457px;
  color: var(--primary-color);
}
.ff-content-detail-wrap > .ff-content > .ff-commander-info {
  font-family: var(--primary-font);
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 104%;
  text-transform: uppercase;
}
.ff-content-detail-wrap > .ff-content > .ff-list {
  display: flex;
  flex-direction: column;
}
.ff-content-detail-wrap > .ff-content > .ff-list > .ff-list-row {
  display: flex;
  gap: 10px;
  padding: 8px 0;
}
.ff-content-detail-wrap
  > .ff-content
  > .ff-list
  > .ff-list-row
  > .ff-list-title {
  flex: 0 1 217px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  font-family: var(--primary-font);
  letter-spacing: 0.12px;
}
.ff-content-detail-wrap
  > .ff-content
  > .ff-list
  > .ff-list-row
  > .ff-list-detail {
  flex: 1;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  font-family: var(--primary-font);
  letter-spacing: 0.12px;
}
.ff-content-detail-wrap > .ff-content.humanx {
  color: #7b7f83;
}
.ff-content-detail-wrap > .ff-content.brotherhood {
  color: #837b7b !important;
}
.ff-content-detail-wrap > .ff-content.apostle {
  color: #a39e97 !important;
}
.ff-content-detail-wrap > .ff-content.humanx > .ff-tagline-wrap > .ff-tagline {
  background: #7b7f83 !important;
}
.ff-content-detail-wrap
  > .ff-content.brotherhood
  > .ff-tagline-wrap
  > .ff-tagline {
  background: #837b7b !important;
}
.ff-content-detail-wrap > .ff-content.apostle > .ff-tagline-wrap > .ff-tagline {
  background: #a39e97;
}
.ff-content-detail-wrap > .ff-content.humanx > .ff-list {
  border-top: 1px solid #7b7f83;
}
.ff-content-detail-wrap > .ff-content.brotherhood > .ff-list {
  border-top: 1px solid #837b7b;
}
.ff-content-detail-wrap > .ff-content.apostle > .ff-list {
  border-top: 1px solid #a39e97;
}
.ff-content-detail-wrap > .ff-content.humanx > .ff-list > .ff-list-row {
  border-bottom: 1px solid #7b7f83;
}
.ff-content-detail-wrap > .ff-content.brotherhood > .ff-list > .ff-list-row {
  border-bottom: 1px solid #837b7b;
}
.ff-content-detail-wrap > .ff-content.apostle > .ff-list > .ff-list-row {
  border-bottom: 1px solid #a39e97;
}
.ff-content-wrapper {
  flex: 1 1 775px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ff-content-header {
  border-bottom: 1px solid #837b7b;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ff-content-footer {
  border-top: 1px solid #837b7b;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ff-header-icon {
  width: 24px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ff-content-wrapper.humanx {
  border: 1px solid #7b7f83;
}
.ff-content-wrapper.brotherhood {
  border: 1px solid #837b7b;
}
.ff-content-wrapper.apostle {
  border: 1px solid #a39e97;
}
.ff-content-wrapper .ff-content-header.humanx {
  border-bottom: 1px solid #7b7f83;
}
.ff-content-wrapper .ff-content-header.brotherhood {
  border-bottom: 1px solid #837b7b;
}
.ff-content-wrapper .ff-content-header.apostle {
  border-bottom: 1px solid #a39e97;
}
.ff-content-wrapper.humanx .ff-header-icon.left,
.ff-content-wrapper.humanx .ff-content-left,
.ff-footer-spacing.left {
  border-right: 1px solid #7b7f83;
}
.ff-content-wrapper.humanx .ff-header-icon.right,
.ff-content-wrapper.humanx .ff-content-right,
.ff-footer-spacing.right {
  border-left: 1px solid #7b7f83;
}
.ff-content-wrapper.brotherhood .ff-header-icon.left,
.ff-content-wrapper.brotherhood .ff-content-left,
.ff-footer-spacing.left {
  border-right: 1px solid #837b7b;
}
.ff-content-wrapper.brotherhood .ff-header-icon.right,
.ff-content-wrapper.brotherhood .ff-content-right,
.ff-footer-spacing.right {
  border-left: 1px solid #837b7b;
}
.ff-content-wrapper.apostle .ff-header-icon.left,
.ff-content-wrapper.apostle .ff-content-left,
.ff-footer-spacing.left {
  border-right: 1px solid #a39e97;
}
.ff-content-wrapper.apostle .ff-header-icon.right,
.ff-content-wrapper.apostle .ff-content-right,
.ff-footer-spacing.right {
  border-left: 1px solid #a39e97;
}

.ff-close {
  width: 76px;
  height: 28px;
  padding: 0 5px;
  border-radius: 4px;
  background: var(--secondary-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
  cursor: pointer;
}
.ff-close-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ff-close-icon svg {
  width: 13px;
  height: 13px;
  fill: var(--primary-color);
}
.ff-close-text {
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 104%;
  text-transform: uppercase;
  font-family: var(--primary-font);
}

.ff-content-detail-wrap > .ff-content,
.ff-slider-wrap > .ff-content-container {
  scrollbar-width: thin;
  scrollbar-color: transparent !important;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ff-content-detail-wrap > .ff-content::-webkit-scrollbar,
.ff-slider-wrap > .ff-content-container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.ff-content-detail-wrap > .ff-content::-webkit-scrollbar-track,
.ff-slider-wrap > .ff-content-container::-webkit-scrollbar-track {
  background-color: transparent;
}
.ff-content-detail-wrap > .ff-content::-webkit-scrollbar-thumb:vertical,
.ff-slider-wrap > .ff-content-container::-webkit-scrollbar-thumb:vertical {
  background: transparent !important;
  border-radius: 10px;
}
.ff-slider-wrap .prev,
.ff-slider-wrap .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  cursor: pointer;
}
.ff-slider-wrap .prev {
  left: -30px;
}
.ff-slider-wrap .next {
  right: -30px;
}
.ff-m-img {
  display: none;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: -30px;
  z-index: 1000;
  transform: rotate(90deg);
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: -30px;
  z-index: 1000;
  transform: rotate(-90deg);
  cursor: pointer;
}
/* Faction Confirmation Modal */
.faction-confirmation-wrap {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  margin: 0 auto;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 8px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(60px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  opacity: 0;
  transition-delay: 0.5s;
  transition: opacity 1s, z-index 0.1s;
}
.faction-confirmation-wrap.active {
  opacity: 1;
  z-index: 10;
}
.fc-modal {
  max-width: 550px;
  width: 90%;
  border-radius: 8px;
  background: var(--primary-color);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  transition: all 0.3s ease;
}
.fc-modal.animate {
  transform: translate(-50%, -50%);
  top: 50%;
}
.fc-modal-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: -0.09px;
}
.fc-modal-info {
  text-align: center;
  font-size: 14px;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.07px;
  color: var(--secondary-color);
  white-space: nowrap;
}
.fc-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.fc-modal-footer .btn {
  background: transparent;
  border: 1px solid var(--grey-grey-600);
}
.fc-modal-footer .btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Avatar selection */

.avatar-selection-background {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 8px;
}

.avatar-selection-background > .avatar-selection-wrapper {
  background: #1f1f1f;
  backdrop-filter: blur(60px);
  height: 100%;
  padding: 0px 48px;
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.avatar-selection-background > .avatar-selection-wrapper > .as-content-wrapper {
  display: flex;

  width: 780px;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  align-self: stretch;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > h5 {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 54.4px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-content {
  display: flex;
  flex-direction: column;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-content
  p {
  color: #fff;
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap {
  display: flex;
  width: 98px;
  height: 135px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .as-badge {
  background-color: #00ff85;
  border-radius: 100%;
  width: 98px;
  height: 98.916px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .as-badge
  > .badge-text {
  display: flex;
  padding: 14px 10px 10px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .as-badge
  > .badge-text
  h1 {
  color: #000;
  font-family: var(--primary-font-regular);
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 52px */
  text-transform: uppercase;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .badge-btn-wrap {
  cursor: pointer;
  display: flex;
  width: 98px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  border-radius: 2px;
  border: 0.25px solid var(--grey-grey-500, #808080);
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .badge-btn-wrap:hover {
  background: rgba(131, 123, 123, 0.3);
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-badge-content-wrap
  > .badge-btn-wrap
  h5 {
  color: #fff;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-separator
  > .separator-line {
  width: 349.5px;
  height: 1px;
  background: #545454;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .as-separator
  h5 {
  color: #fff;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .avatar-group-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .avatar-group-wrapper
  > .avatar-wrapper {
  display: flex;
  width: 98px;
  height: 98px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .avatar-group-wrapper
  > .avatar-wrapper
  > .avatar-icon {
  cursor: pointer;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 100%;
  object-fit: cover;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .avatar-group-wrapper
  > .avatar-wrapper
  > .avatar-icon:hover {
  /* Glow/Green */
  box-shadow: 0px 4px 4px 0px rgba(4, 233, 13, 0.5);
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper {
  display: flex;
  padding: 0px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper
  > .btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper
  > .btn-wrap
  > .long-btn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: rgba(68, 68, 68, 0.9);
  cursor: pointer;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper
  > .btn-wrap
  > .long-btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
}

.long-btn-active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
}

.long-btn-active h5 {
  color: var(--brand-black) !important;
}
@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper
  > .btn-wrap
  > .long-btn
  h5 {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.avatar-selection-background
  > .avatar-selection-wrapper
  > .as-content-wrapper
  > .long-btn-wrapper
  > .btn-wrap
  > .long-btn:hover
  h5 {
  color: var(--brand-black);
}
/* Loader */
.loader-background {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 8px;
  flex-shrink: 0;
  background: #1f1f1f;
  backdrop-filter: blur(60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-content-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.loader-content-wrap {
  display: flex;
  width: 81.271px;
  height: 81.271px;
  align-items: flex-start;
}

.loader-content-wrapper > .loader-badge-wrapper {
  display: flex;
  padding: 6.437px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16.092px;
}

.loader-badge {
  border-radius: 73.9px;
  width: 68.397px;
  height: 68.397px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
}

.loader-badge-loader {
  border-radius: 73.9px;
  width: 68.397px;
  height: 68.397px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
}

.loader-badge-loader > .badge-background {
  width: 68.397px;
  height: 68.397px;
  flex-shrink: 0;
  border-radius: 60.351px;
  background: var(
    --gradients-hologram,
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0.72) 16.875deg,
      #000 88.12500178813934deg,
      rgba(255, 255, 255, 0.72) 151.875deg,
      #000 225deg,
      rgba(255, 255, 255, 0.72) 288.7499928474426deg,
      #000 360deg
    ),
    conic-gradient(
      from 180deg at 50% 50%,
      #fff 30.00000089406967deg,
      #000 95.625deg,
      #fff 168.75deg,
      #000 228.75000715255737deg,
      #fff 285.0000071525574deg,
      #000 360deg
    ),
    radial-gradient(
      92.48% 91.85% at 10.11% 28.24%,
      #7e6a7e 8.33%,
      #d4d4d4 37.5%,
      #75888a 63.45%,
      #896753 100%
    )
  );
  background-blend-mode: screen, difference, normal;
}

.loader-badge > .badge-background {
  width: 68.397px;
  height: 68.397px;
  flex-shrink: 0;
  border-radius: 60.351px;
  background: var(
    --gradients-hologram,
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0.72) 16.875deg,
      #000 88.12500178813934deg,
      rgba(255, 255, 255, 0.72) 151.875deg,
      #000 225deg,
      rgba(255, 255, 255, 0.72) 288.7499928474426deg,
      #000 360deg
    ),
    conic-gradient(
      from 180deg at 50% 50%,
      #fff 30.00000089406967deg,
      #000 95.625deg,
      #fff 168.75deg,
      #000 228.75000715255737deg,
      #fff 285.0000071525574deg,
      #000 360deg
    ),
    radial-gradient(
      92.48% 91.85% at 10.11% 28.24%,
      #7e6a7e 8.33%,
      #d4d4d4 37.5%,
      #75888a 63.45%,
      #896753 100%
    )
  );
  background-blend-mode: screen, difference, normal;
}

.loader-badge-loader > .loader-background > .badge-noise {
  width: 68.397px;
  height: 68.397px;
  flex-shrink: 0;
  border-radius: 60.351px;
  background: url(../../images/avatars/Noise.png),
    lightgray 0% 0% / 53.14755439758301px 53.14755439758301px repeat;
  background-blend-mode: overlay;
}

.loader-badge > .loader-background > .badge-noise {
  width: 68.397px;
  height: 68.397px;
  flex-shrink: 0;
  border-radius: 60.351px;
  background: url(../../images/avatars/Noise.png),
    lightgray 0% 0% / 53.14755439758301px 53.14755439758301px repeat;
  background-blend-mode: overlay;
}

.mine-prep-logo {
  position: absolute;
  top: 4px;
}

.badge {
  width: 95.277px;
  height: 60.351px;
  flex-shrink: 0;
  mix-blend-mode: overlay;
}

.loader-content-wrap > img {
  position: absolute;
  border-radius: 100%;
  padding-top: 3px;
  object-fit: cover;
}

.loader-content-wrapper h5 {
  color: var(--secondary-color);
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* mine-prep*/
.mine-prep-background {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 8px;
  flex-shrink: 0;
  background: #1f1f1f;
  backdrop-filter: blur(60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mine-prep-wrapper {
  width: 1300px;
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
}

.mp-tc-content-wrap {
  /* Shadow/Med */

  display: flex;
  height: 400.098px;
  padding: 0px 21px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.card-front {
  display: flex;
  width: 298.676px;
  padding: 0px 11.929px 19.011px 13.747px;
  flex-direction: column;
  align-items: center;
}

.mp-left-wrap {
  display: flex;
  width: 378px;
  height: 880px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  margin-top: -32%;
}

.mp-id-content {
  border-radius: 13.097px;
  width: 273px;
  height: 378px;
  flex-shrink: 0;
  background: #1b1b1b;
  stroke-width: 1.161px;
  stroke: #292929;
}

.mp-id-image-wrap {
  border-radius: 23.222px;
  background: #c4c4c4;
  width: 253.944px;
  height: 165.173px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.red-big-text {
  background: linear-gradient(
    97deg,
    #f84b4b 8.85%,
    rgba(255, 219, 219, 0.41) 97.33%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: var(--primary-font);
  font-size: 37.097px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.226px;
  text-transform: uppercase;
}

.mp-left-wrap > h1 {
  color: #fff;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 54.4px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.mp-left-wrap > p {
  color: var(--brand-white, #fff);

  /* Paragraph/Large Text */

  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.16px;
}

.mp-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.mp-light-btn {
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: var(--brand-white, #fff);
  cursor: pointer;
}

.mp-light-btn:hover {
  border-radius: 4px;
  border: 1px solid var(--brand-white, #fff);
  background: var(--grey-grey-900, #1c1c1c);

  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
  transform: scale(1.02);
  transition: transform 300ms ease-in-out;
}

.mp-lb-text {
  color: var(--brand-black, #000);
  text-align: center;

  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.mp-light-btn:hover .mp-lb-text {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Shadow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Headings/Heading 5 */
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.mp-dark-btn {
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: rgba(68, 68, 68, 0.9);
  cursor: pointer;
}
.mp-db-text {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.mp-dark-btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
  transform: scale(1.02);
  transition: transform 300ms ease-in-out;
}

@keyframes fadeOut {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.mp-dark-btn:hover .mp-db-text {
  color: var(--brand-black);
}

.mp-middle-wrap {
  width: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
}
.mp-right-wrap {
  width: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
}

.mp-middle-wrap > .mp-top-content {
  border-radius: var(--edge-12, 12px);
  border: 1px solid #292929;
  background: linear-gradient(136deg, #4e4141 17.08%, #181515 75.31%);

  /* Shadow/Med */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
}
.mp-image-holder {
  position: relative;
}
.mp-mech-card-wrap {
  display: flex;
  width: 378px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--edge-12, 12px);
  background: linear-gradient(171deg, #595858 0%, #262626 100%);

  /* Shadow/Med */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
}

.mp-licence-card-wrap {
  display: flex;
  width: 378px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--edge-12, 12px);
  background: linear-gradient(171deg, #4e4141 0%, #181515 100%);

  /* Shadow/Med */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
}
.mp-lc-top {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: overlay;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.licence-plate-wrap {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(
    --gradients-background-black,
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%)
  );

  /* Shadow/Sunken Field */
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.24) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
    0px -2px 4px 0px rgba(255, 255, 255, 0.09) inset;
}

.mp-top-content {
  border-radius: 8.101px;
  border: 0.675px solid #292929;
  background: linear-gradient(136deg, #595858 17.08%, #262626 75.31%);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    1.35023px 3.37559px 8.10141px 0px rgba(0, 0, 0, 0.48),
    6.75117px 13.50235px 14.85258px 0px rgba(0, 0, 0, 0.42),
    14.85258px 29.70517px 20.25352px 0px rgba(0, 0, 0, 0.25),
    26.32958px 52.65916px 23.62911px 0px rgba(0, 0, 0, 0.07),
    41.18216px 82.36433px 25.65446px 0px rgba(0, 0, 0, 0.01);
  display: flex;
  width: 378px;
  height: 495px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.mp-badge-wrap {
  border-radius: 2.7px;
  background: rgba(255, 255, 255, 0.2);
  mix-blend-mode: overlay;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.mp-badge-wrap img {
  width: 268px;
  height: 46.924px;
}

/* Image Container */
.np-lc-top-group {
  width: 268px;
  height: 46.924px;
  position: relative;
}

/* Common Styling for Images */
.image-1,
.image-2,
.image-3,
.image-4 {
  width: 268px;
  height: 46.924px;
  flex-shrink: 0;
  fill: var(
    --gradients-hologram,
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0.72) 16.875deg,
      #000 88.12500178813934deg,
      rgba(255, 255, 255, 0.72) 151.875deg,
      #000 225deg,
      rgba(255, 255, 255, 0.72) 288.7499928474426deg,
      #000 360deg
    ),
    conic-gradient(
      from 180deg at 50% 50%,
      #fff 30.00000089406967deg,
      #000 95.625deg,
      #fff 168.75deg,
      #000 228.75000715255737deg,
      #fff 285.0000071525574deg,
      #000 360deg
    ),
    radial-gradient(
      92.48% 91.85% at 10.11% 28.24%,
      #7e6a7e 8.33%,
      #d4d4d4 37.5%,
      #75888a 63.45%,
      #896753 100%
    )
  );
  background-blend-mode: screen, difference, normal;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

/* Individual Image Styling */
.image-2 {
  mix-blend-mode: difference;
  z-index: 2;
}

.image-3 {
  mix-blend-mode: soft-light;
  z-index: 3;
}
.image-4 {
  z-index: 4;
}

.mp-lc-front {
  /* Shadow/Med */

  display: flex;
  height: 400.098px;
  padding: 0px 21px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.mp-lc-front-wrap {
  position: relative;
  display: flex;
  width: 298.676px;
  padding: 0px 11.929px 19.011px 13.747px;
  flex-direction: column;
  align-items: center;
}

.grain-texture {
  z-index: 9999;
  position: absolute;
}
.mp-lc-fw-content {
  position: relative;
  z-index: 999;
  border-radius: 13.097px;
  width: 273px;
  height: 378px;
}

.subtract {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);
  border-radius: 13.097px;
  background: #1b1b1b;
  width: 273px;
  height: 378px;
}

.subtract h1 {
  background: linear-gradient(
    123deg,
    #f84b4b 0%,
    rgba(255, 219, 219, 0.41) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 37.097px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.226px;
  text-transform: uppercase;
}

.pfp-wrap {
  margin: 0 auto;
  border-radius: 23.222px;
  background: #c4c4c4;
  width: 253.944px;
  height: 165.173px;
  flex-shrink: 0;
}

.pfp-background {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 23.222px;
  background: #f9f9f9;
  width: 253.944px;
  height: 165.173px;
  flex-shrink: 0;
  background: url(../../images/avatars/img11.png);
}

.pfp {
  width: 136.82px;
  height: 138.833px;
  flex-shrink: 0;
  border-radius: 177.062px;
  opacity: 0.8;
  object-fit: cover;
}

.logo-wrap {
  display: flex;
  padding: 6.437px;
  flex-direction: column;
  align-items: center;
  gap: 16.092px;
}

.logo-badge-wrap {
  margin: auto;
  width: 68.397px;
  height: 68.397px;
}
.text-bottom {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}

.text-bottom-left,
.text-bottom-right {
  display: inline; /* or inline-block */
}

.text-bottom-left,
.text-bottom-right {
  color: #fff;
  font-family: var(--secondary-font-regular);
  font-size: 8.732px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.262px;
  text-transform: uppercase;
}

.mech-card-front {
  position: relative;
  display: flex;
  height: 400.098px;
  width: 412px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.vector-top-img {
  width: 266.728px;
  height: 81.12px;
  position: absolute;

  left: 50px;
}
.vector-img {
  position: relative;
  width: 297px;
  height: 315px;
  top: 75px;
  left: 35px;
}

.logo-background {
  border-radius: 60.351px;
  background: var(
    --gradients-hologram,
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0.72) 16.875deg,
      #000 88.12500178813934deg,
      rgba(255, 255, 255, 0.72) 151.875deg,
      #000 225deg,
      rgba(255, 255, 255, 0.72) 288.7499928474426deg,
      #000 360deg
    ),
    conic-gradient(
      from 180deg at 50% 50%,
      #fff 30.00000089406967deg,
      #000 95.625deg,
      #fff 168.75deg,
      #000 228.75000715255737deg,
      #fff 285.0000071525574deg,
      #000 360deg
    ),
    radial-gradient(
      92.48% 91.85% at 10.11% 28.24%,
      #7e6a7e 8.33%,
      #d4d4d4 37.5%,
      #75888a 63.45%,
      #896753 100%
    )
  );
  border-radius: 60.351px;
  background: url(../../images/avatars/Noise.png),
    lightgray 0% 0% / 53.14755439758301px 53.14755439758301px repeat;
  background-blend-mode: overlay;
  background-blend-mode: screen, difference, normal;
}
/* Miscellaneous Styling */

.licence-plate-wrap > h1 {
  align-self: stretch;
  text-align: center;

  /* Mono Labels/Large Labels */
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
  background: var(
    --gradients-box-outline,
    linear-gradient(171deg, #9f9f9f 0%, #686868 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.licence-plate-wrap > p {
  background: var(
    --gradients-box-outline,
    linear-gradient(171deg, #9f9f9f 0%, #686868 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  /* Paragraph/Small Text */
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.12px;
  align-self: stretch;
}

.licence-plate-wrap > button {
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: rgba(68, 68, 68, 0.9);
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.licence-plate-wrap > button > h5 {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.licence-plate-wrap > button:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
  transform: scale(1.02);
  transition: transform 300ms ease-in-out;
  cursor: pointer;
}

.licence-plate-wrap > button:hover > h5 {
  color: var(--brand-black);
}

/* mech inspect */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--brand-black, #000);
  backdrop-filter: blur(27px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mech-title {
  display: inline-flex;
  padding: 30px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo-top-center {
  opacity: 0.6;
  display: inline-flex;
  padding: 17.659px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.599px;
}
.discover-inspect-top-section {
  opacity: 0.6;
  display: inline-flex;
  padding: 17.659px;
  flex-direction: column;
  align-items: center;
  gap: 5.599px;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.push-to-left {
  position: relative;
  left: -75px;
}
.mech-title > p {
  background: linear-gradient(171deg, #383838 0%, #262626 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 54.4px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.mech-picture {
  width: 542px;
  display: flex;
  height: 525px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.share-button {
  margin: 0 auto;
  display: flex;
  width: 511px;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  gap: 31px;
}

.share-button > p {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.09px;
}
.sharebutton span {
  color: var(--status-green, #00ff85);
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.09px;
}

.mb-100 {
  margin-bottom: 100px;
}

.share-button button {
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: var(--brand-white, #fff);
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.share-button button:hover {
  border-radius: 4px;
  border: 1px solid var(--brand-white, #fff);
  background: var(--grey-grey-900, #1c1c1c);

  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
  animation-name: fadeOut;
  animation-timing-function: ease-out;
  animation-duration: 1000ms;
  animation-fill-mode: forwards; /* Keeps the element in the state of the last keyframe when the animation ends */
  transform: scale(1.02);
  transition: transform 300ms ease-in-out;
}

.share-button button h5 {
  color: var(--brand-black, #000);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.share-button button img {
  width: 12px;
}

.share-button button:hover h5 {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Shadow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}

.share-button button:hover img {
  display: none;
}

.bottom-left {
  position: relative;
  display: inline-flex;
  padding: 24px;
  align-items: flex-end;
  gap: 41.94px;
  z-index: 999999;
}

.close-button {
  position: absolute;
  top: 48px;
  right: 48px;
  background: none;
  border: none;
  cursor: pointer;
  user-select: none;
}

.picture-holder {
  position: relative;
  top: 60px;
  z-index: 1;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 90%;
}

.picture-holder img {
  height: inherit;
}

.close-button img {
  fill: rgba(255, 255, 255, 0.4);
  user-select: none;
}
.flexnone {
  flex: auto !important;
}
.width100 {
  width: 100% !important;
}
.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.bottom-section-share-complete {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.bottom-left img {
  width: 47px;
  height: 88.629px;
  flex-shrink: 0;
  border-radius: 2.686px;
}

.bottom-left .info {
  display: flex;
  width: 172px;

  align-items: flex-start;
  gap: 13.322px;
}

.bottom-left .info .info-labels,
.info-values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.553px;
}

.bottom-left .info .info-labels > p {
  color: #545454;
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 104%; /* 9.36px */
  text-transform: uppercase;
}

.bottom-left .info .info-values > p {
  color: var(--brand-white, #fff);
  text-shadow: 0px 0px 5.812256813049316px rgba(255, 255, 255, 0.6);
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 9.36px */
  text-transform: uppercase;
}

.bottom-right {
  width: 522px;
  display: inline-flex;
  padding: 24px;
  align-items: flex-end;
  gap: 71px;
  justify-content: end;
}

.bottom-right > h5 {
  color: var(--brand-white, #fff);
  font-family: var(--primary-font-regular);
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 67%; /* 24.12px */
  letter-spacing: -1.44px;
  text-transform: uppercase;
}
/*id Card*/
.hide {
  display: none !important;
}
.provisional-card {
  background: url(../../images/card-bg.png) no-repeat;
  width: 375px;
  height: 198px;
  border-radius: 9px;
  padding: 7px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.card-left-wrap {
  flex: 0 0 85px;
}

.avtar-outer {
  display: flex;
  width: 85px;
  height: 85px;
  border-radius: 9px;
  background: rgb(255 255 255 / 20%);
  align-items: center;
  justify-content: center;
}

.vactor-outer {
  text-align: center;
  margin-top: 8px;
}

.plot-no p {
  font-size: 7px;
  font-family: "NB International Pro Regular";
  font-style: normal;
  font-weight: normal;
  line-height: 18.072px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.share-complete-text {
  position: relative;
  z-index: 2;
  color: var(--brand-white, #fff);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 108.8px */
  letter-spacing: -1.28px;
  text-transform: uppercase;
}

.plot-no h5 {
  font-size: 12px;
  font-style: normal;
  font-family: "NB International Pro Monospaced";
  height: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: #ff4d4d;
  background: rgba(250, 90, 90, 0.1);
  margin: 2px 0 5px;
  text-align: center;
}

.flag-outer {
  text-align: center;
}

.top-title {
  border-radius: 2px;
  text-align: center;
  background: #ff4d4d;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  font-family: "NB International Pro Monospaced";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.detail-info {
  flex: 0 0 267px;
}

.user-info-group {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 12px;
}

.user-info-group > .user-name,
.user-info-group > .user-tagline {
  color: #000;
  text-shadow: 0px 0px 6.777108192443848px rgba(255, 255, 255, 0.6);
  font-family: "NB International Pro Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.user-info-group > .user-address {
  color: #000;
  text-shadow: 0px 0px 6.777108192443848px rgba(255, 255, 255, 0.6);
  font-family: "NB International Pro Light";
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 18.072px;
}

.info-group-wrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  padding-left: 12px;
}

.info-group-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 9px;
}

.info-group-row > .info-group-title {
  flex: 0 0 144px;
  color: #000;
  text-shadow: 0px 0px 6.777108192443848px rgba(255, 255, 255, 0.6);
  font-family: "NB International Pro Monospaced";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
}

.info-group-row > .info-group-detail {
  flex: 0 0 144px;
  color: #000;
  text-shadow: 0px 0px 6.777108192443848px rgba(255, 255, 255, 0.6);
  font-family: "NB International Pro Monospaced";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
}

@media (min-width: 1400px) {
  .zoomlevel1 .indiana-scroll-container--hide-scrollbars,
  .zoomlevel2 .indiana-scroll-container--hide-scrollbars {
    overflow: visible;
  }

  .zoomlevel3 .indiana-scroll-container--native-scroll {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1600px) {
  .faction-title-wrap,
  .game-title {
    font-size: 3rem;
  }
  .coming-wrap {
    font-size: 1.5rem;
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide {
    height: calc(100% - 33px);
  }
  .fb-content-wrap > .badge-logo {
    flex: 0 0 200px;
  }
  .fb-content-wrap > .badge-content-wrap h2 {
    font-size: 3rem;
  }
  .fb-content-wrap > .badge-content-wrap > .badge-content p {
    font-size: 0.875rem;
  }
  .fb-content-wrap {
    gap: 4.375rem;
    max-width: 684px;
  }
  .landscape-container {
    transform: scale(0.9);
  }
  .fb-text-wrapper > h5 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

    /* Headings/Heading 1 Mobile */
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }
  .fb-text-wrapper > p {
    font-size: 1rem;
    word-wrap: break-word;
  }
  .fb-content-wrapper {
    gap: 4.375rem;
    max-width: 684px;
  }
}
@media (max-width: 1399px) {
  .landscape-container {
    transform: scale(0.9);
  }
  .mine-prep-wrapper {
    margin: 0 40px;
  }

  .share-complete-text {
    font-size: 80px !important;
  }
}

@media (max-width: 1280px) {
  .landscape-container {
    transform: scale(0.8);
  }
  .top-section {
    flex: none;
  }
  .mp-middle-wrap {
    width: 255px;
  }
  .share-complete-text {
    font-size: 80px !important;
  }
  .mp-right-wrap {
    width: 255px;
  }
  .mine-prep-wrapper {
    margin: 0 60px;
  }
  .mp-top-content {
    display: flex;
    width: 255.194px;
    padding: 5.401px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10.802px;
    height: 331px;
  }

  .mp-badge-wrap img {
    width: 180.931px;
    height: 31.679px;
  }

  .vector-top-img {
    width: 180.073px;
    height: 54.765px;
  }
  .vector-img {
    width: 200.51px;
    height: 212.662px;
    flex-shrink: 0;
    top: 50px;
  }

  .mp-lc-front-wrap {
    width: 184.307px;
    height: 255.194px;
  }

  .mp-lc-fw-content {
    width: 184.307px;
    height: 255.194px;
  }
  .subtract {
    width: 100%;
    height: 100%;
  }

  .mp-tc-content-wrap {
    height: 270.113px;
    padding: 0px 14.177px;

    gap: 6.751px;
  }

  .card-front {
    width: 201.641px;
    padding: 0px 8.054px 12.834px 9.28px;
    height: 270px;
  }

  .mp-id-content,
  .grain-texture {
    width: 184.307px;
    height: 255.194px;
  }

  .mp-id-image-wrap {
    width: 171.442px;
    height: 111.511px;
    flex-shrink: 0;
  }

  .pfp {
    width: 103.237px;
    height: 103.237px;
    flex-shrink: 0;
  }

  .red-big-text {
    font-size: 25.045px;
  }
  .loader-badge {
    width: 46.176px;
    height: 46.176px;
  }
  .loader-badge > .badge-background {
    width: 46.176px;
    height: 46.176px;
  }

  .mine-prep-logo {
    top: 2px;
    left: -2px;
  }
  .bottom-left {
    display: inline-flex;
    padding-right: 0;
    align-items: flex-end;
    gap: 10px;
  }
}

@media (min-width: 1250px) {
  .zoomlevel1 .react-draggable {
    transform: none !important;
  }
}
@media (max-width: 1199px) {
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen.active
    > .game-character {
    transform: scale(1);
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide > .availability {
    opacity: 1;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen
    > .availability {
    width: 87.6%;
  }
  .faction-slider > .swiper {
    padding-bottom: 40px;
    box-sizing: content-box;
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide {
    position: relative;
    width: 100% !important;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active {
    width: 100% !important;
  }
  .faction-slider > .swiper > .swiper-pagination {
    display: block !important;
  }
  .faction-slider > .swiper > .swiper-pagination > .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: transparent !important;
    border: 1px solid #dadada;
    z-index: 99;
    opacity: 1 !important;
  }
  .faction-slider
    > .swiper
    > .swiper-pagination
    > .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: 1px solid var(--find);
    background-color: var(--find) !important;
  }
  .faction-selector-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .faction-content-wrap {
    height: calc(100% - 40px);
    margin-top: 0;
  }
  .faction-btn-wrap {
    bottom: 0;
  }
  .faction-slider {
    width: 636px;
    margin: auto;
    margin-top: 1.5rem;
  }
  .game-info-wrap {
    opacity: 1;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.active
    > .game-info-wrap {
    opacity: 1;
    bottom: 1rem;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen.active
    > .game-info-wrap {
    opacity: 1;
    bottom: 1rem;
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide > .coming-wrap {
    top: 2.0625rem;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen.active
    > .slide-box {
    width: 90%;
    opacity: 0;
    transform: scale(0.8);
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active {
    width: 100% !important;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active
    > .slide-box {
    transform: scale(1);
    opacity: 1;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active
    > .game-character {
    transform: scale(1);
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active
    > .game-character
    img {
    filter: none;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen
    > .slide-box {
    transform: scale(1);
    opacity: 1;
    width: 90%;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen.active.swiper-slide-active
    > .slide-box {
    transform: scale(1);
    opacity: 1;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active
    > .game-character.apostles
    img {
    filter: drop-shadow(52px 3px 26px black);
  }
  .game-character.apostles:before {
    width: 400px;
  }
  .mnft-content-scroller {
    flex: 0 0 398px;
  }
  .mech-nft-wrapper {
    padding-top: 0;
  }
  .mnft-content-wrap {
    padding-top: 3.5rem;
  }
  .mnft-content h2 {
    font-size: 3rem;
  }
  .mnft-content p {
    font-size: 0.875rem;
  }
}

@media (max-width: 1088px) {
  .mp-left-wrap {
    width: 295px;
  }
  .mine-prep-wrapper {
    margin: 0 40px;
  }
}

@media only screen and (min-height: 1200px) and (max-width: 1024px) {
  .faction-content-wrap {
    width: 90%;
    height: 750px;
    margin-top: 0;
  }
}
@media (max-width: 1023px) {
  .bottom-section {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .share-button p {
    display: none;
  }

  .thumb-footer {
    width: 100%;
  }

  .bottom-left {
    order: 2;
    padding: 0;
  }

  .share-button {
    gap: 20px;
    order: 3;
  }
  .bottom-right {
    order: 1;
    padding: 0;
    position: relative;
    top: 27px;
    left: -172px;
  }
  .is-mobile-view {
    display: block !important;
    padding: 0px 10px 5px 10px;
  }
  .is-dektop-view {
    display: none !important;
  }
  .custom-order-1 {
    order: 2;
  }

  .bottom-left {
    gap: 10px;
  }
  .bottom-right {
    gap: 20px;
  }

  .custom-order-2 {
    order: 1;
  }

  .custom-order-3 {
    order: 3;
  }
  .zoom-controller {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 4rem;
  }
  .mnft-content-wrap {
    flex-direction: column;
    overflow: scroll;
  }
  .mnft-content-scroller {
    overflow: unset;
    height: auto !important;
    padding-bottom: 2rem;
  }
  .mnft-content-wrap {
    gap: 1rem;
  }
  .mnft img {
    width: 300px;
  }
  .mnft-content {
    justify-content: flex-start;
  }
  .id-card {
    display: none;
  }
  .ff-content-detail-wrap > .ff-content {
    padding: 0 2.5rem;
  }
  .ff-content-detail-wrap > .ff-content > .ff-list > .ff-list-row {
    flex-direction: column;
    gap: 5px;
  }
  .ff-content-detail-wrap
    > .ff-content
    > .ff-list
    > .ff-list-row
    > .ff-list-title {
    flex: 1 1 auto;
    gap: 5px;
  }
  .ff-bg-container {
    align-items: flex-start;
  }
  .ff-thumb-img-wrap {
    display: none;
  }
  .ff-slider-wrap {
    max-height: 100%;
    height: 100%;
    width: 100%;
    margin-top: 0;
  }
  .ff-content-header,
  .ff-content-left,
  .ff-content-right {
    display: none;
  }
  .ff-slider-wrap .prev {
    display: none;
  }
  .ff-slider-wrap .next {
    display: none;
  }
  .ff-footer-spacing {
    display: none;
  }
  .ff-content-wrapper {
    border: unset;
  }
  .ff-slider-wrap > .ff-content-container {
    padding: 0.5rem;
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
  .ff-content-wrap > .ff-content-detail-wrap {
    padding: 0;
  }
  .ff-content-footer {
    position: fixed;
    height: 139px;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }
  .footer-btn-wrap {
    padding: 0;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 12px;
  }
  .ff-content-footer {
    border: unset;
  }
  .ff-content-wrapper.humanx {
    border: unset;
  }
  .ff-content-wrapper.brotherhood {
    border: unset;
  }
  .ff-content-wrapper.apostle {
    border: unset;
  }
  .ff-content-detail-wrap > .ff-content > .ff-tagline-wrap > .ff-tagline {
    width: 100% !important;
  }
  .ff-m-img {
    display: block;
  }
  .fc-modal {
    padding: 2rem;
  }
  .mnft-content h2 br {
    display: none;
  }

  .avatar-selection-background > .avatar-selection-wrapper {
    display: flex;
    padding: 0px 48px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > h5 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

    /* Headings/Heading 1 Mobile */

    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 85%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    p {
    color: #fff;
    text-align: center;

    /* Paragraph/Large Text */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.16px;
    align-self: stretch;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .avatar-group-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 32px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .long-btn-wrapper {
    padding: 0 96px;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .long-btn-wrapper
    > .btn-wrap
    > .long-btn
    h5 {
    color: var(--brand-white, #fff);
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
}

@media (max-width: 767px) {
  .landscapenav-wrap {
    display: none !important;
  }
  .landscapefooter {
    max-width: 175px;
  }

  .logo-bird {
    display: none;
  }
  .faction-slider {
    margin-top: 1.5rem;
    width: 100%;
  }
  .faction-title-wrap {
    font-size: 2.25rem;
  }
  .game-title {
    font-size: 2rem;
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide {
    position: relative;
    width: 100% !important;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.swiper-slide-active {
    width: 100% !important;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen
    > .availability {
    width: 87.4%;
  }
  .faction-content-wrap {
    height: 500px;
  }
  .game-character img {
    height: 83%;
  }
  .faction-btn-wrap {
    bottom: 1.5rem;
  }
  .faction-btn-wrap .btn {
    width: 100px;
  }
  .faction-content-wrap {
    width: calc(100% - 100px);
    height: calc(100% - 32px);
    margin-top: 0;
  }
  .faction-slider > .swiper > .swiper-wrapper > .swiper-slide > .coming-wrap {
    top: 1rem;
  }
  .game-info-wrap {
    left: 1rem;
  }
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen
    > .game-info-wrap {
    left: 2.5rem;
  }
  .game-character.apostles:before {
    width: 300px;
  }

  .mnft-content-wrap {
    flex-direction: column;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    gap: 2rem;
  }
  .mnft-content .btn-wrap .btn:first-child {
    display: none;
  }
  /* .mnft-content .btn-wrap .btn{ width: 134px !important;} */
  .mnft-content .btn-wrap .btn span {
    display: none;
  }
  .fc-modal-title {
    font-size: 1rem;
  }
  .ff-content-detail-wrap > .ff-content {
    padding: 0 0;
  }

  .faction-badge-background {
    display: flex;
    align-items: center;
    text-align: center;

    border-radius: 20px;
    background: #191919;
    backdrop-filter: blur(60px);
  }

  .faction-badge-wrapper > .fb-content-wrapper {
    display: flex;
    width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .fb-content-wrapper img {
    width: 200px;
    height: 200px;
    border-radius: 150px;
  }

  .fb-content-wrapper > .fb-text-wrapper {
    text-align: center;
    width: 400px;
  }

  .fb-text-wrapper > h5 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

    /* Headings/Heading 1 Mobile */
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }

  .fb-text-wrapper > p {
    color: #fff;
    text-align: center;
    font-family: var(--primary-font-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.16px;
    align-self: stretch;
  }

  .fb-btn-wrapper {
    padding: 0px 20px;
    width: auto;
  }
}

.accordion-toggle-new {
  display: none;
}

.mob-wrapper {
  display: none;
}

@media (max-width: 650px) {
  .share-button {
    width: 411px;
  }

  .top-section {
    gap: 50px;
  }
  .picture-holder img {
    height: 400px;
  }
  .close-button {
    top: 50px;
    right: 10px;
  }

  .mech-title {
    display: inline-flex;
    padding: 72px 24px 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .mech-title > p {
    background: linear-gradient(295deg, #ffff 0%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    mix-blend-mode: difference;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 113px;
    font-style: normal;
    font-weight: normal;
    line-height: 103%;
    letter-spacing: -7.64px;
    text-transform: uppercase;
  }
  .mech-picture {
    flex-shrink: 0;
    width: 500px;
    z-index: 9;
  }

  .mech-picture img {
    width: 700px;
  }
  .fb-text-wrapper1 > button {
    color: #fff;
    margin-top: 2%;
    align-items: center;
    align-self: stretch;
    background: rgba(68, 68, 68, 0.9);
    border: 1px solid #545454;
    border: 1px solid var(--grey-grey-600, #545454);
    border-radius: 4px;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 12px 23px;
    letter-spacing: 2px;
    font-size: 16px;
  }
  .fb-text-wrapper1 {
    padding: 0 3% 0 3%;
  }
  .fb-text-wrapper1 > h5 {
    color: #fff;
    /* Headings/Heading 1 Mobile */
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }
  .fb-text-wrapper1 > p {
    align-self: stretch;
    color: #fff;
    /* text-align: center; */
    font-family: var(--primary-font-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
  .mp-licence-card-wrap {
    width: auto;
  }
  .mp-mech-card-wrap {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
      2px 5px 12px 0px rgba(0, 0, 0, 0.48),
      10px 20px 22px 0px rgba(0, 0, 0, 0.42),
      22px 44px 30px 0px rgba(0, 0, 0, 0.25),
      39px 78px 35px 0px rgba(0, 0, 0, 0.07),
      61px 122px 38px 0px rgba(0, 0, 0, 0.01);
    width: auto;
  }
  .mob-wrapper {
    display: block;
  }
  .accordion-toggle-new {
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .licence-plate-wrap-mobile {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
  }
  .mrb {
    margin-bottom: 100px;
  }
  .mech-card-front {
    width: auto;
  }

  .section-title {
    padding: 10px 0;
    color: var(--secondary-color);
    text-transform: uppercase;
    font-family: var(--primary-color);
    font-size: 0.75rem;
    font-weight: normal !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #333333;
    align-items: center;
    cursor: pointer !important;
  }
  .accordion-toggle-new.active .accordion-icon {
    transform: rotate(180deg);
  }
  .mp-right-wrap {
    display: none;
  }
  .mobile-mp-right-wrap {
    display: flex;
    width: 350px;
    height: 856px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    gap: 33px;
    padding: 10px;
  }
  .mine-prep-wrapper {
    flex-wrap: wrap;
  }

  .mp-left-wrap {
    overflow: auto;
    padding: 10px;
    justify-content: start;
    padding-top: 60px;
    padding-bottom: 17%;
  }
  .mnft-card-image-upload-wrapper {
    gap: 1rem;
  }
  .mnft-card-image-upload {
    flex: 0 0 88px;
    align-items: center;
  }
  .mnft-user-profile-pic-wrap,
  .mnft-user-profile-pic-wrap > .avatar-thumnail,
  .mnft-img-wrap > .remove-btn,
  .mnft-img-wrap {
    width: 88px;
    height: 88px;
  }
  .mnft-card-image-upload .btn {
    padding: 8px 2px;
  }
}

.asset-title-wrap h2 {
  text-align: center;

  /* Headings/Heading 1 */
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%; /* 53.76px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
  background: linear-gradient(136deg, #383838 17.08%, #262626 75.31%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

@media (max-height: 875px) {
  .mech-picture,
  .mech-picture img {
    width: auto !important;
    height: 440px;
  }
}

@media (max-height: 780px) {
  .mech-picture,
  .mech-picture img {
    width: auto !important;
    height: 400px;
  }
}

@media (max-width: 450px) {
  .mnft-card-image-upload-wrapper {
    gap: 1rem;
    flex-direction: column;
  }

  .bottom-left,
  .share-button {
    width: 350px;
  }

  .bottom-right {
    top: 17px;
    left: -142px;
  }

  .mech-picture {
    width: 100%;
  }

  .mech-picture img {
    width: 100%;

    flex-shrink: 0;
  }

  .mnft-card-image-upload {
    flex: 0 0 88px;
    align-items: center;
  }
  .mnft-user-profile-pic-wrap,
  .mnft-user-profile-pic-wrap > .avatar-thumnail,
  .mnft-img-wrap > .remove-btn,
  .mnft-img-wrap {
    width: 88px;
    height: 88px;
  }
  .mnft-card-image-upload .btn {
    padding: 8px 2px;
  }

  .faction-badge-background {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 2px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #191919;
    backdrop-filter: blur(60px);
  }

  .faction-badge-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .faction-badge-wrapper > .fb-content-wrapper {
    display: flex;
    width: 357px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .fb-content-wrapper img {
    width: 166px;
    height: 166px;
    border-radius: 150px;
  }

  .fb-content-wrapper > .fb-text-wrapper {
    width: 357px;
  }

  .fb-text-wrapper > h5 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

    /* Headings/Heading 1 Mobile */
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
  }

  .fb-btn-wrapper {
    padding: 0px 20px;
    width: auto;
  }

  .avatar-selection-background > .avatar-selection-wrapper {
    display: flex;
    padding: 16px 16px 0 16px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > h5 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

    /* Headings/Heading 1 Mobile */
    font-family: var(--secondary-font);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 49.92px */
    letter-spacing: -0.48px;
    text-transform: uppercase;
    width: 280px;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    p {
    color: #fff;
    text-align: center;

    /* Paragraph/Large Text */
    font-family: var(--primary-font-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.16px;
    align-self: stretch;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .avatar-group-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 32px;
    align-self: stretch;
    flex-wrap: wrap;
    overflow-x: scroll;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .long-btn-wrapper {
    padding: 0 48px;
  }

  .avatar-selection-background
    > .avatar-selection-wrapper
    > .as-content-wrapper
    > .long-btn-wrapper
    > .btn-wrap
    > .long-btn
    h5 {
    color: var(--brand-white, #fff);
    text-align: center;

    /* Headings/Heading 5 */
    font-family: var(--primary-font-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
}

@media only screen and (min-height: 700px) and (min-width: 1024px) {
  /* .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen
    > .game-character
    img {
    height: 70%;
  } */
}
@media only screen and (max-height: 640px) and (max-width: 767px) {
  .faction-content-wrap {
    height: 420px;
  }
}

@media only screen and (min-width: 1199px) and (max-height: 700px) {
  .faction-slider
    > .swiper
    > .swiper-wrapper
    > .swiper-slide.fallen.active
    > .game-character {
    transform: scale(1);
  }
  .mech-nft-wrapper {
    align-items: flex-start;
  }
  .mnft-content-wrap {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    align-items: flex-start;
  }
}

@media (max-height: 885px) {
  .picture-holder img {
    max-height: 300px;
  }
  .share-complete-text {
    font-size: 40px !important;
  }
  .top-section {
    gap: 0;
  }
}
