.frame-wrapper {
  width: 100%;
  position: relative;
  background: var(--primary-color);
  margin-top: 1rem;
}

.fl-area-wrap {
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: var(--secondary-color);
  gap: 5px;
  padding: 5px;
  border-radius: 6px;
}
.frame > img {
  width: 100%;
  display: block;
}
.fl-area-wrap.not-find {
  border-radius: 10px;
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
  color: var(--status-red, #ff4d4d);
  width: 80%;
}
.full-screen-wrap {
  position: absolute;
  z-index: 2;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("../../../images/icons/map-icon.svg");
}

.full-screen-wrap:hover {
  height: 15px;
  background-image: url("../../../images/icons/map-icon-fill.svg");
}
.location-cordinate {
  width: calc(100% - 32px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lc-box {
  width: 28px;
  height: 28px;
  margin: auto;
  /* background: url(../../../images/icons/frame-icon.svg) no-repeat 0 0 / cover;  */
  display: flex;
  justify-content: center;
  align-items: center;
}
.lc-box > img {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* .lc-box:before{content:''; width:calc(50% - 30px); height: 1px; border-top:1px solid rgba(255, 255, 255, 0.5);top: 50%; left: 0; transform: translateY(-50%);position: absolute;}
.lc-box:after{content:''; width:calc(50% - 30px); height: 1px; border-top:1px solid rgba(255, 255, 255, 0.5); top: 50%; right: 0; transform: translateY(-50%);position: absolute;} */
.location-img-wrap {
  width: 20px;
  height: 20px;
  position: relative;
}
.location-img-wrap::before {
  content: "";
  width: 8px;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-52%, -52%) rotate(90deg);
  position: absolute;
}
.location-img-wrap::after {
  content: "";
  width: 8px;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.move-selection-modal {
  max-width: 427px;
  width: 90%;
  position: fixed;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--primary-color);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  transition: all 0.3s ease;
}
.move-selection-modal.animate {
  transform: translate(-50%, -50%);
  top: 50%;
}
.ms-modal-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 4rem;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}
.ms-modal-info {
  text-align: center;
  font-size: 14px;
  font-family: var(--primary-font-light);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.07px;
  color: var(--secondary-color);
  white-space: nowrap;
}
.ms-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ms-modal-footer .btn {
  background: transparent;
  border: 1px solid var(--grey-grey-600);
}
.ms-modal-footer .btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.ms-modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: none;
}
.ms-modal-backdrop.animate {
  display: block;
}

.location-grid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 272px;
  height: 153px;
  display: flex;
  flex-wrap: wrap;
}
.location-grid-item {
  flex: 0 0 17px;
  height: 17px;
  position: relative;
  cursor: pointer;
}
.location-hover-wrap {
  width: 21px;
  height: 21px;
  position: absolute;
  top: -2px;
  left: -2px;
  opacity: 0;
  transition: all 0.3s ease;
}
.location-grid-item:hover .location-hover-wrap {
  opacity: 1;
}

@media (max-width: 767px) {
  .move-selection-modal {
    padding: 2rem;
  }
}
