/* ======== Fonts ======== */

@font-face {
  font-family: "Druk Bold";
  src: url("../src/fonts/druk-bold-webfont.woff2") format("woff2"),
    url("../src/fonts/druk-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: "Druk Medium";
  src: url("../src/fonts/druk-medium-webfont.woff2") format("woff2"),
    url("../src/fonts/druk-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: "NB International Pro Light";
  src: url("../src/fonts/nbinternationalpro-light-webfont.woff2")
      format("woff2"),
    url("../src/fonts/nbinternationalpro-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: "NB International Pro Monospaced";
  src: url("../src/fonts/nbinternationalpro-monospaced-webfont.woff2")
      format("woff2"),
    url("../src/fonts/nbinternationalpro-monospaced-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: "NB International Pro Regular";
  src: url("../src/fonts/nbinternationalpro-regular-webfont.woff2")
      format("woff2"),
    url("../src/fonts/nbinternationalpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  ascent-override: 100%;
}

/* ======== Icons ======== */

@font-face {
  font-family: "icomoon";
  src: url("../src/fonts/icomoon.eot?2sjsct");
  src: url("../src/fonts/icomoon.eot?2sjsct#iefix") format("embedded-opentype"),
    url("../src/fonts/icomoon.ttf?2sjsct") format("truetype"),
    url("../src/fonts/icomoon.woff?2sjsct") format("woff"),
    url("../src/fonts/icomoon.svg?2sjsct#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-inspect:before {
  content: "\e900";
  color: #7f7f7f;
}

.icon-core:before {
  content: "\e901";
  color: #7f7f7f;
}

.icon-mine:before {
  content: "\e902";
  color: #7f7f7f;
}

/* ======== Variable ======== */
:root {
  --primary-color: #000;
  --secondary-color: #fff;
  --secondary-color-btn: 255, 255, 255;
  --sidebar-nav-bg: #181818;
  --primary-font: "NB International Pro Monospaced";
  --primary-font-light: "NB International Pro Light";
  --primary-font-regular: "NB International Pro Regular";
  --secondary-font: "Druk Bold";
  --secondary-font-medium: "Druk Medium";
  --color-darkbrown: 68, 68, 68;
  --color-green: #a8ceaa;
  --color-blue: #00d1ff;
  --title-color: #a2a2a2;
  --divider-color: #333333;
  --find: #00ff85;
  --process: #ffa71a;
  --nofind: #fc3e3e;
  --inprogress: #00d1ff;
  --ready: #64bbeb;
  --mint: #bedfcf;
  --grey-grey-400: #dadada;
  --grey-grey-500: #808080;
  --grey-grey-600: #545454;
  --grey-grey-700: #444;
  --grey-grey-800: #707070;
  --grey-grey-900: #1c1c1c;
  --grey-grey-1000: #1b1b1b;
  --grey-grey-400: #dadada;
  --status-green: #00ff85;
  --accents-64: #f2ef4c;
  --status-orange: #ffa71a;
  --status-red: #ff4d4d;
  --depth-1: #64bbeb;
  --depth-2: #f2ef4c;
  --depth-3: #aa5afa;
  --gold: #807956;
  --silver: #747474;
  --bronze: #7e643b;
  --gold-text: #514a27;
  --silver-text: #484848;
  --bronze-text: #51370e;
}
/*-----Loader Css------*/
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: rgba(0, 0, 0.8);
}
button {
  cursor: pointer;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--status-green);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.fade {
  opacity: 0;
  transition: opacity 2s ease;
}

.fade.active {
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1600px) {
  .inner-content-container {
    width: 100% !important;
  }
}
/* ======== Default Settings ======== */
html {
  font-size: 16px;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  padding: 67px 0 0 0;
  background: #000;
  font-family: var(--primary-font);
  font-weight: normal;
}

/* ======== Buttons ======== */
.btn {
  border: 1px solid var(--grey-grey-600);
  outline: none;
  background-color: rgba(var(--color-darkbrown), 1);
  border-radius: 4px;
  padding: 0.625rem;
  font-family: var(--primary-font);
  font-size: 12px;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s linear;
  color: var(--secondary-color);
  height: 36px;
  letter-spacing: 0.36px;
}

.auth-btn {
  color: var(--status-green, #00ff85);

  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
  border-radius: 4px;
  border: 1px solid var(--status-green, #00ff85);
  background: var(--status-find-background, rgba(0, 255, 133, 0.1)) !important;
}

.auth-btn:hover {
  border-radius: 4px;
  border: 1px solid var(--brand-white, #fff);
  background: var(--status-green, #00ff85) !important;
  color: var(--brand-black, #000);
  text-align: center;

  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}

.toggle-section {
  width: 100%;
  height: auto;
  display: flex;
  gap: 8px;

  padding: 4px 8px 6px;
  border-radius: 2px;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 16px;
}

.toggle-section p {
  color: var(--grey-grey-500, #808080);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.1px;
}

.switch {
  position: relative;
  display: inline-block;
  display: flex;
  width: 44px;
  height: 26px;
  padding: 2px 3px;
  align-items: center;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
  border-radius: 4px;
  border: 1px solid var(--status-green, #00ff85);
  background: var(--status-find-background, rgba(0, 255, 133, 0.1)) !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-checked {
  background: var(--grey-grey-600, #545454) !important;
  border: none;
}

.slider-checked:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  left: 4px !important;
  bottom: 3px !important;
  background: var(--brand-white, #fff) !important;
  filter: drop-shadow(0px 2px 5px rgba(103, 110, 118, 0.08))
    drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12));

  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  left: 3px;
  bottom: 2px;
  background: var(--status-green, #00ff85);

  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-section p span {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
  letter-spacing: 0.1px;
}
.btn:hover,
.btn:focus {
  background: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--secondary-color);
}

.btn-fluid {
  width: 100%;
}

.btn-secondary {
  background-color: rgb(var(--secondary-color-btn)) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--grey-grey-600);
}

.btn-secondary:hover {
  background: var(--grey-grey-900) !important;
  color: var(--secondary-color) !important;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}

.btn:disabled,
.btn[disabled],
button[disabled] .btn:disabled:hover {
  /* background: var(--grey-grey-600) !important;
  border-color: var(--grey-grey-500) !important;
  color: var(--grey-grey-500) !important;*/
  text-shadow: unset !important;
}

.btn-secondary:disabled,
.btn-secondary[disabled],
button[disabled] .btn-secondary:disabled:hover {
  border: 1px solid #ff4d4d !important;
  background-color: #ff4d4d !important;
  color: black !important;
  text-shadow: unset !important;
}

.btn-fluid:disabled,
.btn-fluid[disabled],
button[disabled] .btn-fluid:disabled:hover {
  border: 1px solid #ff4d4d !important;
  background-color: #ff4d4d !important;
  color: black !important;
  text-shadow: unset !important;
}

.status {
  width: 279px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  justify-content: center;
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.status.complete {
  color: var(--accents-32, #64bbeb);

  text-shadow: 0px 0px 5px rgba(101, 187, 235, 0.85),
    0px 0px 1px rgba(101, 187, 235, 0.5), 0px 1px 1px rgba(101, 187, 235, 0.15),
    0px 1px 41px #65bbeb;
}

.status.error {
  color: var(--status-red, #ff4d4d);

  /* Glow/Red */
  text-shadow: 0px 0px 5px rgba(255, 77, 77, 0.85),
    0px 0px 1px rgba(255, 77, 77, 0.5), 0px 1px 1px rgba(255, 77, 77, 0.15),
    0px 1px 41px rgba(255, 77, 77, 0.02);
}

.status.progress {
  color: var(--status-orange, #ffa71a);

  /* Glow/Orange */
  text-shadow: 0px 0px 5px rgba(255, 167, 26, 0.85),
    0px 0px 1px rgba(255, 167, 26, 0.5), 0px 1px 1px rgba(255, 167, 26, 0.15),
    0px 1px 41px rgba(255, 167, 26, 0.02);
}

.status.ready {
  color: var(--status-green, #00ff85);
}
.status.warning {
  color: var(--ready, #ffa71a);
}

.status.find {
  background-color: rgba(0, 255, 133, 0.7);
  color: #000;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.24) inset;
  background-image: url(../src/images/noise.png);
}

.btn.error {
  border: 1px solid #ff4d4d;
  color: #ff4d4d;
  background: rgba(250, 90, 90, 0.2);
}

.btn.error:hover {
  border: 1px solid #ff4d4d;
  color: #721d1d;
  background: #ff4d4d;
}

/* Set the height of the scrollbar */

/* .btn-large.status:hover{background-color: unset;} */

/* ======== Sidebar ======== */
/* .sidebar{ position: absolute; top:50%;  transform: translateY(-50%); z-index: 1;} */

/* ======== Home ======== */
#root {
  width: 100%;
  height: 100%;
}

canvas {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.App {
  background: var(--primary-color);
  height: 100%;
  width: 100%;
  border-radius: 20px;
  position: relative;
}

.align-flex-end {
  display: flex;
  align-items: flex-end;
}

.main-asset-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 46px;
}

.unity-asset-holder {
  position: absolute;
  display: flex;
  top: -5px;
  align-items: center;
  width: 100vw;
  cursor: grabbing;
  height: 100%;
}

.refinement-progress-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15.608px;
  z-index: 99999;
  width: 407px;
}

.rp-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.refinement-progress-wrap p {
  color: var(--status-red, #ff4d4d);
  text-align: center;

  text-shadow: 0px 0px 5px rgba(255, 77, 77, 0.85),
    0px 0px 1px rgba(255, 77, 77, 0.5), 0px 1px 1px rgba(255, 77, 77, 0.15),
    0px 1px 41px rgba(255, 77, 77, 0.02);
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.refinement-progress-wrap p {
  color: var(--status-orange, #ffa71a);
}

.error-animation-wrap {
}

.rp-mid {
  display: flex;
  height: 15px;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.rp-mid .rectangle {
  height: 15px;
  width: 3.05px;
  align-self: stretch;
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
}

.rp-mid .rectangle.yellow {
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
}
.rp-mid .rectangle.complete {
  background: var(--status-red, #ff4d4d);
  box-shadow: 0px 0px 5px 0px rgba(255, 77, 77, 0.85),
    0px 0px 1px 0px rgba(255, 77, 77, 0.5),
    0px 1px 1px 0px rgba(255, 77, 77, 0.15),
    0px 1px 41px 0px rgba(255, 77, 77, 0.02);
}

.rp-mid .rectangle.complete.yellow {
  background: var(--status-orange, #ffa71a);
  box-shadow: 0px 0px 25px 0px rgba(255, 77, 77, 0.85),
    0px 0px 3px 0px rgba(255, 77, 77, 0.5),
    0px 1px 13px 0px rgba(255, 77, 77, 0.15),
    0px 1px 51px 0px rgba(255, 77, 77, 0.04);
}
.asset-interaction-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  position: relative;
}

.middle-frame {
  /* width: 778px;
  min-width: 600px; */
  height: 100%;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 99px;
  user-select: none;
}

.asset-title-wrap {
  display: inline-flex;
  position: relative;
  z-index: 2;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.asset-title-wrap h2 {
  text-align: center;

  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
  background: linear-gradient(136deg, #383838 17.08%, #262626 75.31%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

.bottom-left-end {
  width: 522px;
  justify-self: flex-end;
}

.share-text-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15.608px;
}

.share-text-frame p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Paragraph/XL Text */
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.09px;
}

.share-text-frame span {
  color: var(--status-green, #00ff85);

  /* Paragraph/XL Text */
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.09px;
}

.share-cta-frame {
  display: flex;
  width: 341px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.share-button-1 {
  border-radius: 4px;
  display: flex;
  width: 63.5px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid var(--grey-grey-900, #1c1c1c);
  background: rgba(68, 68, 68, 0.7);
  backdrop-filter: blur(9px);
  border: none;
  cursor: pointer;
}

.overlay-none {
  opacity: 1;
}

@keyframes dissolve {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animate-dissolve {
  animation: dissolve 0.4s ease-in-out;
}

.share-button-2 {
  display: flex;
  width: 198px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-900, #1c1c1c);
  background: var(--brand-white, #fff);
  cursor: pointer;
}

.button-2-clicked {
  /* Apply animation when clicked */
  animation-name: moveInBottom;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  /* You should define the 'moveInBottom' animation keyframes separately */
}

/* Define the animation */
@keyframes moveInBottom {
  from {
    width: 198px; /* Initial position */
  }
  to {
    width: 190px; /* Move the button downward */
  }
}

.share-button-2 p {
  color: var(--grey-grey-900, #1c1c1c);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.thumbnail-slider-wrap {
  width: 484px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  overflow-x: hidden;
  user-select: none;
}

.thumbnail-slider {
  display: inline-flex;
  padding: 0px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.thumbnail-slider-wrap .thumbnail {
  display: flex;
  width: 70px;
  height: 40px;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid var(--grey-grey-600, #545454);
  background: var(--brand-black, #000);
  cursor: pointer;
}
.thumbnail-slider-wrap .thumbnail.thumbnail-clicked {
  border: 0.5px solid var(--status-green, #00ff85);

  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}
.thumbnail-slider-wrap .thumbnail:hover {
  border: 0.5px solid var(--status-green, #00ff85);

  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}

.thumbnail-slider-wrap .thumbnail img {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
  object-fit: contain;
  user-select: none;
}
.asset-bottom-left {
  position: relative;
  display: inline-flex;
  padding: 24px;
  align-items: flex-end;
  gap: 41.94px;
  z-index: 999999;
}

.asset-bottom-left img {
  width: 47px;
  height: 88.629px;
  flex-shrink: 0;
  border-radius: 2.686px;
}

.asset-bottom-left .info {
  display: flex;
  width: 172px;

  align-items: flex-start;
  gap: 13.322px;
}
.asset-bottom-left .info .info-labels,
.info-values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.553px;
}

.asset-bottom-left .info .info-labels > p {
  color: #545454;
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 104%; /* 9.36px */
  text-transform: uppercase;
}

.asset-bottom-left .info .info-values > p {
  color: var(--brand-white, #fff);
  text-shadow: 0px 0px 5.812256813049316px rgba(255, 255, 255, 0.6);
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 9.36px */
  text-transform: uppercase;
}

.equal-width {
  width: calc(100% - 778px);
}
.arrow-left {
  position: absolute;
  left: 30px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.arrow-right {
  position: absolute;
  right: 30px;
  transform: rotate(90deg);
  cursor: pointer;
}

.bottom-right-text {
  min-width: 200px;
  padding: 24px;
  display: flex;
  gap: 71px;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  z-index: 999999;
}

.asset-close-button {
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
  z-index: 99;
}
.asset-viewer-container {
  height: 100%;
  margin-top: 30px;
}

/* .right-shrink {
  width: calc(100% - 1300px);
} */
.bottom-right-text p {
  color: var(--brand-white, #fff);

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.mech-signal {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.mech-bottom-left {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
}

.mech-signal img {
  width: 184px;
  height: 100px;
  border-radius: 8px;
  border: 0.5px solid var(--grey-grey-400, #dadada);
}

.mech-badge {
  display: flex;
  position: relative;
  width: 184px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0.5px solid var(--grey-grey-400, #dadada);
  background: var(--brand-black, #000);
  justify-content: center;
  align-items: flex-start;
}
.corner-circles {
  position: absolute;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.info-row {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}

.top-left-circle {
  top: 0;
  left: 0;
}

.top-right-circle {
  top: 0;
  right: 0;
}

.bottom-left-circle {
  bottom: 0;
  left: 0;
}

.bottom-right-circle {
  bottom: 0;
  right: 0;
}

.badge-info {
  display: flex;
  width: 171px;
  flex-direction: column;
  text-align: center;

  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  color: var(--grey-grey-400, #dadada);
  gap: 8px;
  /* Mono Labels/Mini Labels */
  font-family: var(--secondary-font-regular);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 9.36px */
  text-transform: uppercase;
}

.border-field {
  width: 60.444px;
  height: 10.929px;
  flex-shrink: 0;
  border: 0.5px solid var(--grey-grey-400, #dadada);
}

.border-field-long {
  width: 173px;
  padding-left: 15px;
  height: 13.929px;
  flex-shrink: 0;
  border: 0.5px solid var(--grey-grey-400, #dadada);
}

/*new contewnt*/
.main-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.grid-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.radio {
  position: absolute;
  bottom: 22px;
  left: 22px;
  z-index: 999;
}

/* ======== PROSPECTING ======== */
.col {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  padding: 10px 8px;
}

.section-title {
  padding: 10px 0;
  color: var(--secondary-color);
  text-transform: uppercase;
  font-family: var(--primary-color);
  font-size: 0.75rem;
  font-weight: normal !important;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  cursor: pointer !important;
}

.section-title:first-child {
  border-bottom: 1px solid #1c1c1c;
}

.coring-container {
  height: calc(100% - 8px);
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.info-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
}

.info-wrap > .info-wrap-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.125rem;
}

.info-wrap > .info-wrap-row .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.info-wrap > .info-wrap-row .info > .info-title,
.info-wrap .info > .info-detail {
  width: 100%;
}

.info-wrap > .info-wrap-row .info > .info-title {
  font-family: var(--primary-font);
  font-size: 10px;
  font-weight: normal;
  color: var(--grey-grey-700) !important;
  text-transform: uppercase;
}

.info-wrap > .info-wrap-row .info > .info-detail {
  font-family: var(--primary-font-light);
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.07px;
  color: var(--mint);
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--divider-color);
  margin: 0;
  flex-shrink: 0;
}

.dropdown-top-badge {
  display: flex;
  padding: var(--edge-4, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--edge-4, 4px);
  border-radius: var(--edge-4, 4px);
  border: 1px solid var(--gradients-hologram, #7e6a7e);
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
}

.dropdown-top-badge-certified {
  background: rgba(173, 154, 90, 0.2);
}

.dropdown-top-badge p {
  color: var(--brand-white, #fff);
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
  text-wrap: nowrap;
}

.licence-chip {
  display: flex;
  padding: 3px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--status-red, #ff4d4d);
}

.licence-chip-certified {
  background: #ad9a5a;
}

.licence-chip p {
  color: var(--brand-white, #fff);
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 10px */
  text-transform: uppercase;
}

.licence-chip-certified p {
  color: #000;
}
.profile-dropdown-container {
  top: 40px;
  position: absolute;
  border-radius: 4px;
  background: var(--brand-black, #000);
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dropdown-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.dropdown-nav-item p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Mono Labels/Small Labels */
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}
/* .accordion{ max-height: 0;transition: all .8s linear; overflow: hidden; }
.accordion.active{max-height: 100%; } */

.ready-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.explore-text {
  width: 100%;
  font-size: 11px;
  font-family: var(--primary-font);
  color: var(--secondary-color);
  text-align: center;
  line-height: 190%;
  text-transform: uppercase;
}

.explore-text span {
  color: var(--status-green);
}

/* ======== Progress List css ======== */
.pl-main-container {
  width: 100%;
}

.pl-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pl-wrapper.pl-content-wrap {
  background: #1c1c1c;
  border: 1px solid #323232;
  border-radius: 2px;
  padding: 4px 2px;
}

.pl-wrapper > .pl-row {
  width: 100%;
  padding: 2px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.625rem;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: -0.1px;
  border: 1px solid transparent;
  cursor: default;
  gap: 8px;
  line-height: 104%;
  position: relative;
  text-transform: uppercase;
}

.pl-wrapper > .pl-row.pl-header {
  color: #545454;
  text-transform: uppercase;
  font-size: 9px;
}

.pl-wrapper > .pl-row.pl-header:hover {
  background-color: transparent;
}

.pl-wrapper > .pl-row.pl-header > .pl-sample {
  text-align: center;
}

.pl-wrapper > .pl-row.pl-header > .pl-depth {
  text-align: center;
}

.pl-wrapper > .pl-row.pl-header > .pl-result {
  text-align: center;
}

.pl-wrapper > .pl-row.inprogress:hover {
  background: rgba(251, 190, 94, 0.2);
  border: 1px solid #d68c16;
  border-radius: 4px;
}

.pl-wrapper > .pl-row.no-find:hover {
  background: rgba(250, 90, 90, 0.2);
  border: 1px solid #ff4d4d;
  border-radius: 4px;
}

.pl-wrapper > .pl-row.find:hover {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid var(--find);
  border-radius: 4px;
}

.pl-wrapper > .pl-row.ready:hover {
  background: rgba(100, 187, 235, 0.2);
  border: 1px solid var(--ready);
  border-radius: 4px;
}

.pl-wrapper > .pl-row > .remove-plan {
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 20px;
  display: none;
  cursor: pointer;
}

.pl-wrapper > .pl-row > .remove-plan > span {
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background-color: var(--nofind);
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  color: var(--primary-color);
  border-radius: 2px;
}

.pl-wrapper > .pl-row:hover > .remove-plan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-wrapper > .pl-row > .pl-number {
  flex: 0 0 31px;
}

.pl-wrapper > .pl-row > .pl-status {
  flex: 0 0 75px;
}

.pl-wrapper > .pl-row > .pl-sample {
  flex: 0 0 56px;
}

.pl-wrapper > .pl-row > .pl-result {
  flex: 0 0 55px;
}

.pl-wrapper > .pl-row > .pl-usage {
  flex: 0 0 63px;
  text-align: right;
}

.pl-wrapper > .pl-row > .pl-detail {
  flex: 0 0 46px;
}

.pl-wrapper > .pl-row > .pl-number.e-ledger {
  width: 60px;
  flex: 0 0 60px;
}

.pl-wrapper > .pl-row > .pl-depth {
  flex: 0 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pl-wrapper > .pl-row > .pl-depth > img {
  width: 18px;
  height: 18px;
}

.pl-wrapper > .pl-row > .pl-process {
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
}

.pl-wrapper > .pl-row > .pl-process > .pl-process-icon {
  height: 10px;
}

.pl-wrapper > .pl-row > .pl-result > span {
  width: 100%;
  height: 18px; /*padding: 4px; display: block;*/
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  line-height: 104%;
  border-radius: 2px;
  letter-spacing: -0.1px;
}

.pl-wrapper > .pl-row > .pl-result > span.inProgress {
  background: #ffa71a;
}

.pl-wrapper > .pl-row > .pl-result > span.find {
  background: var(--find);
}

.pl-wrapper > .pl-row > .pl-result > span.none {
  background: var(--nofind);
}

.pl-wrapper > .pl-row > .pl-result > span.ready {
  background: var(--ready);
}

.pl-wrapper > .pl-row > .pl-sample > .sample {
  width: 100%;
  height: 18px;
  border-radius: 2px;
  overflow: hidden;
  background-size: cover;
}

.pl-wrapper > .pl-row > .pl-sample > .sample.inProgress {
  opacity: 0.2;
}

.pl-wrapper > .pl-row > .pl-result > span.inProgress {
  background: linear-gradient(
    to right,
    #ffa71a 0%,
    #ffa71a 50%,
    transparent 0%
  );
  background-size: 200% 100%;
  animation: fillBackground 1s linear;
}

@keyframes fillBackground {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

/* ======== Accordion css ======== */
.accordion-content {
  width: 100%;
}

.accordion-toggle {
  transition: all 0.3s ease;
  touch-action: none;
}

.accordion-icon {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.accordion-toggle.active .accordion-icon {
  transform: rotate(180deg);
}

.collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
  position: relative;
}

.collapse.show {
  height: auto;
}

#report-btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

#report-btn.fixed {
  position: absolute;
  bottom: 0px;
  padding: 10px 0;
  background: var(--primary-color);
}

.scrollbar-content {
  padding-right: 10px;
}

.thumb-vertical {
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.5);
}

.track-vertical {
  position: absolute;
  width: 6px;
  display: block !important;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.reporting-modal-wrap {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  padding-top: 70px;
  padding-bottom: 4rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.reporting-modal-content {
  max-width: 1160px;
  width: 90%;
  margin: auto;
  margin-top: 80px;
}

.thumbnail-container {
  margin-top: 40px;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.reporting-footer {
  position: fixed;
  z-index: 1001;
  width: 100%;
  left: -4px;
  height: 142px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.rf-container {
  max-width: 1048px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.reporting-footer .rf-container .btn {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid var(--grey-grey-600);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  border-radius: 4px;
}

.reporting-footer .rf-container .btn:hover {
  background: var(--secondary-color);
  border: 1px solid var(--grey-grey-600);
}

.reporting-title {
  width: 100%;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  line-height: 104%;
}

.reporting-title p {
  margin: 5px 0;
  color: var(--accents-crt-text, #61e8a2);
  text-shadow: 0px 0px 8.571385383605957px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.report-core-icon {
  width: 48px;
  height: 48px;
}

.reporting-title br {
  display: none;
}

.core-drill-image {
  align-self: center;
}

.reporting-date {
  font-size: 12px;
  color: var(--status-green);
  margin-top: 10px;
  display: block;
  line-height: normal;
  text-shadow: none;
  letter-spacing: normal;
  font-family: var(--primary-font);
}

.reporting-modal-backdrop {
  width: 100%;
  height: 100%;
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(1, 26, 20, 0.7);
  backdrop-filter: blur(60px);
}

.reporting-modal-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  width: 100%;
}

.reporting-modal-wrap {
  scrollbar-width: thin;
  scrollbar-color: #292828 !important;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.reporting-modal-wrap::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.reporting-modal-wrap::-webkit-scrollbar-track {
  background-color: #292828;
}

.reporting-modal-wrap::-webkit-scrollbar-thumb:vertical {
  background: #0c0c0c !important;
  border-radius: 10px;
}

.reporting-close-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.28571px;
  gap: 6.43px;
  width: 24px;
  height: 24px;
  background: #1c1c1c;
  border: 1px solid #444444;
  border-radius: 100%;
  position: fixed;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
  z-index: 999;
  overflow: hidden;
}

.reporting-wrap-main {
  width: 100%;
  margin-bottom: 5rem;
}

.reporting-wrap-main .section-title {
  font-size: 1rem;
  border-bottom: 1px solid #b1afaf;
  cursor: default !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}

.reporting-summary-wrap {
  width: 100%;
  height: auto;
  display: flex;
  gap: 20px;
  margin-top: 2.3125rem;
}

.reporting-summary-left {
  flex: 0 0 318px;
  overflow: hidden;
}

.reporting-summary-right {
  flex: 1 1 100%;
}

.reporting-summary-right .snap-shot {
  width: 100%;
}

.reporting-summary-right .snap-shot img {
  /*width: 100%; */
  display: block;
}

.reporting-summary-right .section-title {
  width: calc(100% - 33px);
  margin: auto;
  margin-bottom: 10px;
}

.mine-reporting-summary-right {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.mine-reporting-summary-right .snap-shot {
  width: 100%;
}

.mine-reporting-summary-right .snap-shot img {
  /*width: 100%; */
  display: block;
}

.mine-reporting-summary-right .section-title {
  width: calc(100% - 33px);
  margin: auto;
  margin-bottom: 10px;
}

.reporting-location-title {
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 10px;
}

.reporting-location-detail-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.reporting-location-detail-wrap > .reporting-location-detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reporting-location-detail-wrap
  > .reporting-location-detail
  > .reporting-location-detail-title {
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  color: #757575;
  flex: 0 0 75px;
}

.reporting-location-detail-wrap
  > .reporting-location-detail
  > .reporting-location-detail-dec {
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  color: #fff;
  flex: 1 1 100%;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}

.report-modal-message {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 3rem 0;
  text-align: center;
  font-size: 1.25rem;
  color: #808080;
  font-family: var(--primary-font-regular);
}

.report-modal-message .message {
  width: 100%;
  height: auto;
}

.report-modal-message .message.success-text {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1.125rem;
}

.report-modal-message .message.success-text > div span {
  color: var(--secondary-color);
}

.report-modal-message span {
  color: var(--secondary-color);
}

.report-modal-message span.find {
  color: var(--find) !important;
}

.report-modal-message .report-message.best-discoveries {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.discoveries-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.report-modal-message .message.best-discoveries span.remove-char {
  color: #808080;
}

.dicovery {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.report-modal-message .message.success-text .depth-icon-wrap {
  width: 42px;
  height: 10px;
  display: inline-block;
}

.report-modal-message .message.success-text .highest-yield {
  line-height: 160%;
  pointer-events: none !important;
}

.report-modal-message
  .message.success-text
  .highest-yield
  > .depth-icon-wrap
  > .depth-unit {
  transform: translate(-50%, -55%) !important;
  opacity: 1 !important;
}

.session-time-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.report-message {
  color: var(--accents-crt-text, #61e8a2);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.45px;
  text-transform: uppercase;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 8.571385383605957px rgba(255, 255, 255, 0.6);
}

.report-message span {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}

.st-detail {
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 0 0 133px;
}

.st-detail-title {
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  color: #fff;
  flex: 1;
}

.st-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.st-wrap.session-start {
  color: var(--find);
}

.st-wrap > .st-icon {
  height: 16px;
}

.st-wrap > .st-time {
  font-size: 1rem;
}

.st-wrap.session-start > .st-icon svg {
  fill: var(--find);
}

.session-remaning {
  color: var(--nofind);
}

.session-remaning > .st-icon svg {
  fill: var(--nofind);
}

.session-process {
  color: var(--accents-64);
}

.session-process > .st-icon svg {
  fill: var(--accents-64);
}

.cr-summary-left .frame-wrapper {
  margin-top: 5px;
}

.close-icon {
  margin-top: -4px;
}

.close-icon svg {
  fill: #323232 !important;
}

.react-draggable {
  cursor: pointer;
}

.mining-reporting-left-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.depth-icon-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.depth-icon-wrap > .depth-unit {
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  opacity: 0;
  transition: all 0.3s ease;
}

.depth-icon-wrap > .depth-unit > .depth-frame {
  width: 100%;
  display: flex;
  justify-content: center;
}

.depth-icon-wrap > .depth-unit > .depth-frame > .depth-frame-icon > div {
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.depth-icon-wrap > .depth-unit > .depth-frame svg {
  fill: var(--grey-grey-600);
}

.depth-icon-wrap > .depth-unit > .depth-unit-wrap {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.depth-icon-wrap > .depth-unit > .depth-unit-wrap > .depth-hover-corner {
  height: 18px;
  flex: 0 0 4px;
  position: relative;
  display: flex;
}

.depth-icon-wrap
  > .depth-unit
  > .depth-unit-wrap
  > .depth-hover-corner.left:after {
  content: "";
  width: 2px;
  height: 16px;
  position: absolute;
  top: 1px;
  right: -1px;
  background: var(--primary-color);
}

.depth-icon-wrap
  > .depth-unit
  > .depth-unit-wrap
  > .depth-hover-corner.right:after {
  content: "";
  width: 2px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: -1px;
  background: var(--primary-color);
}

.depth-icon-wrap > .depth-unit > .depth-unit-wrap > .depth-unit-wrap {
  width: 100%;
  display: flex;
}

.depth-icon-wrap > .depth-unit > .depth-unit-wrap > .depth-unit-text {
  display: flex;
  justify-content: center;
  line-height: 1.6;
  height: 18px;
  padding: 0 2px;
  background: var(--primary-color);
  font-size: 10px;
  font-family: var(--primary-font);
  color: var(--grey-grey-600);
  white-space: nowrap;
}

.depth-icon-wrap.depth-1 > .depth-unit > .depth-frame svg {
  fill: var(--depth-1);
}

.depth-icon-wrap.depth-2 > .depth-unit > .depth-frame svg {
  fill: var(--depth-2);
}

.depth-icon-wrap.depth-3 > .depth-unit > .depth-frame svg {
  fill: var(--depth-3);
}

.depth-icon-wrap.depth-1 > .depth-unit > .depth-unit-wrap > .depth-unit-text {
  color: var(--depth-1);
}

.depth-icon-wrap.depth-2 > .depth-unit > .depth-unit-wrap > .depth-unit-text {
  color: var(--depth-2);
}

.depth-icon-wrap.depth-3 > .depth-unit > .depth-unit-wrap > .depth-unit-text {
  color: var(--depth-3);
}

.depth-icon-wrap:hover .depth-unit {
  opacity: 1;
  transform: translate(-50%, -53%);
}

.depth-icon-wrap:hover > img {
  visibility: hidden;
}

/*Inner Page Style */

.inner-main-container {
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  background: var(--grey-grey-1000);
  position: relative;
}

.inner-container-wrap {
  width: calc(100% - 130px);
  margin: 0 auto;
  padding-top: 4.75rem;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
}

.inner-page-heading {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.inner-content-container {
  width: calc(100% - 264px);
  margin: 0 auto;
  padding-top: 2.5rem;
}

.inner-container-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  scrollbar-width: thin !important;
  scrollbar-color: transparent !important;
  overflow: auto;
  -ms-overflow-style: none !important; /* IE 11 */
  scrollbar-width: none !important; /* Firefox 64 */
}

.inner-container-wrap::-webkit-scrollbar {
  width: 0px;
}

.inner-container-wrap::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0;
}

.inner-container-wrap::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.inner-section-heading {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-family: var(--primary-font);
}

.inner-main-container .loader-container {
  position: absolute !important;
  z-index: 1;
}
.swiper-slide {
  margin: 0 15px !important;
}
.asset-interaction-wrap .swiper-slide {
  width: 70px !important;
}
.width-70 {
  width: 70px !important;
}
/* @media (min-width: 1710px) {
  .unity-asset-holder {
    height: 80%;
  }
}

@media (max-width: 1710px) {
  .unity-asset-holder {
    height: 70%;
  }
}

@media (max-width: 1500px) {
  .unity-asset-holder {
    height: 60%;
  }
}

@media (max-width: 1250px) {
  .unity-asset-holder {
    height: 50%;
  }
}

@media (max-width: 1020px) {
  .unity-asset-holder {
    height: 40%;
  }
}
@media (max-width: 870px) {
  .unity-asset-holder {
    height: 30%;
  }
} */

@media (max-width: 1023px) {
  body {
    padding-top: 50px;
  }

  .reporting-title {
    font-size: 2.875rem;
  }

  .reporting-title br {
    display: block;
  }

  .reporting-footer .rf-container .btn-large {
    font-size: 10px;
  }

  .reporting-summary-wrap {
    flex-direction: column;
  }

  .reporting-summary-left {
    flex: 1 1 100%;
  }

  .report-modal-message .message {
    font-size: 1rem;
  }

  .radio {
    display: none;
  }
}

@media (max-width: 767px) {
  .report-modal-message .message.best-discoveries,
  .discoveries-wrap {
    flex-direction: column;
  }

  .report-modal-message .message.best-discoveries {
    gap: 10px;
  }

  .discoveries-wrap {
    gap: 8px;
  }

  .report-modal-message .message.best-discoveries .remove-char {
    display: none !important;
  }

  .dicovery span {
    width: 45px;
  }

  .btn {
    font-size: 11px;
    padding: 0.625rem 0.3125rem;
  }
}
