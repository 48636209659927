/* ======== Header ======== */
header {
  width: 100%;
  min-height: 50px;
  background-color: var(--primary-color);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 289px 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 10px 2.25rem;
}
header ul {
  display: grid;
  justify-content: start;
  margin: 0;
  padding: 0;
  width: 100%;
  grid-auto-flow: column;
  gap: 1rem;
  list-style-type: none;
}
header ul.right-nav li,
header ul.left-nav li {
  position: relative;
  align-self: center;
}
header ul.right-nav li span,
header ul.left-nav li span {
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875rem;
  opacity: 0.8;
  cursor: pointer;
}

header ul li:hover::before {
  content: "";
  width: 2px;
  height: 2px;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 10px;
}
header ul li:hover::after {
  content: "";
  width: 2px;
  height: 2px;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 10px;
}
/* header ul.right-nav li:hover::before, header ul.left-nav li:hover::before{content:none;}
header ul.right-nav li:hover::after, header ul.left-nav li:hover::after{content:none;} */
header ul li.active::before {
  content: "";
  width: 2px;
  height: 2px;
  background: #8adeb4;
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 10px;
}
header ul li.active::after {
  content: "";
  width: 2px;
  height: 2px;
  background: #8adeb4;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border-radius: 10px;
}

header ul.right-nav {
  justify-content: end;
}
.logo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.logo-bottom {
  position: absolute;
  width: 100%;
  height: 16px;
  width: 100%;
  background: var(--primary-color);
  bottom: -26px;
  z-index: 1;
}
.logo-bottom::before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: -16px;
  z-index: 2;
  background: url("../../images/logo-bottom-left-corner.svg") no-repeat 0 0;
  display: inline-block;
  vertical-align: top;
}
.logo-bottom::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: -16px;
  z-index: 2;
  background: url("../../images/logo-bottom-right-corner.svg") no-repeat 0 0;
  display: inline-block;
  vertical-align: top;
}
.auth-wrap {
  cursor: pointer;
}
.auth-wrap::after,
.auth-wrap::before {
  content: none;
}
.auth-wrap:hover::before,
.auth-wrap:hover::after {
  content: none !important;
}
.auth-wrap .avatar {
  width: 24px;
  height: 24px;
  position: relative;
  border: 2px solid #8adeb4;
  border-radius: 100%;
  overflow: hidden;
  border: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.auth-wrap .avatar img {
  width: 23px;
  height: 23px;
  border: 100%;
  object-fit: cover;
}
.auth-wrap .avatar.have-name {
  border: unset;
  background: var(--find);
  overflow: unset;
  text-transform: uppercase;
}
.auth-wrap .avatar.not-register {
  border: unset;
  background: var(--nofind);
  overflow: unset;
}
.auth-wrap .avatar > .profile-dropdown {
  display: none;
  position: absolute;
  bottom: -35px;
  margin: auto;
  width: 64px;
  font-size: 10px;
  font-family: var(--primary-font-regular);
  color: var(--primary-color);
  border-radius: 2px;
  padding: 8px;
  text-transform: uppercase;
  text-align: center;
}
.auth-wrap .avatar.have-name:hover > .profile-dropdown {
  background: var(--find);
  display: block;
}
.auth-wrap .avatar.not-register:hover > .profile-dropdown {
  background: var(--nofind);
  display: block;
}

.balance {
  width: 71px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}
.balance::before,
.balance::after {
  content: none !important;
}
.balance-icon-wrap {
  flex: 0 0 21px;
}
.balance-icon-wrap > .balance-icon {
  height: 20px;
}
.balance-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 20px;
}
.balance-amount {
  color: var(--find) !important;
  font-size: 12px !important;
  line-height: 0px !important;
  opacity: 1 !important;
  font-family: var(--primary-font-regular);
}
.balance .balance-icon-wrap > .balance-icon svg {
  fill: var(--find);
}
.balance.nill .balance-icon-wrap > .balance-icon svg {
  fill: #fa5a5a;
}

.balance-title {
  color: var(--secondary-color);
  font-size: 10px;
  text-transform: uppercase;
  font-family: var(--primary-font-regular);
  height: 15px;
  line-height: 7px;
}
.no-blance {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.no-blance span {
  font-size: 12px !important;
  color: #fa5a5a !important;
  opacity: 1 !important;
  font-family: var(--primary-font-regular);
}
.l-desktop {
  display: block;
}
.l-mobile {
  display: none;
}
.mobile-header-menu {
  display: none;
}
.mobile-menu {
  display: none;
}

@media (max-width: 1023px) {
  header ul.right-nav {
    display: none;
  }
  header ul.left-nav {
    display: none;
  }
  header {
    grid-template-columns: 1fr 90px 1fr;
  }
  .logo {
    height: 30px;
  }
  .l-mobile {
    display: block !important;
    position: absolute;
    z-index: 9;
    width: 66px;
    margin-left: -12px;
  }
  .l-desktop {
    display: none !important;
  }
  .mobile-header-menu {
    display: flex;
    align-items: center;
    width: auto;
    gap: 10px;
    justify-content: flex-end;
  }
  .hamburger {
    height: 13px;
  }
  .user-icon img {
    display: block;
  }
  .hamburger .ham-icon {
    width: 18px;
    height: 1px;
    background-color: #fff;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .hamburger .ham-icon:nth-child(1) {
    margin-top: 0;
  }
  .hamburger .ham-icon:nth-child(3) {
    margin-bottom: 0;
  }
  .hamburger .ham-icon:nth-child(2) {
    position: relative;
  }
  .hamburger .ham-icon:nth-child(2):after {
    width: 4px;
    height: 2px;
    background: var(--primary-color);
    content: "";
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 1;
  }

  .hamburger.is-active .ham-icon:nth-child(1) {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    -o-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
  }
  .hamburger.is-active .ham-icon:nth-child(3) {
    -webkit-transform: translateY(-7px) rotate(-45deg);
    -ms-transform: translateY(-7px) rotate(-45deg);
    -o-transform: translateY(-7px) rotate(-45deg);
    transform: translateY(-7px) rotate(-45deg);
  }
  .hamburger.is-active .ham-icon:nth-child(2) {
    opacity: 0;
  }
  .mobile-menu {
    width: 100%;
    height: calc(100% - 50px);
    position: fixed;
    background: var(--primary-color);
    z-index: 1;
    top: 50px;
    left: 0;
    display: flex;
  }
  .mobile-menu ul {
    margin: 0;
    padding: 2rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .mobile-menu ul li {
    position: relative;
    align-self: center;
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .mobile-menu ul li span {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875rem;
    opacity: 0.8;
    cursor: pointer;
  }
  .mobile-menu ul li:hover::before {
    content: "";
    width: 2px;
    height: 2px;
    background: #8adeb4;
    position: absolute;
    top: -11px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    border-radius: 50%;
  }
  .mobile-menu ul li:hover::after {
    content: "";
    width: 2px;
    height: 2px;
    background: #8adeb4;
    position: absolute;
    bottom: -11px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    border-radius: 50%;
  }
  .header-user-info {
    margin: 0;
    padding: 0 !important;
    list-style-type: none;
    display: flex;
    flex-direction: row !important;
    gap: 3rem;
    justify-content: center !important;
    padding-top: 2rem !important;
    width: 71px;
  }
  .header-user-wrap {
    border-top: 1px solid var(--secondary-color);
  }
  .header-user-wrap::before,
  .header-user-wrap::after {
    content: none !important;
  }
}
