.mines-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 10px;
}
.m-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 10px;
}

.mech-inspect-close-button {
  position: absolute;
  top: 48px;
  right: 48px;
  background: none;
  border: none;
  cursor: pointer;
}

.m-wrapper > .section-title {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--secondary-color);
  letter-spacing: 1px;
  cursor: default !important;
}
.mine-report-top-section {
  max-width: 957px;
  width: 100%;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.75rem;
}
.mine-share-wrap {
  flex: 1 1 368px;
  padding: 1.25rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.25) -8.05%,
    rgba(0, 0, 0, 0) 123.04%
  );
  border-radius: 4px;
  align-self: stretch;
}
.mine-media-wrap {
  flex: 1 1 511px;
  box-shadow: 63.6965px 141.548px 61.6744px rgba(0, 0, 0, 0.03),
    35.387px 79.8734px 52.5749px rgba(0, 0, 0, 0.09),
    16.1769px 35.387px 38.4201px rgba(0, 0, 0, 0.15),
    4.04422px 9.0995px 21.2322px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.17);
}
.mine-media-wrap img,
.mine-media-wrap video,
.mine-media-wrap canvas,
.mine-media-wrap object {
  width: 100%;
  display: block;
}

.mines-wrapper .pl-wrapper > .pl-row > .pl-number {
  flex: 0 0 52px;
  position: relative;
  padding-left: 8px;
}
.mines-wrapper .pl-wrapper > .pl-row > .pl-number:after {
  content: "";
  width: 1px;
  height: 100%;
  background: var(--grey-grey-600);
  position: absolute;
  top: 0;
  right: 0;
}
.mines-wrapper .pl-wrapper > .pl-row > .pl-status {
  flex: 0 0 79px;
}
.mines-wrapper .pl-wrapper > .pl-row > .pl-result {
  flex: 0 0 77px;
}
.mines-wrapper .pl-wrapper > .pl-row.pl-header > .pl-number:after {
  content: none !important;
}

.mine-map-wrap {
  width: 220px;
  height: auto;
  padding: 1rem 0.7rem;
  z-index: 100;
  position: absolute;
  bottom: 0;
  right: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-color);
  background: var(--grey-grey-900);
  margin: 8px;
}
.mine-map {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.mm-title-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.fx-circle-wrap {
  flex: 0 0 21px;
}
.fx-circle {
  width: 21px;
  height: 21px;
  background: var(--grey-grey-600);
  border-radius: 100%;
  text-align: center;
}
.fx-circle img {
  vertical-align: 1px;
}
.yellow {
  color: var(--status-orange, #ffa71a) !important;
}
.red {
  color: var(--status-red, #ff4d4d) !important;
}
#overlay {
  position: fixed;
  /* display: none; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 15.8%;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
.mm-title {
  font-size: 10px;
  font-family: var(--primary-font);
  color: var(--secondary-color);
  text-transform: uppercase;
}

.mm-travel-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.mm-travel-wrap > .mm-travel-icon {
  flex: 0 0 24px;
  padding-left: 2px;
  height: 20px;
}
.mm-travel-wrap > .mm-travel-title {
  flex: 0 0 47px;
  color: var(--secondary-color);
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.mm-travel-wrap > .mm-travel-energy {
  flex: 1 0 65px;
  color: var(--secondary-color);
  font-size: 10px;
  font-family: var(--primary-font);
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
}
.mm-divider {
  width: 100%;
  height: 1px;
  background: var(--grey-grey-600);
}

.mm-total-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.mm-total-wrap > .mm-total-icon {
  flex: 0 0 24px;
  padding-left: 2px;
  height: 16px;
}
.mm-total-wrap > .mm-total-title {
  flex: 0 0 47px;
  color: var(--find);
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.mm-total-wrap > .mm-total-energy {
  flex: 1 0 65px;
  color: var(--find);
  font-size: 10px;
  font-family: var(--primary-font);
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
}

.mm-footer {
  width: 100%;
  margin-top: 8px;
}
.mm-footer .btn {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid var(--find);
  border-radius: 4px;
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
  color: var(--status-green);
}
.mm-footer .btn:hover {
  color: var(--primary-color);
  background-color: var(--find);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
  border: 1px solid var(--secondary-color);
}
.mm-footer > .yellow {
  background: rgb(255 202 0 / 10%) !important;
  border: 1px solid var(--status-orange, #ffa71a) !important;
  border-radius: 4px;
  text-shadow: 0px 4px 4px rgb(225 199 11 / 50%) !important;
  color: var(--status-orange, #ffa71a) !important;
}
.mine-map-process {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mm-process-anim {
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: center;
}
.mm-process-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}
.mm-process-icon {
  flex: 0 0 18px;
  height: 19px;
}
.mm-process-title {
  color: var(--process);
  font-size: 12px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  flex: 1;
  letter-spacing: -0.48px;
  white-space: nowrap;
}

.popup-container {
  position: absolute;
  z-index: 99999;
  display: inline-flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;

  gap: 10px;
  border-radius: 8px;
  border: 1px solid #9f9f9f;
  background: var(--brand-black, #000);
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  user-select: none;
}

.popup-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.popup-frame > h1 {
  color: var(--brand-white, #fff);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%; /* 53.76px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.popup-frame > p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.green-text-popup {
  color: var(--status-green, #00ff85);

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}
.orange-text-popup {
  color: var(--status-orange);

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.animated-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(17, 255, 0, 0.85),
    0px 0px 1px 0px rgba(17, 255, 0, 0.5),
    0px 1px 1px 0px rgba(17, 255, 0, 0.15),
    0px 1px 41px 0px rgba(17, 255, 0, 0.02);

  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
  cursor: pointer;
}

.animated-button-container:hover {
  background: var(--status-green, #00ff85);
}
.animated-button-buttons {
  display: flex;
  width: 430px;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
}

.animated-button-container h5 {
  color: var(--status-green, #00ff85);
  text-align: center;

  /* Glow/Green */
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.animated-button-container:hover h5 {
  color: #000;
}

.mm-depth-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
}
.mm-depth-wrap > .mm-depth-row {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-color);
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.mm-depth-wrap > .mm-depth-row.mm-header {
  color: var(--grey-grey-600);
  text-transform: uppercase;
}
.mm-depth-wrap > .mm-depth-row > .mm-mine {
  flex: 0 0 29px;
}
.mm-depth-wrap > .mm-depth-row > .mm-depth {
  flex: 0 0 20px;
}
.mm-depth-wrap > .mm-depth-row > .mm-size {
  flex: 0 0 46px;
  text-align: center;
}
.mm-depth-wrap > .mm-depth-row > .mm-consumption {
  flex: 0 0 69px;
  text-align: right;
}
.mm-depth-wrap > .mm-depth-row > .mm-depth-divider {
  flex: 0 0 1px;
  background: var(--grey-grey-600);
  align-self: stretch;
}

.mc-modal {
  max-width: 594px;
  width: 90%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--primary-color);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  z-index: 999;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  transition: all 0.3s ease;
}
.mc-modal.animate {
  transform: translate(-50%, -50%);
  top: 50%;
}
.mc-modal-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 4rem;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}
.mc-modal-info {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  font-size: 1.25rem;
  color: #808080;
  font-family: var(--primary-font-light);
}
.mc-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mc-modal-footer .btn {
  background: transparent;
  border: 1px solid var(--grey-grey-600);
}
.mc-modal-footer .btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.report-modal-message {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 3rem 0;
  text-align: center;
  font-size: 1.25rem;
  color: #808080;
  font-family: var(--primary-font-regular);
}
.mc-modal-info .message {
  width: 100%;
  height: auto;
}
.mc-modal-info .message.success-text {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1.125rem;
  font-family: var(--primary-font-regular);
}
.message-white {
  color: var(--secondary-color);
}

.mc-modal-info span.find {
  color: var(--find) !important;
}
.mc-modal-info .message.best-discoveries {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 1.125rem;
}
.mc-modal-info .message.success-text .highest-yield {
  line-height: 160%;
  font-family: var(--primary-font-regular);
}

/*Share Css*/
.share-title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.share-title {
  font-size: 3rem;
  font-family: var(--secondary-font);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  line-height: 0.8;
}
.share-btn-wrap {
  width: 100%;
}
.share-message {
  font-size: 10px;
  color: #545454;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}
.share-wrap-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.share-your-find-wrap {
  width: 100%;
  height: auto;
  margin-top: 2.7rem;
  order: 1;
}
.syf-title {
  font-size: 0.75rem;
  color: #fff;
  width: 100%;
  text-align: center;
}

.social-share-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.875rem;
  gap: 0.875rem;
}
.ss-icon-wrap {
  flex: 0 0 66px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  align-items: center;
}
.ss-icon-text {
  font-size: 10px;
  color: #fff;
  width: 100%;
  text-align: center;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon {
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid #ffffff;
  border-radius: 44px;
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon > .tiktok {
  height: 24px;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon > .instagram {
  height: 24px;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon > .discord {
  height: 18px;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon > .twitter {
  height: 20px;
}
.social-share-wrap > .ss-icon-wrap > .ss-icon svg {
  fill: #fff;
}
.social-share-wrap > .ss-icon-wrap:hover > .ss-icon {
  border: 1px solid var(--find);
}
.social-share-wrap > .ss-icon-wrap:hover > .ss-icon svg {
  fill: var(--find);
}
.social-share-wrap > .ss-icon-wrap:hover > .ss-icon-text {
  color: var(--find);
}

.view-ar-warp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
}
.view-ar-warp > .btn {
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.75rem;
}
.view-ar-warp > .btn .ar-icon {
  width: 15px;
  height: 15px;
}
.view-ar-warp > .btn .ar-icon svg {
  fill: #000;
  transition: all 0.8s ease;
}
.view-ar-warp > .btn .ar-icon svg {
  fill: #000;
}
.view-ar-warp > .btn:hover .ar-icon svg {
  fill: #fff;
}
.share-link-wrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  order: 3;
}

.share-link-title {
  width: 100%;
  font-size: 0.625rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.share-link {
  width: 100%;
  height: auto;
  position: relative;
}
.share-link input {
  width: 100%;
  border: 1px solid #fff;
  color: #b5b5b5;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.25) -8.05%,
    rgba(0, 0, 0, 0) 123.04%
  ) !important;
  font-size: 10px;
  color: #b5b5b5;
  height: 34px;
  padding: 8px 34px 8px 11px;
  border-radius: 4px;
}
.share-link input:focus {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.25) -8.05%,
    rgba(0, 0, 0, 0) 123.04%
  ) !important;
}
.share-link input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #101010 inset !important;
  -webkit-text-fill-color: #b5b5b5 !important;
}
.copy-wrap {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 6px;
  right: 14px;
  z-index: 2;
  cursor: pointer;
}
.copy-wrap .copy-icon {
  width: 11px;
  height: 11px;
}

.share-title-wrap .mine-media-wrap {
  display: none;
}
.results .pl-row.find {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid #02b860;
  border-radius: 4px;
}

/*Congratulations Modal */
.congratulation-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2000;
  background-color: #000;
  top: 0;
  left: 0;
}
.congratulation-wrap > .blur {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(100px);
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.congratulation-content-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - 190px);
  margin-top: 6rem;
  position: relative;
}
.cc-wrap-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cc-gallery-element-wrap {
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.cc-gallery-element {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cc-gallery-element-img-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
}
.cc-gallery-element-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  top: 92px;
  left: 10px;
  height: calc(100% - 251px);
}
.cc-gallery-element-img img {
  display: block;
  width: auto;
  height: 100%;
}
.congratulation-content-wrap h2 {
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: 8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 85%;
  letter-spacing: -1.28px;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 0;
  top: 0;
}
.congratulation-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 6rem;
  left: 0;
  flex-direction: column;
  gap: 1.75rem;
  align-items: center;
}

.message-wrap {
  display: flex;
  width: 100%;
  justify-content: center;
}
.message {
  width: 513px;
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.09px;
}

.message-text {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.09px;
}

.message-grey {
  margin-top: 15px;
  color: #808080;
}

.message-green {
  color: var(--status-green, #00ff85);
}
#message-green {
  color: var(--status-green, #00ff85);
}

.message-black {
  align-self: stretch;
  color: var(--brand-black, #000);
  text-align: center;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-wrap {
  border-radius: var(--edge-4, 4px);
  background: rgba(0, 255, 133, 0.7);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.24) inset;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.congratulation-footer > .btn-wrap > .btn {
  display: flex;
  width: 279px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
}

/*Discoveries Modal */

.discoveries-container {
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Ensure the background covers the entire viewport */
  position: relative;
  z-index: 1000000;
}
.discoveries-wrap2 {
  /*width: 100%;
    height: 100%;*/
  /*position: fixed;*/
  z-index: 1002;
  /*background-color: #000;*/
  top: 0;
  /*left: 0;*/
  display: flex;

  justify-content: space-between;
  gap: 0.625rem;
  /*padding: 80px 0;*/
}
.discoveries-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1002;
  background-color: #000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.625rem;
  padding-top: 6rem;
}

.discoveries-wrap h2 {
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 85%;
  letter-spacing: -1.28px;
  text-transform: uppercase;
}
.discoveries-wrap > .slider-container {
  width: 50%;
  height: auto;
}
.discoveries-wrap > .slider-container > .swiper {
  width: 100%;
  height: 100%;
}
.discoveries-wrap
  > .slider-container
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.swiper-slide-prev {
  opacity: 1;
}
.discoveries-wrap
  > .slider-container
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.swiper-slide-next {
  opacity: 0.6;
}
.discoveries-wrap
  > .slider-container
  > .swiper
  > .swiper-wrapper
  > .swiper-slide
  > .slide-wrap {
  width: 100%;
  height: 100%;
  color: #fff;
}
.discoveries-wrap
  > .slider-container
  > .swiper
  > .swiper-wrapper
  > .swiper-slide.swiper-slide-active {
  width: 90%;
  height: 100%;
}
.discoveries-wrap
  > .slider-container
  > .swiper
  > .swiper-wrapper
  > .swiper-slide
  > .slide-wrap
  .gallery-element {
  display: flex;
  height: 100%;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.swiper-slide.swiper-item.active {
}

.discoveries-footer {
  display: flex;
  width: 511px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.discoveries-footer .white-button {
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: var(--brand-white, #fff);
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}

.discoveries-footer .white-button h5 {
  color: var(--brand-black, #000);
  text-align: center;

  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.discoveries-footer .dark-button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  cursor: pointer;
  backdrop-filter: blur(9px);
}

.discoveries-footer .dark-button h5 {
  color: var(--grey-grey-500, #808080);
  text-align: center;

  font-family: var(--primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.footer-text-wrap {
  display: flex;
  width: 511px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.footer-text-wrap p {
  color: var(--brand-white, #fff);
  text-align: center;
  font-family: var(--primary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.09px;
}

.thumbnail-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8.375px;
  background: var(--brand-black, #000);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.17),
    3.5496761798858643px 7.986772060394287px 18.635799407958984px 0px
      rgba(0, 0, 0, 0.17),
    14.198704719543457px 31.059667587280273px 33.721923828125px 0px
      rgba(0, 0, 0, 0.15),
    31.059667587280273px 70.10610961914062px 46.145790100097656px 0px
      rgba(0, 0, 0, 0.09),
    55.90740203857422px 124.2386703491211px 54.13256072998047px 0px
      rgba(0, 0, 0, 0.03),
    86.9670639038086px 194.3447723388672px 59.45707702636719px 0px
      rgba(0, 0, 0, 0);
  width: 448.512px;
  height: 634.505px;
  flex-shrink: 0;
}

.thumbnail-wrap-2 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8.375px;
  background: var(--brand-black, #000);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.17),
    3.5496761798858643px 7.986772060394287px 18.635799407958984px 0px
      rgba(0, 0, 0, 0.17),
    14.198704719543457px 31.059667587280273px 33.721923828125px 0px
      rgba(0, 0, 0, 0.15),
    31.059667587280273px 70.10610961914062px 46.145790100097656px 0px
      rgba(0, 0, 0, 0.09),
    55.90740203857422px 124.2386703491211px 54.13256072998047px 0px
      rgba(0, 0, 0, 0.03),
    86.9670639038086px 194.3447723388672px 59.45707702636719px 0px
      rgba(0, 0, 0, 0);
  width: 311.38px;
  height: 440.505px;
  flex-shrink: 0;
}

.thumb-header {
  width: 163px;
  height: 54px;
}

.thumb-header-2 {
  padding: 15.499px;
}

.thumb-header img {
  opacity: 0.6;
  display: inline-flex;
  padding: 15.499px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4.914px;
}
.thumb-header-2 img {
  width: 91.626px;
  height: 16.043px;
  opacity: 0.6;
  display: inline-flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 4.914px;
}

.thumb-footer-2 {
  width: 311.718px;
  height: 32.63px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  z-index: 2;
  border-radius: 0 0 8.65px 8.65px;
}

.thumb-footer {
  position: relative;
  display: flex;
  width: 449px;
  height: 47px;
  flex-shrink: 0;
  align-items: center;
  z-index: 2;
  border-radius: 0 0 8.65px 8.65px;
}

.thumb-img-no-hover {
  width: 287.719px;
  height: 346.626px;
  flex-shrink: 0;
}

.thumb-img {
  width: 414.431px;
  height: 499.282px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumb-img:hover {
  mix-blend-mode: luminosity;
  opacity: 0.8;
  user-select: none;
  cursor: pointer;
}

.thumb-img:hover .hover-text {
  display: block;
}

.hover-text {
  position: absolute;
  color: var(--brand-white, #fff);
  text-align: right;
  font-family: var(--primary-font-light);
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -1.28px;
  text-transform: uppercase;
  z-index: 99999;
  display: none;
}

.top-text {
  top: 190px;
  left: 30px;
}
.mid-text {
  top: 315px;
  left: 180px;
}
.bottom-text {
  top: 470px;
  left: 290px;
}
.badge {
  mix-blend-mode: overlay;
  position: absolute;
  width: 30.03px;
  height: 59.59px;
  flex-shrink: 0;
  top: -30px;
  right: 20px;
  z-index: 999;
}
.gold-text {
  background: linear-gradient(171deg, #383838 0%, #262626 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
  font-family: var(--secondary-font-regular);
  font-size: 12.562px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.377px;
  text-transform: uppercase;
  margin-left: 16.5px;
}
.gold {
  background: #807956;
}

.silver-text {
  font-family: var(--secondary-font-regular);
  font-size: 12.562px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.377px;
  text-transform: uppercase;
  margin-left: 16.5px;
  background: linear-gradient(171deg, #383838 0%, #262626 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

.silver {
  background: #747474;
}

.bronze-text {
  font-family: var(--secondary-font-regular);
  font-size: 12.562px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.377px;
  text-transform: uppercase;
  margin-left: 16.5px;
  background: linear-gradient(171deg, #383838 0%, #262626 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: difference;
}

.bronze {
  background: #7e643b;
}

.social-overlay-container {
  position: fixed;
  height: 100%;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
  margin-top: -30px;
  justify-content: center;
}

.social-overlay-wrap {
  align-self: center;
  position: fixed;
  display: inline-flex;
  padding: 32px 64px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 20px;
  border: 3px solid #9f9f9f;
  background: rgba(30, 30, 30, 0.4);

  z-index: 9999;
  /* Blur/60 */
  backdrop-filter: blur(30px);
}
.thumb-wrap {
  width: 311.38px;
  height: 440.505px;
}
.overlay-close-button {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -10px;
  cursor: pointer;
  right: -7px;
}

.share-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.social-icon {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 0px 0.019px 0.006px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.share-text-wrap h1 {
  color: var(--brand-white, #fff);
  text-align: center;

  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.share-icons-wrap {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.share-modal-wrap {
  height: fit-content;
  border-radius: 8px;
  border: 1px solid #9f9f9f;
  background: var(--brand-black, #000);
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  display: inline-flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.share-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 404px;
}

.share-modal h1 {
  color: var(--brand-white, #fff);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Headings/Heading 1 */
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%; /* 53.76px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.share-modal p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Paragraph/Medium Text */
  font-family: var(--primary-font-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.07px;
}

.share-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.white-share-button {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #545454;
  background: var(--brand-white, #fff);
}

.white-share-button p {
  color: var(--brand-black, #000);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.dark-share-button {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #545454;
}

.dark-share-button p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.core-complete p {
  font-size: 107px;
  color: #fff;
  word-spacing: -50px;
  font-weight: bolder;
  letter-spacing: -7px;
}

/* Mine Cursor */

.mining-cursor {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-shrink: 0;
  margin: auto;
  pointer-events: none;
  z-index: 9999999;
}

.mine-icon {
  display: flex;
  width: 34px;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px;
  pointer-events: none;
  cursor: nwse-resize;
}

.mining-cursor .depth-1 {
  border: 2.5px dashed var(--accents-32, #64bbeb);
}
.mining-cursor .depth-2 {
  border: 2.5px dashed var(--accents-64, #f2ef4c);
}
.mining-cursor .depth-3 {
  border: 2.5px dashed var(--accents-192, #aa5afa);
}

.mine-icon img {
  width: 24px;
  height: 24.506px;
}

.drag-icon {
  display: inline-flex;
  padding: 5px 7px;
  flex-direction: column;
  align-items: center;
  gap: var(--edge-4, 4px);
  border-radius: var(--edge-2, 2px);

  border: 0.8px solid var(--brand-black, #000);
  background: var(--grey-grey-900, #1c1c1c);
  cursor: nwse-resize;
  pointer-events: all;
}

.drag-icon img {
  width: 26.379px;
  height: 26.379px;
}

.drag-icon p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Mono Labels/Small Labels */
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

/* error modal */
.error-modal {
  position: absolute;
  top: 120px;
  left: 74px;
  display: inline-flex;
  padding: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  z-index: 99999;
  border-radius: 8px;
  border: 1px solid #9f9f9f;
  background: var(--brand-black, #000);
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
}

.error-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 487px;
  height: 409px;
  gap: 24px;
}
.error-modal-content h2 {
  color: var(--brand-white, #fff);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Headings/Heading 2 */
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 54.4px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.em-summary {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.error-modal-content .white-button {
  border-radius: 4px;
  border: 1px solid #545454;
  background: var(--brand-white, #fff);
  display: flex;
  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.error-modal-content .white-button p {
  color: var(--brand-black, #000);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.em-summary p {
  color: var(--brand-white, #fff);
  text-align: center;

  /* Paragraph/Large Text */
  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.16px;
}

.em-summary img {
  width: 20px;
  position: relative;
  top: 6px;
}

.em-media {
  width: 146px;
  height: 145px;
}
.health-monitor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin: 10px 0;
    margin-bottom: 20px;
}

.health-left {
    display: flex;
    align-items: center;
    gap: 4px;
}

.health-left-title {
    display: flex;
    align-items: center;
    gap: 2px;
}

    .health-left-title p {
        color: var(--grey-grey-400, #dadada);
        /* Headings/Heading 5 */
        font-family: var(--secondary-font-regular);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        text-transform: uppercase;
    }

.health-percent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 2px;
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    background: var(--status-find-background, rgba(0, 255, 133, 0.1));
}

    .health-percent p {
        color: var(--status-green, #00ff85);
        align-self: center;
        /* Mono Labels/Small Labels */
        font-family: var(--secondary-font-regular);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 104%; /* 10.4px */
        text-transform: uppercase;
    }

.repair-button {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    border: 1px solid var(--brand-white, #fff);
    background: rgba(255, 255, 255, 0.1);
}

    .repair-button p {
        color: var(--brand-white, #fff);
        /* Mono Labels/Small Labels */
        font-family: var(--secondary-font-regular);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 104%; /* 10.4px */
        text-transform: uppercase;
    }
@media (max-width: 1500px) {
  /* .congratulation-content-wrap{ width: 30vw; height: 83vh; } */
  .congratulation-content-wrap h2 {
    font-size: 6rem;
  }
  .congratulation-footer {
    bottom: 6rem;
  }

  .cc-gallery-element-img {
    height: 70%;
    top: 10%;
    left: 0;
  }
}

@media (max-width: 1023px) {
  .mine-report-top-section {
    flex-direction: column;
    gap: 2rem;
  }
  .share-title-wrap .mine-media-wrap {
    display: block;
  }
  .mine-media-wrap {
    display: none;
  }
  .share-your-find-wrap {
    order: 2;
    margin-top: 0;
  }
  .share-wrap-main {
    margin-top: 2rem;
  }
  .view-ar-warp {
    order: 1;
  }
  .view-ar-warp .btn {
    flex-direction: column;
    border-radius: 8px;
  }
  .mine-media-wrap {
    flex: 1 1 auto;
  }
  .ss-icon-wrap {
    width: 44px;
  }
  .social-share-wrap > .ss-icon-wrap > .ss-icon {
    background: transparent;
  }
  .social-share-wrap > .ss-icon-wrap > .ss-icon {
    width: 44px;
    height: 44px;
    border: 0px;
  }
  .social-share-wrap > .ss-icon-wrap > .ss-icon svg {
    fill: #fff;
  }
  .social-share-wrap > .ss-icon-wrap:hover > .ss-icon {
    border: 1px solid #fff;
  }
  .social-share-wrap > .ss-icon-wrap:hover > .ss-icon svg {
    fill: #fff;
  }
  .social-share-wrap > .ss-icon-wrap:hover > .ss-icon-text {
    color: #fff;
  }
  .mine-map-wrap {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 0px;
    border-radius: 22px;
    transition: all 0.3s ease;
  }
  .mine-map-wrap.hide {
    display: none !important;
  }
}

@media (max-width: 920px) {
  .thumb-footer {
    width: 448.875px;
    height: 36.415px;
    flex-shrink: 0;
  }
  .discoveries-wrap > .slider-container {
    width: 460.497px;
    height: 640.6px;
  }
}

@media (max-width: 767px) {
  .mc-modal {
    padding: 2rem;
    gap: 12px;
  }

  .mc-modal-info .message.success-text {
    font-size: 1rem;
  }
  .mc-modal-info .message.success-text br {
    display: none;
  }
  .congratulation-footer > .message {
    font-size: 1rem;
  }
  .congratulation-content-wrap h2 {
    font-size: 4rem;
  }
  .cc-gallery-element-img {
    height: 50%;
    top: 20%;
    left: 0;
  }
}

@media (max-width: 700px) {
  .social-overlay-container {
    margin-top: 0;
  }
  .thumb-img-no-hover {
    width: 235.719px;
    height: 241.626px;
    flex-shrink: 0;
  }

  .social-overlay-wrap {
    padding-left: 0;
    padding-right: 0;
    margin: 20px;
  }

  .share-icons-wrap {
    width: 80%;
    flex-wrap: wrap;
  }

  .discoveries-wrap {
    padding: 0;
    padding-top: 40px;
    height: 98%;
  }
  .discoveries-wrap > .slider-container {
    width: 347.497px;
    height: 466.6px;
  }
  .thumb-img {
    width: 330.061px;
    height: 400.218px;
    flex-shrink: 0;
    padding: 0;
    user-select: none;
    cursor: pointer;
  }
  .thumbnail-wrap {
    width: 347.497px;
    height: 450.6px;
  }
  .discoveries-wrap h2 {
    padding-top: 1.875rem;
  }
  .thumb-header {
    display: inline-flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 3.807px;
    padding: 0;
  }
  .thumb-header img {
    width: 135.254px;
    padding: 0;
  }

  .thumb-footer {
    width: 347.875px;
    height: 36.415px;
    flex-shrink: 0;
  }

  .badge {
    width: 23.267px;
    height: 46.169px;
    flex-shrink: 0;
  }

  .discoveries-footer {
    padding: 0 16px;
    max-width: 400px;
  }

  .footer-text-wrap {
    width: 250px;
    padding: 0;
  }

  .thumb-img .hover-text {
    display: block;
  }

  .top-text {
    top: 30px;
  }
  .mid-text {
    top: 175px;
    left: 120px;
  }
  .bottom-text {
    top: 300px;
    left: 200px;
  }
}

@media (max-height: 1032px) {
  .thumb-img {
    width: 280px;
    height: auto;
  }
  .thumbnail-wrap {
    max-height: 480px;
    gap: 2px;
  }
}

@media (max-height: 880px) {
  .thumb-img {
    max-width: 230px;
    height: auto;
  }
  .thumbnail-wrap {
    max-height: 400px;
  }
}

@media (max-height: 800px) {
  .discovery-picture {
    height: 280px !important;
  }
  .discovery-picture img {
    height: 280px !important;
  }
  .thumbnail-wrap-2 {
    height: 300px;
  }
  .share-icons-wrap {
    width: 90%;
    margin-top: 20px;
  }

  .thumb-img-no-hover img {
    height: 240px;
    width: auto;
  }
  .mech-inspect-close-button {
    top: 23px;
    right: 21px;
  }
  .asset-title-wrap h2 {
    font-size: 50px;
  }
  .discoveries-wrap > .slider-container {
    height: 348.6px;
  }
  .discoveries-wrap > .slider-container > .swiper {
    height: initial;
  }
  .footer-text-wrap {
    width: 320px;
  }
  .discoveries-footer {
    gap: 10px;
  }
  .footer-text-wrap p {
    font-size: 15px;
  }
  .discoveries-wrap {
    padding-top: 80px;
    margin-top: -13px;
    height: 100%;
  }
  .discoveries-wrap h2 {
    padding-top: 0;
    font-size: 3rem;
  }
  .thumbnail-wrap {
    height: 372px;
  }
  .thumbnail-wrap-2,
  .thumb-footer-2 {
    width: 230px;
  }
  .thumb-img {
    display: flex;
    justify-content: center;
    width: 200px;
    height: auto;
  }

  .social-overlay-wrap {
    margin-top: 70px;
  }
  .share-text-wrap h1 {
    font-size: 40px;
  }
}
