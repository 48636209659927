.warning-popup-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.5);
  /* backdrop-filter: blur(30px); */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-popup-container > .wp-wrap {
  position: absolute;
  display: flex;
  width: 449px;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: rgba(28, 28, 28);
  /* Notification/Blur+Shadow */
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.2),
    0px 32px 32px 0px rgba(0, 0, 0, 0.18), 0px 71px 43px 0px rgba(0, 0, 0, 0.1),
    0px 126px 50px 0px rgba(0, 0, 0, 0.03), 0px 197px 55px 0px rgba(0, 0, 0, 0),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}

.warning-popup-container > .wp-wrap > .wp-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.warning-popup-container > .wp-wrap > .wp-text-wrap h3 {
  align-self: stretch;
  color: var(--brand-white, #fff);
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .wp-message {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .wp-message {
  color: var(--status-green, #00ff85);
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-transform: uppercase;
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .wp-message.high {
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
  color: var(--status-red, #ff4d4d);
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .wp-message.medium {
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
  color: var(--status-orange, #ffa71a);
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .wp-message.low {
  background: rgba(242, 239, 76, 0.2);
  color: var(--accents-64, #f2ef4c);
}

.warning-popup-container > .wp-wrap > .wp-text-wrap .risk-stack {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.assessment {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--status-green, #00ff85);
  align-self: stretch;
}

.assessment.high {
  color: var(--status-red, #ff4d4d);
}

.assessment.medium {
  color: var(--status-orange, #ffa71a);
}

.assessment.low {
  color: var(--accents-64, #f2ef4c);
}

.assessment-class-container {
  display: flex;
  padding-right: 0px;
  align-items: center;
}

.assessment-class {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  color: var(--status-green, #00ff85);
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.assessment-class.high {
  color: var(--status-red, #ff4d4d);
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
}

.assessment-class.medium {
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
  color: var(--status-orange, #ffa71a);
}

.assessment-class.low {
  background: rgba(242, 239, 76, 0.2);
  color: var(--accents-64, #f2ef4c);
}

.assessment p {
  margin-left: auto;
  text-align: right;
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.assessment.high p {
  color: var(--status-red, #ff4d4d);
  text-shadow: 0px 0px 5px rgba(255, 77, 77, 0.85),
    0px 0px 1px rgba(255, 77, 77, 0.5), 0px 1px 1px rgba(255, 77, 77, 0.15),
    0px 1px 41px rgba(255, 77, 77, 0.02);
}

.no-threat {
  color: var(--grey-grey-500, #808080);
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-transform: uppercase;
}

.assessment-buttons-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.assessment-buttons {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  cursor: pointer;
}

.button-grey {
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: rgba(68, 68, 68, 0.7);

  /* Blur/18 */
  backdrop-filter: blur(9px);
  color: var(--brand-white, #fff);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.button-white {
  border-radius: 4px;
  background: var(--brand-white, #fff);
  box-shadow: 4px 4px 4px 0px rgba(118, 118, 118, 0.35);
  backdrop-filter: blur(9px);
  color: var(--brand-black, #000);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.button-white:hover {
  color: var(--brand-white, #fff);
  text-align: center;
  /* Shadow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  border: 1px solid var(--brand-white, #fff);
  background: var(--grey-grey-900, #1c1c1c);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}

.button-grey:hover {
  border-radius: 4px;
  background: var(--brand-white, #fff);
  box-shadow: 4px 4px 4px 0px rgba(118, 118, 118, 0.35);
  backdrop-filter: blur(9px);
  color: var(--brand-black, #000);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
