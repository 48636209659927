::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.map-container {
  height: 768px;
  position: relative;
  display: flex;
}

.map-container-report-mine {
  margin: 30px 40px;
}
.reportMapContainer {
  left: 2% !important;
  margin-top: 5% !important;
}
.map-container .ruler {
  display: flex;
}
.map-container .ruler.top {
  flex: 0 0 768px;
  height: 32px;
  position: absolute;
  top: -32px;
  left: 0;
}

.mine-section-title {
  width: 100%;
}

.mining-report {
  top: 30px;
  left: 37px;
}
.map-container .ruler.bottom {
  flex: 0 0 768px;
  height: 32px;
  position: absolute;
  bottom: -32px;
  left: 0;
}
.map-container .ruler.left {
  width: 32px;
  flex: 0 0 768px;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  left: -32px;
}
.map-container .ruler.right {
  width: 32px;
  flex: 0 0 768px;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  right: -32px;
}
.map-container .ruler > .ruler-item {
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 6px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
.map-container .ruler.left > .ruler-item::before {
  content: "";
  width: 14px;
  height: 1px;
  background: #fff;
  right: 5px;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
.map-container .ruler.left > .ruler-item:first-child:after {
  content: "";
  width: 14px;
  height: 1px;
  background: #fff;
  right: 5px;
  top: 0;
  position: absolute;
  z-index: 1;
}
/* .map-container .ruler.left > .ruler-item:last-child::after{ content:''; width:1px;height: 20px; background: #fff; left:0; top: 0; position: absolute;z-index: 1;} */

.map-container .ruler.top > .ruler-item::before {
  content: "";
  width: 1px;
  height: 14px;
  background: #fff;
  left: 0;
  bottom: 5px;
  position: absolute;
  z-index: 1;
}
.map-container .ruler.top > .ruler-item:last-child::after {
  content: "";
  width: 1px;
  height: 14px;
  background: #fff;
  right: 0;
  bottom: 5px;
  position: absolute;
  z-index: 1;
}

.map-container .ruler.right > .ruler-item::before {
  content: "";
  width: 14px;
  height: 1px;
  background: #fff;
  left: 5px;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
.map-container .ruler.right > .ruler-item:first-child:after {
  content: "";
  width: 14px;
  height: 1px;
  background: #fff;
  left: 5px;
  top: 0;
  position: absolute;
  z-index: 1;
}
.map-container .ruler.right > .ruler-item:last-child::after {
  content: "";
  width: 14px;
  height: 1px;
  background: #fff;
  left: 5px;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.map-container .ruler.bottom > .ruler-item::before {
  content: "";
  width: 1px;
  height: 14px;
  background: #fff;
  left: 0;
  top: 6px;
  position: absolute;
  z-index: 1;
}
.map-container .ruler.bottom > .ruler-item:last-child::after {
  content: "";
  width: 1px;
  height: 14px;
  background: #fff;
  right: 0;
  top: 5px;
  position: absolute;
  z-index: 1;
}

.map-container .ruler.top > .ruler-item {
  align-items: flex-end;
  padding-bottom: 5px;
}
.map-container .ruler.left > .ruler-item {
  justify-content: flex-end;
  padding-right: 5px;
}

.map-container .ruler.right > .ruler-item {
  justify-content: flex-start;
  padding-left: 5px;
}

.map-container .ruler.bottom > .ruler-item {
  align-items: flex-start;
  padding-top: 5px;
}

.map-container .ruler.right img,
.map-container .ruler.left img {
  height: 768px;
}

/* .map-container:after{ content:''; position: absolute; top:0; right: 0; height: 100%; width:1px; background-color:rgba(255,255,255,0.5);z-index: 1;}
.map-container::before{ content:''; position: absolute; top:0; left: 0; height:1px; width:100%; background-color:rgba(255,255,255,0.5);z-index: 1;} */
/* .map-container img{ border: 1px solid rgba(255,255,255,0.5); display: block; border-bottom: 0; width: 100%;} */
.grid-outer {
  width: 772px;
  height: 772px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.map-image {
  position: absolute;
  width: fit-content;
  width: 100%;
  height: inherit;
  z-index: 0;
}

.grid-wrap {
  width: 768px;
  height: 768px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 2;
}
.grid-wrap > .grid-item {
  width: 32px;
  height: 32px;
  position: relative;
}
/* .grid-wrap > .grid-item::before{ content:''; width:100%;height:1px; background: rgba(255,255,255,0.5);; position: absolute; bottom:0; left: 0;}
.grid-wrap > .grid-item::after{ content:''; width:1px;height:100%; background: rgba(255,255,255,0.5);; position: absolute; bottom:0; left: 0;} */

.core-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.core {
  position: relative;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background: var(--grey-grey-900, #1c1c1c);
  z-index: 999;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.5);
  outline: 3px solid var(--accents-mint, #bedfcf);
}

.core-child {
  position: relative;
  height: 32px;
  width: 32px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  bottom: 0;
  transition: bottom 0.3s ease-in-out;
  background: var(--grey-grey-900, #1c1c1c);
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.5);
}

.core:hover .mining-component {
  display: none;
}
.border-overlay {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border-top: 3px solid var(--accents-mint, #bedfcf);
  border-right: 3px solid var(--accents-mint, #bedfcf);
  border-bottom: 3px solid var(--accents-mint, #bedfcf);
  border-left: 3px solid var(--accents-mint, #bedfcf);
  transform: rotate(45deg);
  animation: borderExpand 1s forwards;
}

.border-overlay-clipped {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border-top: 3px solid #bedfcf;
  border-right: 3px solid #bedfcf;
  border-bottom: 3px solid #bedfcf;
  border-left: 3px solid #bedfcf;
  z-index: 999;
  transform: rotate(-180deg);
  animation: borderExpand2 2s forwards;
  clip-path: circle(50% at 0 100%);
}

@keyframes borderExpand {
  0% {
    border-top: 3px solid var(--accents-mint, #bedfcf);
    border-right: 3px solid var(--accents-mint, #bedfcf);
    border-bottom: 3px solid var(--accents-mint, #bedfcf);
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  10% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid var(--accents-mint, #bedfcf);
    border-bottom: 3px solid var(--accents-mint, #bedfcf);
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  20% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid var(--accents-mint, #bedfcf);
    border-bottom: 3px solid var(--accents-mint, #bedfcf);
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  60% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid var(--accents-mint, #bedfcf);
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  75% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid var(--accents-mint, #bedfcf);
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  95% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
  100% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid var(--accents-mint, #bedfcf);
  }
}

@keyframes borderExpand2 {
  0% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(-180deg);
  }
  16% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(-135deg);
  }
  33% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(-90deg);
  }
  49% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(-45deg);
  }
  66% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(0deg);
  }
  82% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(45deg);
  }
  100% {
    border-top: 3px solid #ffa71a;
    border-right: 3px solid #ffa71a;
    border-bottom: 3px solid #ffa71a;
    border-left: 3px solid #ffa71a;
    transform: rotate(90deg);
  }
}
/* .find {
  outline: 2px solid var(--status-green, #00ff85);
}

.not-find {
  outline: 2px solid var(--status-red, #ff4d4d);
} */
.core-child > .ready {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 3px solid var(--accents-mint, #bedfcf);
}
.core-child > .find {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid var(--status-green, #00ff85);
}

.core-child > .not-find {
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid var(--status-red, #ff4d4d);
}

.depth-label {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  transition: bottom 0.3s ease-in-out;
  opacity: 0;
}

.core-bottom-label {
  width: 80px;
  position: absolute;
  left: -10px;
  bottom: -45px;
  opacity: 0;
  transition: left 0.3s ease-in, bottom 0.3s ease-in, opacity 0.3s ease-in;
}

.core-close-button {
  display: none;
}

.core-container:hover .mining-component {
  display: none;
}

.core:hover .core-close-button {
  display: block;
}

.core:hover .core-bottom-label {
  left: -30px;
  bottom: -42px;
  opacity: 1;
}

.close-button:hover .mining-component {
  display: none;
}

.core:hover .depth-label {
  bottom: 5px;
  opacity: 1;
}

.core:hover .center-image {
  opacity: 0;
}

.core:hover .core-child {
  bottom: 20px;
}

.coreHover .core-bottom-label {
  left: -30px;
  bottom: -42px;
  opacity: 1;
}

.coreHover .depth-label {
  bottom: 5px;
  opacity: 1;
}

.coreHover .center-image {
  opacity: 0;
}

.coreHover .core-child {
  bottom: 20px;
}

.depth-label-text {
  font-family: var(--primary-font);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  letter-spacing: -0.1px;
}

.icon-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.depth-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.center-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-out;
}

.depth-1 {
  color: var(--accents-32, #64bbeb);
}
.depth-2 {
  color: var(--accents-64, #f2ef4c);
}

.depth-3 {
  color: var(--accents-192, #aa5afa);
}

.inspecting-expanded-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.inspecting-expanded-wrap {
  display: flex;
  align-items: flex-start;
  gap: var(--edge-4, 4px);
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.restricted-grid {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(255, 0, 0, 0.4);
}

.mining-component {
  position: absolute;
  top: 0;
  left: -21px;
  display: flex;
  width: 74px;
  height: 67px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  z-index: 999;
}

.mining-icon-wrap {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 100px;
  box-shadow: 0px 2.29167px 1.83333px 0px rgba(0, 0, 0, 0.5);
}

.mining-icon {
  border-radius: 100px;
  border: 0.8px solid var(--brand-black, #000);
  background: var(--brand-white, #fff);
  display: inline-flex;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  left: 1px;
}
.mining-icon.expanded {
  background: var(--brand-black, #000);
}

.mining-content-wrap {
  display: flex;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 2.161px;
  border-radius: 2px;
  border: 0.8px solid var(--brand-black, #000);
  background: var(--brand-white, #fff);
  box-shadow: 0px 2.29167px 1.83333px 0px rgba(0, 0, 0, 0.5);
}

.mining-content-wrap p {
  color: var(--brand-black, #000);
  text-align: center;
  font-family: var(--primary-font);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
  border-radius: 2px;
  background: var(--brand-white, #fff);
}

.mining-content-wrap-expanded {
  display: flex;
  padding: 5px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 0.8px solid var(--brand-black, #000);
  background: var(--grey-grey-900, #1c1c1c);
  box-shadow: 0px 2.29167px 1.83333px 0px rgba(0, 0, 0, 0.5);
}

.mining-content-wrap-expanded p {
  color: var(--brand-white, #fff);
  text-align: center;

  font-family: var(--primary-font);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%;
  text-transform: uppercase;
  white-space: nowrap;
}

.mining-content-wrap-expanded img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.mining-icon,
.mining-content-wrap,
.mining-content-wrap-expanded {
  transition: all 0.3s ease-in-out;
}
.grid-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  z-index: 3333;
}

.gs-content-wrap {
  flex: 1 0 0;
  position: absolute;
  align-self: stretch;
  display: inline-flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.grid-boxes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@keyframes yellowToNone {
  0% {
    background-color: rgba(0, 255, 133, 0);
  }

  90% {
    background-color: rgba(0, 255, 133, 0);
  }

  99% {
    background-color: rgba(0, 255, 133, 0);
  }

  100% {
    background-color: rgba(0, 255, 133, 0);
  }
}
@keyframes yellowToGreen {
  0% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  90% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  99% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  100% {
    background-color: rgba(0, 255, 133, 0.4);
  }
}

@keyframes yellowToRed {
  0% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  90% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  99% {
    background-color: rgba(251, 190, 94, 0.4);
  }
  100% {
    background-color: rgba(250, 90, 90, 0.4);
  }
}
.restricted-box {
  width: 32px;
  height: 32px;
  pointer-events: none;
  /*animation: yellowToNone 1s 1;*/
  background-color: rgba(0, 0, 0, 0) !important;
}
.found-box {
  width: 32px;
  height: 32px;
  pointer-events: none;
  animation: yellowToGreen 1s 1;
  background-color: rgba(0, 255, 133, 0.4);
}

.not-found-box {
  width: 32px;
  height: 32px;
  pointer-events: none;
  animation: yellowToRed 1s 1;
  background-color: rgba(250, 90, 90, 0.4);
}
.gs-text-content-wrap {
  display: flex;
  align-items: center;
  gap: 2px;
}

.gs-text-content-wrap > img {
  width: 12px;
  height: 12px;
}

.gs-text-content-wrap > p {
  color: var(--brand-white, #fff);
  text-align: center;

  font-family: var(--primary-font);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.gs-content-wrap > img {
  width: 16px;
  height: 16px;
}

.disable-selection {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge */
}

.close-button {
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;
  padding: 10px;
  z-index: 9999;
}

.pathway {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: rgba(207, 207, 207, 0.5);
  z-index: 0;
}

.pathway-complete {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 31px;
  height: 31px;
}

.mixblend-container {
  fill: rgba(68, 78, 70, 0.6);
  background-image: url("../../images/icons/Rectangle-overlay.png");
  position: absolute;
  width: 31px;
  height: 31px;
  mix-blend-mode: color-burn; /* Apply mix-blend-mode to this container */
}

.arrow {
  position: relative;
  display: flex;
  width: 8px;
  height: 9.714px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
}
.pathway-complete-container {
  animation: fadeIn 0.8s ease-in-out;
}

/* tooltips */

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
} */

.tooltip-container {
  /* Shadow/Med */
  position: absolute;

  z-index: 99999;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.49),
    2px 5px 12px 0px rgba(0, 0, 0, 0.48), 10px 20px 22px 0px rgba(0, 0, 0, 0.42),
    22px 44px 30px 0px rgba(0, 0, 0, 0.25),
    39px 78px 35px 0px rgba(0, 0, 0, 0.07),
    61px 122px 38px 0px rgba(0, 0, 0, 0.01);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--edge-4, 4px);
  border: 1px solid var(--status-green, #00ff85);
  background: var(--grey-grey-900, #1c1c1c);
}

.tooltip-overlay {
  position: fixed;

  top: 5px;
  left: -5px;
  width: 1849px;

  height: 1072px;
  flex-shrink: 0;
  z-index: 1111;
}
.overlay-coring-3 {
  position: fixed;

  top: 5px;
  left: -5px;
  width: 1849px;

  height: 1072px;
  flex-shrink: 0;
  z-index: 1111;
}

.overlay-coring-4 {
  position: fixed;

  top: 5px;
  left: -5px;
  width: 1849px;

  height: 1072px;
  flex-shrink: 0;
  z-index: 1111;
}
.overlay-coring-5 {
  position: fixed;

  top: 5px;
  left: -5px;
  width: 2000px;

  height: 1300px;
  flex-shrink: 0;
  z-index: 1111;
}

/* .tt-depth-selector {
  top: 34px;
  left: 536px;
}
*/
.tt-mine-component {
  /* top: 120px;
  left: 50px; */
}

.align-item-end {
  align-items: flex-end !important;
}

.tt-depth-selector,
.tt-core-component,
.tt-pathway,
.tt-monitor,
.tt-location,
.tt-energy,
.tt-calculator,
.tt-global,
.tt-ledger,
.tt-area,
.tt-mine-component,
.tt-mine-calculator,
.tt-ledger-mine {
  transition: top 1s ease-in-out, left 1s ease-in-out, bottom 1s ease-in-out,
    right 1s ease-in-out;
}

.tooltip-arrow-1 {
  position: absolute;
  top: 0px;
  left: -12px;
}

.tooltip-arrow-2 {
  position: absolute;
  top: 90px;
  right: -12px;
  transform: rotate(180deg);
}

.tooltip-arrow-4 {
  position: absolute;
  top: 190px;
  left: -12px;
}

.tooltip-arrow-5 {
  position: absolute;
  top: 300px;
  right: -12px;

  transform: rotate(180deg);
}

.tooltip-mining .tooltip-media {
  background: var(--brand-warm-charcoal, #383333);
}

.tooltip-media {
  display: flex;
  border-radius: var(--edge-4, 4px);
  background: var(--brand-black, #000);
  width: 440px;
  height: 247px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.tooltip-info-wrap {
  display: flex;
  width: 440px;

  flex-direction: column;
  align-items: flex-start;
  gap: -0.278px;
}

.tooltip-info {
  display: flex;
  padding: var(--edge-4, 4px) 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--edge-4, 4px);
}

.tooltip-info h3 {
  align-self: stretch;

  color: var(--brand-white, #fff);

  /* Paragraph/Large Text */
  font-family: var(--primary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.16px;
}

.ds-wrap {
  position: relative;
}

.dummy-enter {
  opacity: 1;
}

.dummy-enter-border {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 82px;
  height: 82px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.zoom-controller-border {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 191px;
  height: 48px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.monitor-border {
  position: absolute;
  top: 10px;
  left: -12px;
  width: 306px;
  height: 135px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}
.tt-ledger-mine .tooltip-media {
  height: 292px;
}

.monitor-border-mine {
  position: absolute;
  top: 10px;
  left: -12px;
  width: 306px;
  height: 135px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.tt-rtm {
  position: absolute;
  top: 367px;
  left: -12px;
  width: 306px;
  height: 395px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}
.location-border {
  position: absolute;
  top: 135px;
  left: -12px;
  width: 306px;
  height: 52px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.navigator-border {
  position: absolute;
  top: 175px;
  left: -12px;
  width: 304px;
  height: 208px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.control-border {
  position: absolute;
  top: 371px;
  left: -12px;
  width: 304px;
  height: 224px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.ledger-border {
  position: absolute;
  top: -3px;
  left: 3px;
  width: 44px;
  height: 44px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.global-border {
  padding: 10px !important;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.tooltip-info p {
  align-self: stretch;
  color: var(--grey-grey-400, #dadada);

  /* Paragraph/Small Text */
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.12px;
}
.depth-selector-border {
  position: absolute;
  top: -9px;
  left: -7px;
  width: 136px;
  height: 145px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}
.tooltip-info-footer {
  display: flex;
  height: 71.778px;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
}

/* Add this in your CSS file */
.fade-in {
  opacity: 0;
  animation: fadeIn ease-in-out 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-custom {
  opacity: 1;
  animation: fadeOutCustom ease 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOutCustom {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tt-landing {
  top: 286px;
  left: 620px;
}

.tt-landing .tooltip-media {
  background: var(--brand-warm-charcoal, #383333);
}

.tt-landing-2 .tooltip-media {
  background: var(--brand-warm-charcoal, #383333);
}

.tt-landing-2 {
  bottom: 16px;
  right: 218px;
}

.tt-landing-2 .tooltip-arrow {
  position: absolute;
  bottom: -174px;
  right: -12px;
  transform: rotate(180deg);
}

.landing-1-text {
  display: inline-flex;
  padding: 5.416px 10.833px;
  justify-content: center;
  align-items: center;
  gap: 10.833px;
  display: flex;
  flex-direction: row;
}

.mt-5 {
  margin-top: 20px;
}

.landing-1-text p {
  color: var(--brand-white, #fff);
  font-family: var(--secondary-font-regular);
  font-size: 16.249px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16.249px */
  letter-spacing: -0.65px;
  text-transform: uppercase;
}

.coring-component-border {
  position: absolute;
  top: -40px;
  left: -30px;
  width: 136px;
  height: 145px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.mining-component-border {
  position: absolute;
  top: -40px;
  left: -30px;
  width: 136px;
  height: 145px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.grid-wrap-border {
  position: absolute;
  top: 54px;
  left: 202px;
  width: 527px;
  height: 532px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.grid-wrap-border-inspect {
  position: absolute;
  top: 79px;
  left: 462px;
  width: 102px;
  height: 196px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}
.tooltip-close-button-container {
  position: absolute;
  display: inline-flex;
  padding: 12px;
  align-items: flex-start;
  gap: 9.13px;
  top: 10px;
  right: 10px;
}

.ready-wrapper-border {
  position: absolute;
  top: 372px;
  left: -12px;
  width: 307px;
  height: 422px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.calculator-border {
  position: absolute;
  top: -7px;
  left: -7px;
  width: 192px;
  height: 255px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.mining-calculator-border {
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 230px;
  height: 296px;
  stroke-width: 1px;
  border-radius: var(--edge-12, 12px);
  outline: 18px solid rgba(0, 255, 133, 0.4);
  z-index: 900999;
  border: 3px solid var(--status-green, #00ff85);
}

.tooltip-next-button {
  border-radius: 4px;
  border: 1px solid var(--status-green, #00ff85);
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.tooltip-next-button h5 {
  color: var(--status-green, #00ff85);
  text-align: center;

  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);

  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  border-radius: 4px;
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
}

.tooltip-pagination {
  display: flex;
  padding: var(--edge-12, 12px) 16px;
  justify-content: center;
  align-items: center;
  border-radius: var(--edge-4, 4px);
}

.tooltip-pagination h5 {
  color: var(--grey-grey-400, #dadada);
  text-align: center;

  /* Headings/Heading 5 */
  font-family: var(--primary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  border-radius: var(--edge-4, 4px);
}

.tt-close-button {
  display: flex;
  width: 27.391px;
  height: 27.391px;
  justify-content: center;
  align-items: center;
  border-radius: 27.391px;
  border: 0.913px solid var(--gradients-box-outline, #9f9f9f);
  background: rgba(255, 255, 255, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.grid-pathway-container {
  animation: fadeIn2 0.8s ease-in-out;
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.path-label {
  display: inline-flex;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 2.161px;
  background: var(--brand-white, #fff);
}

.path-label p {
  color: var(--brand-black, #000);
  text-align: center;
  font-family: var(--primary-font);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 9.36px */
  letter-spacing: -0.09px;
  opacity: 0.8;
}

/* map css */

.map-content-wrap {
  height: auto;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.map-container-outer {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
}
.left-grid-wrap {
  background: var(--primary-color);
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
/* .left-grid-wrap .ruler{ margin-top:1.125rem;} */
.top-grid-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  padding-left: 32px;
}
.map-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 20px;
  background: #383838 url("../../images/main-bg.png") no-repeat 0 0 / cover;
}
.map-nav {
  display: flex;
  flex: 1 1 100%;
  cursor: pointer;
}
.map-nav-center {
  display: flex;
  flex: 1 1 100%;
  background: rgba(156, 154, 154, 0.1);
  cursor: pointer;
}
.map-nav-center:hover {
  background: transparent;
}
.map-spacing-wrap {
  width: 100%;
  display: flex;
  flex: 1 1 100%;
}
.map-center-wrap {
  flex: 0 0 768px;
  display: flex;
  justify-content: center;
  background-color: rgba(156, 154, 154, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.map-center-wrap:hover {
  background: transparent;
}

.move-icon {
  background: rgba(0, 0, 0, 0.5);
  width: fit-content;
  height: 44px;
  border-radius: 2px;
  align-self: center;
  padding-left: 0.8rem;
  padding-right: 0.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0;
  justify-content: center;
}
.move-icon > div:first-child {
  height: 22px;
}
.map-center-wrap.top {
  padding-top: 20px;
  justify-content: flex-end;
}
.map-center-wrap.bottom {
  padding-bottom: 20px;
  justify-content: flex-start;
}
.map-nav-center.left {
  justify-content: flex-end;
}
.map-nav-center.right {
  justify-content: flex-start;
}

.map-center-wrap.top .move-icon {
  margin-bottom: 40px;
}
.map-center-wrap.bottom .move-icon {
  margin-top: 40px;
}
.map-nav-center.left .move-icon {
  margin-right: 40px;
}
.map-nav-center.right .move-icon {
  margin-left: 40px;
}

.text-wrapper {
  width: auto;
  transform: translateX(5px);
}
.text {
  max-width: 0;
  white-space: nowrap;
  transition: all 0.8s linear;
  color: var(--secondary-color);
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-right: 5px;
  visibility: hidden;
}
.move-icon:hover .text {
  max-width: 300px;
  visibility: visible;
}

.radio-module {
  position: absolute;
  bottom: 10px;
  left: 12px;
  z-index: 888;
  background: var(--primary-color);
  width: 205px;
  height: 53px;
  padding: 10px;
  border-radius: 14px;
  display: none;
}

.ds-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 5px;
  width: 123px;
  background: #1c1c1c;
  border: 1px solid #000000;
  border-radius: 12px;
  flex-grow: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 555;
  margin: 8px;
}
.ds-row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.5;
}

.ds-title {
  font-size: 10px;
  text-transform: uppercase;
  width: 100%;
  white-space: nowrap;
  color: var(--secondary-color);
  line-height: 104%;
  font-family: var(--primary-font);
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
}
.ds-title-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5;
}
.ds-circle-wrap {
  flex: 0 0 21px;
}
.ds-circle {
  width: 21px;
  height: 21px;
  background: var(--grey-grey-600);
  border-radius: 100%;
  text-align: center;
}
.ds-circle img {
  vertical-align: 1px;
}

.ds-row .ds-depth {
  flex: 0 0 24px;
}
.ds-row .ds-depth img {
  display: block;
}
.ds-row .ds-lock {
  flex: 0 0 10px;
}
.ds-row .ds-lock .lock-icon {
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
}
.ds-row .ds-lock svg {
  fill: #444444;
}
.ds-row .ds-distance {
  display: flex;
}
.ds-row .ds-distance span {
  background-color: var(--primary-color);
  border-radius: 4px;
  font-size: 10px;
  min-width: 40px;
  height: 14px;
  text-align: center;
  display: inline-block;
  line-height: 1.2;
  padding: 0 5px;
}
.ds-row.depth-1 .ds-distance span {
  color: #64bbeb;
}
.ds-row.depth-2 .ds-distance span {
  color: #f2ef4c;
}
.ds-row.depth-3 .ds-distance span {
  color: #aa5afa;
}
.ds-row:hover {
  opacity: 1;
  cursor: pointer;
}
.ds-row:hover .ds-lock svg {
  fill: #fff;
}
.ds-row.active {
  opacity: 1;
  cursor: pointer;
}
.ds-row.active .ds-lock svg {
  fill: #fff;
}
.grid {
  z-index: 1;
}

.mobile-handle-energy-core-calculator-wrap {
  width: auto;
  position: absolute;
  z-index: 556;
  top: 0;
  left: 0;
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mh-icon {
  width: 39px;
  height: 39px;
  border-radius: 24px;
  border: 1px solid var(--primary-color);
  background: var(--grey-grey-900);
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.mh-icon > .circle-icon-wrap {
  width: 21px;
  height: 21px;
  background: var(--grey-grey-600);
  border-radius: 100%;
  text-align: center;
}
.mh-icon > .circle-icon-wrap img {
  vertical-align: 1px;
}

@media (max-width: 1322px) {
  .map-container-outer {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1290px) {
  .ds-wrap {
    top: 23px;
  }
}

@media (max-width: 1023px) {
  .ds-wrap {
    top: unset;
    left: unset;
    position: relative;
    margin: 0;
    border-radius: 22px;
    transition: all 0.3s ease;
  }
  .ruler {
    display: none;
  }
  .radio-module {
    display: none;
  }
  .map-side-area {
    display: none;
  }
  .map-spacing-wrap {
    justify-content: center;
    align-items: center;
  }
  .map-area {
    border-radius: 28px;
    overflow: hidden;
  }
  .map-content-wrap {
    width: 100%;
  }
  .ds-wrap.hide {
    display: none !important;
  }
  .mh-icon.hide {
    display: none;
  }
}

@media (max-width: 450px) {
  .map-area {
    overflow: auto;
  }
}
