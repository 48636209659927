.monitor-container {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}
.m-detail-wrap {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-family: var(--primary-font);
  font-size: 1rem;
}
.monitor-time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--mint);
}
.monitor-time > div:first-child {
  align-self: center;
  height: 17px;
}
.monitor-time > div:first-child svg {
  fill: var(--mint);
}
.monitor-hours {
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
}
.unit-number {
  font-size: 1rem;
}
.unit {
  font-size: 10px;
}
.monitor-energy-wrap {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.e-indicator {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  gap: 2px;
}
.e-indicator > div:first-child {
  height: 13px;
}
.e-utilize {
  color: #444444;
  font-size: 12px;
  font-family: var(--primary-font);
}

.monitor-container.high .unit-number,
.monitor-container.high .unit,
.monitor-container.high .e-indicator {
  color: var(--status-green);
}
.monitor-container.high .e-indicator svg {
  fill: var(--status-green);
}
.monitor-container.mid .unit-number,
.monitor-container.mid .unit,
.monitor-container.mid .e-indicator {
  color: var(--accents-64);
}
.monitor-container.mid .e-indicator svg {
  fill: var(--accents-64);
}
.monitor-container.low .unit-number,
.monitor-container.low .unit,
.monitor-container.low .e-indicator {
  color: var(--status-orange);
}
.monitor-container.low .e-indicator svg {
  fill: var(--status-orange);
}
.monitor-container.critical .unit-number,
.monitor-container.critical .unit,
.monitor-container.critical .e-indicator {
  color: var(--status-red);
}
.monitor-container.critical .e-indicator svg {
  fill: var(--status-red);
}
