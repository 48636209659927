.my-locker-container{ width:100%; height: auto; display: flex; flex-direction: column; gap:4.5rem; padding-bottom: 5rem;}
.ml-discoveries, .ml-badges, .ml-mechs{ width:100%; height: auto; display: flex; flex-direction: column; gap:1.5rem;}
.card-container{ width:100%; height: auto; grid-gap:1.5rem;display: grid;grid-template-columns: repeat(4,1fr);}
.card{ width:100%;position: relative; }
.card > img, .card > video{ border-radius:6px; width: 100%; height: auto;box-shadow:  0px -1px 15px 0px rgba(0, 0, 0, 1);}
.card > .card-info{ width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; background: transparent; position: absolute;bottom:0; left: 0;}
.card > .card-info > .card-title{ font-size: 9px; font-family: var(--primary-regular);font-style: normal;font-weight: normal;line-height: normal;letter-spacing: 0.26px;text-transform: uppercase; padding: 12px;}
.card > .card-info.gold{ background: var(--gold);}
.card > .card-info.silver{ background: var(--silver);}
.card > .card-info.bronze{ background: var(--bronze);}
.card > .card-info.gold > .card-title { color: var(--gold-text);}
.card > .card-info.silver > .card-title{ color: var(--silver-text);}
.card > .card-info.bronze > .card-title{ color: var(--bronze-text);}
.card > .card-info > .card-verified{ position: absolute; bottom: 12px; right:12px; width:24px; height: 44px; display: flex; justify-content: center; align-items: center;}
.card > .card-info.gold > .card-verified{ background: url(../../images/gold.png) no-repeat 0 0 / cover}
.card > .card-info.silver> .card-verified{ background: url(../../images/silver.png) no-repeat 0 0 / cover}

.card > .card-info.bronze > .card-verified{ background: url(../../images/bronze.png) no-repeat 0 0 / cover}
.card > .card-info > .card-verified img{ mix-blend-mode: overlay;}


.badge-container{ width:100%; height: auto; display: flex; justify-content: flex-start; gap: 1.5rem; flex-wrap: wrap;}

@media(max-width:1199px){
    .card-container{ width:100%; height: auto; grid-gap:1.5rem;display: grid;grid-template-columns: repeat(2,1fr);}
}

@media(max-width:650px){
    .card-container{ width:100%; height: auto; grid-gap:1.5rem;display: grid;grid-template-columns: repeat(1,1fr);}
    
}
@media(max-width:450px){.badge-container > .badge-img-wrap{ width:calc(50% - 12px); display: flex; align-items: center;justify-content: center;}}

