.c-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 10px;
}
.c-wrapper > .section-title {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--secondary-color);
  letter-spacing: 1px;
  cursor: default !important;
}

.cr-wrap {
  width: 100%;
  height: auto;
  display: flex;
  gap: 2.3125rem;
}
.cr-bottom-wrapper {
  width: 100%;
  display: block;
  margin-top: 3rem;
}
.cr-list-left {
  flex: 0 0 318px;
}
.cr-list-left .pl-wrapper > .pl-row.pl-header {
  color: #fff;
}
.cr-list-left .pl-wrapper.pl-content-wrap {
  padding: 4px;
}
.cr-bottom-wrapper .section-title .accordion-icon {
  display: none;
}
.cr-list-right {
  flex-grow: 1;
}
.cd-wrap {
  width: 100%;
  height: auto;
  padding: 5px;
  margin-top: 10px;
}
.cd-wrap.find {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid #02b860;
  border-radius: 4px;
}
.cd-wrap.inprogress {
  background: rgba(251, 190, 94, 0.2);
  border: 1px solid #d68c16;
  border-radius: 4px;
}
.cd-wrap.no-find {
  background: rgba(250, 90, 90, 0.2);
  border: 1px solid #cc4a4a;
  border-radius: 4px;
}
.cd-header {
  width: 100%;
  height: 37px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #fff;
  line-height: 104%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.39rem;
  text-transform: uppercase;
}
.cd-content {
  width: 100%;
  display: flex;
  padding: 5px;
}
.cd-content > .cd-list {
  display: flex;
  gap: 3px;
  flex-direction: column;
}
.cd-content > .cd-list > .cd-row {
  display: flex;
  gap: 15px;
}
.cd-content > .cd-list.cd-distance {
  flex: 0 1 45%;
}
.cd-content > .cd-list.cd-depth {
  flex: 0 1 30%;
}
.cd-content > .cd-list.cd-quality {
  flex: 0 1 25%;
}
.cd-content > .cd-list > .cd-row > .cd-title {
  font-size: 0.75rem;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}
.cd-content > .cd-list > .cd-row > .cd-description {
  flex: 1 1 auto;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2px;
}
.cd-content > .cd-distance > .cd-row > .cd-title {
  flex: 0 0 75px;
}
.cd-content > .cd-depth > .cd-row > .cd-title {
  flex: 0 0 50px;
}
.cd-content > .cd-quality > .cd-row > .cd-title {
  flex: 0 0 60px;
}

.energy-usage-chart-wrap,
.core-depth-chart-wrap {
  width: 100%;
}
.chart-title-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.chart-title-wrap > .chart-title {
  font-size: 0.6875rem;
  color: #fff;
}
.chart-title-wrap > .chart-unit {
  font-size: 1rem;
  color: #757575;
  display: flex;
  align-items: center;
  gap: 5px;
}
.chart-title-wrap > .chart-unit > span {
  font-size: 10px;
  text-transform: uppercase;
}
.chart-title-wrap > .chart-unit.eu {
  color: #d68c16;
}

.core-chart-wrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.cc-top-wrap {
  display: flex;
  width: 100%;
  gap: 12px;
}
.cc-icon-wrap {
  flex: 0 0 24px;
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}
.cc-depth-main-wrap {
  flex: 0 0 276px;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 5px;
}
.cc-depth-wrap {
  border-radius: 4px;
  flex-grow: 1;
  display: flex;
  gap: 1px;
  position: relative;
  background-color: rgba(28, 28, 28, 0.5);
  background-image: url(../../images/grain.png);
  background-repeat: repeat;
  background-position: 0 0;
}
.cc-depth-wrap:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.cc-depth-wrap > .cc-depth-col {
  width: 22px;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  flex-shrink: 0;
  box-shadow: inset 0px -2px 4px rgba(255, 255, 255, 0.09),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 4px rgba(0, 0, 0, 0.24);
  display: flex;
  align-self: stretch;
  background-color: rgba(255, 255, 255, 0.02);
}

.cc-depth-wrap > .cc-depth-col.find {
  background: #193226;
}
.cc-depth-wrap > .cc-depth-col.depth-1 {
  justify-content: flex-start;
}
.cc-depth-wrap > .cc-depth-col.depth-2 {
  justify-content: center;
}
.cc-depth-wrap > .cc-depth-col.depth-3 {
  justify-content: flex-end;
}

.cc-depth-wrap > .cc-depth-col > .cc-depth-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: var(--nofind);
}
.cc-depth-wrap > .cc-depth-col.find > .cc-depth-circle {
  background: var(--find);
}

.cc-depth-title {
  font-size: 11px;
  color: #757575;
  text-transform: uppercase;
}
.cc-depth-ruler {
  width: 100%;
  display: flex;
  gap: 1px;
}
.cc-depth-ruler > .cc-ruler-item {
  width: 22px;
  flex-shrink: 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 11px;
  color: #757575;
  gap: 1px;
}
.cc-depth-ruler > .cc-ruler-item::after {
  content: "";
  width: 1px;
  height: 100%;
  background: #757575;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.cc-depth-ruler > .cc-ruler-item.find {
  color: var(--find);
}
.cc-depth-ruler > .cc-ruler-item.nofind {
  color: var(--nofind);
}

.cc-depth-main-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  width: 100%;
}
.cc-depth-main-wrap {
  scrollbar-width: thin;
  scrollbar-color: #292828 !important;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.cc-depth-main-wrap::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.cc-depth-main-wrap::-webkit-scrollbar-track {
  background-color: #292828;
}
.cc-depth-main-wrap::-webkit-scrollbar-thumb:horizontal {
  background: #141414 !important;
  border-radius: 10px;
}
.results .pl-row.find {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid #02b860;
  border-radius: 4px;
}

/* .ready-core-wrapper{width:100%; height: auto; display:flex; flex-direction: column; gap: 10px; margin-top: 10px;}
.explore-text{ width:100%; font-size:11px; font-family: var(--primary-font-regular); color: var(--secondary-color); text-align: center;line-height: 190%;text-transform: uppercase; letter-spacing: 1px;}
 */

/*Complete Cor Modal */
.cc-modal {
  max-width: 440px;
  width: 90%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--brand-black, #000);

  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  z-index: 999;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
  transition: all 0.3s ease;
}
.cc-modal.animate {
  transform: translate(-50%, -50%);
  top: 50%;
}
.cc-modal-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-size: 4rem;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: normal;
  line-height: 85%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}
.cc-modal-info {
  text-align: center;
  font-size: 14px;
  font-family: var(--primary-font-regular);
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  letter-spacing: -0.07px;
  color: var(--secondary-color);
}
.cc-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cc-modal-footer .btn {
  background: transparent;
  border: 1px solid var(--grey-grey-600);
}
.cc-modal-footer .btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
/*Core Map */
.core-map-wrap {
  width: 179px;
  height: auto;
  padding: 1rem 0.7rem;
  z-index: 100;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-color);
  background: var(--grey-grey-900);
  margin: 8px;
}
.core-map {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.cm-title-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.fx-circle-wrap {
  flex: 0 0 21px;
}
.fx-circle {
  width: 21px;
  height: 21px;
  background: var(--grey-grey-600);
  border-radius: 100%;
  text-align: center;
}
.fx-circle img {
  vertical-align: 1px;
}
.cm-title {
  font-size: 10px;
  font-family: var(--primary-font);
  color: var(--secondary-color);
  text-transform: uppercase;
}

.cm-depth-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
}
.cm-depth-wrap > .cm-depth-row {
  display: flex;
  gap: 8px;
  align-items: center;
}
.cm-depth-wrap > .cm-depth-row > .cm-depth-icon {
  flex: 0 0 20px;
  height: 20px;
}
.cm-depth-wrap > .cm-depth-row > .cm-depth-value {
  flex: 0 0 24px;
  color: var(--secondary-color);
  font-size: 10px;
  font-family: var(--primary-font-regular);
}
.cm-depth-wrap > .cm-depth-row > .cm-depth-divider {
  flex: 0 0 1px;
  background: var(--grey-grey-600);
  align-self: stretch;
}
.cm-depth-wrap > .cm-depth-row > .cm-energy-ultlize {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: var(--secondary-color);
  font-size: 10px;
  text-transform: uppercase;
}

.cm-travel-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.cm-travel-wrap > .cm-travel-icon {
  flex: 0 0 24px;
  padding-left: 2px;
  height: 20px;
}
.cm-travel-wrap > .cm-travel-title {
  flex: 0 0 47px;
  color: #808080;
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.cm-travel-wrap > .cm-travel-energy {
  flex: 1 0 65px;
  color: var(--secondary-color);
  font-size: 10px;
  font-family: var(--primary-font);
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
}
.cm-divider {
  width: 100%;
  height: 1px;
  background: var(--grey-grey-600);
}

.cm-total-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
.cm-total-wrap > .cm-total-icon {
  flex: 0 0 24px;
  padding-left: 2px;
  height: 16px;
}
.cm-total-wrap > .cm-total-title {
  flex: 0 0 47px;
  color: var(--find);
  font-size: 10px;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.cm-total-wrap > .cm-total-energy {
  flex: 1 0 65px;
  color: var(--find);
  font-size: 10px;
  font-family: var(--primary-font);
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
}
.yellow {
  color: var(--status-orange, #ffa71a) !important;
}

.red {
  color: var(--status-red, #ff4d4d) !important;
}
.cm-total-wrap > .cm-total-title.yellow {
  color: var(--status-orange, #ffa71a);
}

.cm-extra-title-yellow {
  margin-top: 10px;
  text-align: center;
  font-size: 9px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  color: var(--status-orange, #ffa71a);
}

.cm-extra-title-red {
  margin-top: 10px;
  text-align: center;
  font-size: 9px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  color: var(--status-red, #ff4d4d);
}
.cm-total-wrap > .cm-total-title.red {
  color: var(--status-red, #ff4d4d);
}

.cm-total-wrap > .cm-total-energy.yellow {
  color: var(--status-orange, #ffa71a);
}

.cm-total-wrap > .cm-total-energy.red {
  color: var(--status-red, #ff4d4d);
}

.cm-footer {
  width: 100%;
  margin-top: 8px;
}
.cm-footer .btn {
  background: rgba(0, 255, 133, 0.1);
  border: 1px solid var(--find);
  border-radius: 4px;
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
  color: var(--status-green);
}

.cm-footer .btn:hover {
  color: var(--primary-color);
  background-color: var(--find);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
  border: 1px solid var(--secondary-color);
}

.cm-footer .btn.yellow {
  color: var(--status-orange, #ffa71a);
  text-shadow: 0px 0px 6px #ffa71a;
  border: 1px solid var(--status-orange, #ffa71a);
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
}

.cm-footer .btn.yellow:hover {
  color: var(--primary-color);
  background-color: var(--status-orange);
}

.cm-footer .btn.red {
  text-shadow: 0px 0px 6px rgba(255, 0, 0, 0.7);
  color: var(--status-red, #ff4d4d);
  border: 1px solid var(--status-red, #ff4d4d);
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
}

.cm-footer .btn.red:hover {
  color: var(--primary-color);
  background-color: var(--nofind);
}

.core-map-process {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.coring-process-anim {
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: center;
}
.cm-process-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}
.cm-process-icon {
  flex: 0 0 18px;
  height: 19px;
}
.cm-process-title {
  color: var(--process);
  font-size: 12px;
  font-family: var(--primary-font);
  text-transform: uppercase;
  flex: 1;
  letter-spacing: -0.48px;
  white-space: nowrap;
}

.coring-section-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.coring-section-wrap:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: -15px;
  left: 0;
  background: url("../../images/icons/bottom-rounded-corner.svg") no-repeat 0 0;
}
.coring-section-wrap:after {
  content: "";
  width: 100%;
  height: 8px;
  background: #000;
  position: absolute;
  bottom: -8px;
  left: 0;
}
.coring-section {
  max-width: 200px;
  height: 40px;
  padding-top: 6px;
  background: var(--primary-color);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.coring-section::before {
  content: "";
  width: 36px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: -35px;
  z-index: 2;
  background: url("../../images/section-left-corner.svg") no-repeat 0 0;
}
.coring-section::after {
  content: "";
  width: 36px;
  height: 40px;
  position: absolute;
  top: 0px;
  right: -35px;
  z-index: 2;
  background: url("../../images/section-right-corner.svg") no-repeat 0 0;
}

.section-divider {
  width: 100%;
  height: 1px;
  background: var(--grey-grey-600);
  margin: 1.25rem 0;
  opacity: 0.4;
}
.coring-logo-bottom {
  width: 46px;
  height: 26px;
}
.coring-section-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
}
.coring-section-content h2 {
  font-size: 12px;
  line-height: 0.5;
  font-family: var(--primary-font);
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: var(--secondary-color);
  text-transform: uppercase;
}
.coring-section-content p {
  margin: 0;
  font-size: 0.625rem;
  font-family: var(--primary-font);
  font-weight: normal;
  color: #969696;
  opacity: 0.8;
}

.welcome-modal-wrap {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(60px);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
}
.welcome-logo-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
}
.welcome-modal {
  position: absolute;
  max-width: 490px;
  width: 90%;
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  border-radius: 4px;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
}

.welcome-modal-title,
.welcome-modal-info {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-family: var(--primary-font-regular);
  text-align: center;
}
.welcome-button-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.welcome-button-wrap .btn {
  background: transparent;
  border: 1px solid var(--grey-grey-600);
}
.welcome-button-wrap .btn:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.welcome-footer {
  font-size: 14px;
  color: var(--grey-grey-500);
  font-family: var(--primary-font-regular);
  text-align: center;
}

.c-wrapper-2 {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  width: fit-content;
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
}

.pl-main-container {
  text-align: left;
}

.core-map-wrap-2 {
  width: 179px;
  height: auto;
  padding: 1rem 0.7rem;
  z-index: 100;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--primary-color);
  background: var(--grey-grey-900);
  margin: 8px;
}

@media (max-width: 1023px) {
  .cc-depth-main-wrap {
    flex: 1 1 100%;
  }
  .cr-wrap {
    flex-direction: column;
    gap: 0;
  }
  .cr-bottom-wrapper .section-title .accordion-icon {
    display: block;
  }
  .cr-list-left {
    flex: 1 1 auto;
  }
  .cr-list-left .collapse.show {
    margin-bottom: 1.5rem;
  }
  .coring-section {
    right: 0;
    margin: auto;
  }
  .coring-section-wrap:before {
    content: none;
  }
  .core-map-wrap {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 0px;
    border-radius: 22px;
    transition: all 0.3s ease;
  }
  .core-map-wrap.hide {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .cd-content {
    flex-direction: column;
  }
  .cd-content {
    gap: 5px;
  }
  .cd-content > .cd-list {
    gap: 5px;
  }
  .cd-content > .cd-list > .cd-row {
    gap: 5px;
  }
  .cd-content > .cd-list > .cd-row > .cd-title {
    flex: 0 0 80px;
  }
  .cc-modal {
    padding: 2rem;
  }
  .welcome-modal {
    padding: 2rem;
  }
  .welcome-modal-info br {
    display: none;
  }
  .coring-section {
    max-width: 175px;
  }
  .coring-section-content p {
    letter-spacing: 0.5px;
  }
  .cc-modal {
    gap: 12px;
  }
}
@media (max-height: 580px) {
  .welcome-logo-wrap {
    display: none !important;
  }
}
