.btn-animated {
  max-width: 370px;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--status-green);
  background: rgba(0, 255, 133, 0.1);
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--status-green);
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
  box-shadow: 0px 0px 6px rgba(4, 233, 13, 0.9);
}

.btn-animated.yellow {
  color: var(--status-orange, #ffa71a) !important;
  text-align: center;
  /* Glow/Orange */
  text-shadow: 0px 0px 5px rgba(255, 167, 26, 0.85),
    0px 0px 1px rgba(255, 167, 26, 0.5), 0px 1px 1px rgba(255, 167, 26, 0.15),
    0px 1px 41px rgba(255, 167, 26, 0.02);
  box-shadow: 0px 0px 5px 0px rgba(255, 167, 26, 0.85),
    0px 0px 1px 0px rgba(255, 167, 26, 0.5),
    0px 1px 1px 0px rgba(255, 167, 26, 0.15),
    0px 1px 41px 0px rgba(255, 167, 26, 0.02);
  border: 1px solid var(--status-orange, #ffa71a);
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
}

.btn-animated.red {
  border: 1px solid var(--status-red, #ff4d4d);
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
  box-shadow: 0px 0px 5px 0px rgba(255, 77, 77, 0.85),
    0px 0px 1px 0px rgba(255, 77, 77, 0.5),
    0px 1px 1px 0px rgba(255, 77, 77, 0.15),
    0px 1px 41px 0px rgba(255, 77, 77, 0.02);
  color: var(--status-red, #ff4d4d);

  /* Glow/Red */
  text-shadow: 0px 0px 5px rgba(255, 77, 77, 0.85),
    0px 0px 1px rgba(255, 77, 77, 0.5), 0px 1px 1px rgba(255, 77, 77, 0.15),
    0px 1px 41px rgba(255, 77, 77, 0.02);
}

.btn-animated.red:hover {
  background: var(--status-red, #ff4d4d);
  color: #000 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.btn-animated:hover {
  background: var(--status-green);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border: 1ps solid var(--status-green);
}

.btn-animated.yellow:hover {
  background: var(--status-orange, #ffa71a);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  color: #000 !important;
  border: 1ps solid var(--status-green);
}
.btn-arrow-wrap {
  display: flex;
  align-items: center;
  gap: 0px;
}

.btn-arrow-wrap svg {
  fill: #00ff85;
  fill-opacity: 0.1;
}

.btn-arrow-wrap.inward svg {
  transform: rotate(180deg);
}
.btn-arrow-wrap.yellow svg {
  fill: #ffa71a;
  fill-opacity: 0.1;
}

.btn-animated:hover .btn-arrow-wrap svg {
  fill: #000;
  fill-opacity: 0.1;
}
.btn-arrow-wrap.right.animate > div:nth-child(1) svg,
.btn-arrow-wrap.left.animate > div:nth-child(8) svg {
  animation: 0.5s linear alternate both 0.1s arrow;
}
.btn-arrow-wrap.right.animate > div:nth-child(2) svg,
.btn-arrow-wrap.left.animate > div:nth-child(7) svg {
  animation: 0.5s linear alternate both 0.2s arrow;
}
.btn-arrow-wrap.right.animate > div:nth-child(3) svg,
.btn-arrow-wrap.left.animate > div:nth-child(6) svg {
  animation: 0.5s linear alternate both 0.3s arrow;
}
.btn-arrow-wrap.right.animate > div:nth-child(4) svg,
.btn-arrow-wrap.left.animate > div:nth-child(5) svg {
  animation: 0.5s linear alternate both 0.4s arrow;
}
.btn-arrow-wrap.right.animate > div:nth-child(5) svg,
.btn-arrow-wrap.left.animate > div:nth-child(4) svg {
  animation: 0.5s linear alternate both 0.5s arrow;
}
.btn-arrow-wrap.right.animate > div:nth-child(6) svg,
.btn-arrow-wrap.left.animate > div:nth-child(3) svg {
  animation: 0.5s linear alternate both 0.6s arrow;
}

.btn-arrow-wrap.right.animate > div:nth-child(7) svg,
.btn-arrow-wrap.left.animate > div:nth-child(2) svg {
  animation: 0.5s linear alternate both 0.7s arrow;
}

.btn-arrow-wrap.right.animate > div:nth-child(8) svg,
.btn-arrow-wrap.left.animate > div:nth-child(1) svg {
  animation: 0.5s linear alternate both 0.8s arrow;
}

.btn-arrow-wrap.right.animate .single-arrow,
.btn-arrow-wrap.left.animate .single-arrow {
  animation: 0.1s linear alternate both arrow;
}

/*yellow */
.btn-arrow-wrap.right.animate.yellow > div:nth-child(1) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(8) svg {
  animation: 0.5s linear alternate both 0.1s arrowYellow;
}
.btn-arrow-wrap.right.animate.yellow > div:nth-child(2) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(7) svg {
  animation: 0.5s linear alternate both 0.2s arrowYellow;
}
.btn-arrow-wrap.right.animate.yellow > div:nth-child(3) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(6) svg {
  animation: 0.5s linear alternate both 0.3s arrowYellow;
}
.btn-arrow-wrap.right.animate.yellow > div:nth-child(4) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(5) svg {
  animation: 0.5s linear alternate both 0.4s arrowYellow;
}
.btn-arrow-wrap.right.animate.yellow > div:nth-child(5) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(4) svg {
  animation: 0.5s linear alternate both 0.5s arrowYellow;
}
.btn-arrow-wrap.right.animate.yellow > div:nth-child(6) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(3) svg {
  animation: 0.5s linear alternate both 0.6s arrowYellow;
}

.btn-arrow-wrap.right.animate.yellow > div:nth-child(7) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(2) svg {
  animation: 0.5s linear alternate both 0.7s arrowYellow;
}

.btn-arrow-wrap.right.animate.yellow > div:nth-child(8) svg,
.btn-arrow-wrap.left.animate.yellow > div:nth-child(1) svg {
  animation: 0.5s linear alternate both 0.8s arrowYellow;
}

/*Animated Button Hovered*/

.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(1) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(8) svg {
  animation: 0.5s linear alternate both 0.1s arrowHover;
}
.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(2) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(7) svg {
  animation: 0.5s linear alternate both 0.2s arrowHover;
}
.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(3) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(6) svg {
  animation: 0.5s linear alternate both 0.3s arrowHover;
}
.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(4) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(5) svg {
  animation: 0.5s linear alternate both 0.4s arrowHover;
}
.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(5) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(4) svg {
  animation: 0.5s linear alternate both 0.5s arrowHover;
}
.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(6) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(3) svg {
  animation: 0.5s linear alternate both 0.6s arrowHover;
}

.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(7) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(2) svg {
  animation: 0.5s linear alternate both 0.7s arrowHover;
}

.btn-animated:hover .btn-arrow-wrap.right.animate > div:nth-child(8) svg,
.btn-animated:hover .btn-arrow-wrap.left.animate > div:nth-child(1) svg {
  animation: 0.5s linear alternate both 0.8s arrowHover;
}

@keyframes arrow {
  100% {
    fill: #00ff85;
    fill-opacity: 1;
  }
}

@keyframes arrowYellow {
  100% {
    fill: #ffa71a;
    fill-opacity: 1;
  }
}
@keyframes arrowHover {
  100% {
    fill: #000;
    fill-opacity: 1;
  }
}
