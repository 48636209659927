.manual-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.manual-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: rgba(1, 26, 20, 0.7);
  box-shadow: 0px 1px 1px 0px rgba(25, 92, 63, 0.14),
    0px 2px 2px 0px rgba(25, 92, 63, 0.12),
    0px 5px 3px 0px rgba(25, 92, 63, 0.07),
    0px 9px 3px 0px rgba(25, 92, 63, 0.02), 0px 14px 4px 0px rgba(25, 92, 63, 0),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(45px);
  backdrop-filter: blur(45px);
  z-index: 9999;
}

.manual-stack {
  display: flex;
  width: 1316px;
  height: 945px;
  padding: 70px 48px 30px 48px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
}

.stack-header {
  display: flex;
  height: 55.23px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.header-logo {
  display: flex;
  padding-right: 0px;
  align-items: center;
  gap: 21px;
  flex: 1 0 0;
  align-self: stretch;
  border: 0.5px solid var(--accents-crt-text, #61e8a2);
}

.logo-wrap {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-right: 0.5px solid var(--accents-crt-text, #61e8a2);
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.714px;
}

.header-title h4 {
  color: #61e8a2;
  text-shadow: 0px 0px 8.571385383605957px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.header-title p {
  color: #61e8a2;

  /* Glow/Green */
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.header-close {
  display: flex;
  height: 55.23px;
  width: 87.228px;

  align-items: flex-start;
  gap: 10px;
}
.header-close img {
  height: 55.23px;
  width: 87.228px;
  flex-shrink: 0;
}

.manual-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.manual-content-frame {
  display: flex;
  align-items: flex-start;
  gap: 29px;
  flex: 1 0 0;
  align-self: stretch;
}

.manual-text-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -32px;
  flex: 1 0 0;
  align-self: stretch;
}

.bottom-frame {
  display: flex;
  position: relative;
  height: 24px;
  padding: 0px var(--edge-4, 4px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  bottom: 24px;
  border: 1px solid rgba(0, 255, 133, 0.5);

  /* Glow/Green */
  /* box-shadow: 0px 4px 4px 0px rgba(4, 233, 13, 0.5); */
}

.manual-content .top-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.manual-content .left-manual-frame {
  display: flex;
  width: 24px;
  padding: 0px 4px 0px 2.857px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(0, 255, 133, 0.5);

  /* Glow/Green */
  box-shadow: 0px 4px 4px 0px rgba(4, 233, 13, 0.5);
}

.manual-content .manual-middle-frame {
  display: flex;
  padding: 0px 16px 24px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--brand-transparent, rgba(255, 255, 255, 0));
}

.manual-content .right-manual-frame {
  display: flex;
  width: 24px;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(0, 255, 133, 0.5);

  /* Glow/Green */
  box-shadow: 0px 4px 4px 0px rgba(4, 233, 13, 0.5);
}

.manual-text-content {
  flex: 1 0 0;
  display: flex;
  align-self: stretch;
  position: relative;
  padding: 0 88px 0px 18px;
}

.manual-training {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  border-radius: 8px;
  height: 775px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 8px;
  scroll-behavior: smooth;
}

.manual-top {
  position: absolute;
  bottom: 10px;
  right: 10px;
  user-select: none;
  cursor: pointer;
}

.quick-summary {
  display: flex;
  padding: 10px 31px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.summary-header-wrap {
  display: flex;
  padding: 22.857px;
  flex-direction: column;
  align-items: center;
  gap: 21.428px;
  align-self: stretch;
  border-radius: 2.857px;
}

.summary-title-wrap {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.summary-title-wrap h3 {
  color: var(--accents-crt-text, #61e8a2);
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 85%; /* 81.6px */
  letter-spacing: -0.96px;
  text-transform: uppercase;
}

.summary-title-wrap p {
  color: var(--accents-crt-text, #61e8a2);
  text-shadow: 0px 0px 8.571385383605957px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.summary-separator {
  width: 924.286px;
  height: 1px;
  background: #61e8a2;
}

.summary-content-wrap {
  display: flex;
  padding: 0px 130px;
  align-items: center;
  gap: 310px;
  align-self: stretch;
}

.summary-content-wrap p {
  color: var(--accents-crt-text, #61e8a2);
  text-align: center;
  text-shadow: 0px 0px 8.571385383605957px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.manual-tabs-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.manual-tabs-wrap h5 {
  color: var(--accents-crt-text, #61e8a2);
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.manual-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--edge-4, 4px);
  user-select: none;
}

.manual-tab {
  display: flex;
  padding: var(--edge-4, 4px) 8px;
  align-items: center;
  gap: var(--edge-4, 4px);
  border-radius: 2px 2px 0px 0px;
  color: var(--accents-crt-text, #61e8a2);
  border-top: 1px solid var(--accents-crt-text, #61e8a2);
  border-right: 1px solid var(--accents-crt-text, #61e8a2);
  border-left: 1px solid var(--accents-crt-text, #61e8a2);
  user-select: none;
  cursor: pointer;
}

.manual-tab .tab-number {
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 12.24px */
  letter-spacing: -0.36px;
  text-transform: uppercase;
  opacity: 0.6;
}

.manual-tab .tab-text {
  text-align: center;

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.manual-tab.clicked {
  color: var(--brand-black, #000) !important;
  background: var(--accents-crt-text, #61e8a2);
}

.training-manual-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--accents-crt-text, #61e8a2);
}

.training-header {
  display: flex;
  width: 1032px;
  padding: 8px;
  border-radius: 10px;
  align-items: center;
  gap: 8px;
  background: var(--accents-crt-text, #61e8a2);
}

.training-header p {
  color: var(--brand-black, #000);

  /* Glow/Status-Text */
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.training-header-separator {
  flex: 1 0 0;
  height: 1px;
  background: #000;

  /* Glow/Status-Text */
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.15);
}

.training-content {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.1);
}

.training-image {
  display: flex;
  flex-direction: column;
  width: 515px;
  height: 351px;
  padding: 85.286px 116.005px 84.955px 116px;
  justify-content: center;
  align-items: center;
  border-radius: var(--edge-4, 4px);
  background: rgba(0, 0, 0, 0.3);
}

.training-text {
  display: flex;
  padding: 24px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--edge-4, 4px);
}

.exploration-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10.833px;
  flex-direction: column;
}

.exploration-column > img {
  margin-top: 25px;
}

.exploration-row {
  display: inline-flex;

  padding: 5.416px 10.833px;
  justify-content: center;
  align-items: center;
  gap: 10.833px;
}

.exploration-row p {
  color: var(--brand-white, #fff);
  font-family: var(--secondary-font-regular);
  font-size: 16.249px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16.249px */
  letter-spacing: -0.65px;
  text-transform: uppercase;
}

.training-number {
  flex: 1 0 0;
  align-self: stretch;
  color: var(--accents-crt-text, #61e8a2);

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.training-title {
  align-self: stretch;
  color: var(--accents-crt-text, #61e8a2);

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Mono Labels/Large Labels */
  font-family: var(--secondary-font-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 102%; /* 16.32px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.training-summary {
  align-self: stretch;
  color: var(--accents-crt-text, #61e8a2);

  /* Glow/Text Glow */
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);

  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.custom-scrollbar {
  position: absolute;
  top: 5px;
  right: -4px;
  height: calc(100% - 15px);
  width: 3px;
  background: rgba(0, 255, 133, 0.2);
}

.custom-bar {
  position: relative;
  width: 3px;
  height: 215px;
  background: rgba(0, 255, 133, 0.5);

  /* Glow/Green */
  box-shadow: 0px 4px 4px 0px rgba(4, 233, 13, 0.5);
}
