.monitor-container {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--grey-grey-900, #1c1c1c);
  background: var(--grey-grey-900, #1c1c1c);
  color: var(--brand-white, #fff);
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 20px;
}

.monitor-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.energy-monitor-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.em-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.em-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.em-hc-title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.em-percent {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
}

.em-percent p {
  color: var(--status-green, #00ff85);

  /* Mono Labels/Small Labels */
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.em-button {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid var(--status-green, #00ff85);
  background: var(--status-find-background, rgba(0, 255, 133, 0.1));
}

.em-button p {
  color: var(--status-green, #00ff85);

  /* Mono Labels/Small Labels */
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.energy-bar-container {
  flex-shrink: 0;
  align-self: stretch;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset,
    0px -7px 14px 0px rgba(255, 255, 255, 0.14) inset,
    -4px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 4px;
  position: relative;
}

.energy-bar {
  width: 50%;
  border-radius: 4px;
  height: 32px;
  flex-shrink: 0;
  background: url("../../images/monitor/Grain.svg") repeat,
    linear-gradient(90deg, #117338 0%, #149c9c 46.87%, #17347e 100%);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset,
    0px -7px 14px 0px rgba(255, 255, 255, 0.14) inset,
    -4px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.monitor-separator {
  align-self: stretch;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.hm-button {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  border: 1px solid var(--brand-white, #fff);
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.hm-button p {
  color: var(--brand-white, #fff);

  /* Mono Labels/Small Labels */
  font-family: var(--secondary-font-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 10.4px */
  text-transform: uppercase;
}

.health-bar {
  width: 100%;
  border-radius: 4px;
  height: 32px;
  flex-shrink: 0;
  background: url("../../images/monitor/Grain.svg") repeat,
    linear-gradient(270deg, #8f1616 0%, #aaad1b 46.87%, #65a317 100%);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset,
    0px -7px 14px 0px rgba(255, 255, 255, 0.14) inset,
    -4px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.em-percent.medium {
  background: var(--status-in-prog-background, rgba(251, 190, 94, 0.2));
}

.em-percent.medium p {
  color: var(--status-orange, #ffa71a);
}

.em-percent.low {
  background: var(--status-no-find-background, rgba(250, 90, 90, 0.2));
}

.em-percent.low p {
  color: var(--status-red, #ff4d4d);
}

.bar-position {
  position: absolute;
  width: 4px;
  height: 32px;
  flex-shrink: 0;
}
