.sidebar.left{margin-top:30px; width:60px;  background:var(--sidebar-nav-bg); display: flex; flex-direction: column; justify-content: center; align-items: center; border-top-right-radius: 10px;}
/* .sidebar.left::before{content: ''; width:50px; height:56px; position:absolute; top:-55px; left:0; z-index:2;background:url("../../images/left-bar-corner-top.svg") no-repeat 0 0; background-size:100%;display:inline-block;vertical-align:top;}
.sidebar.left::after{content: ''; width:50px; height:56px; position:absolute; bottom:-55px; left:0; z-index:2;background:url("../../images/left-bar-corner-bottom.svg") no-repeat 0 0;background-size:100%;display:inline-block;vertical-align:top;} */
.sidebar.left ul{ margin: 0px; padding: 0; list-style-type: none; width:100%; display: flex; flex-direction: column; justify-content: center; gap:1rem; align-items: center;}
.sidebar.left ul li{ color: #7f7f7f;cursor: pointer; display: flex;flex-direction: column; gap: 5px; font-size:10px; justify-content: center; align-items: center; text-transform: uppercase; }
.sidebar.left ul li i{ font-size: 2.2rem; }
.sidebar.left ul li i::before{transition: all 0.3s linear;}
.sidebar.left ul li.icon-inspect{ font-size: 2.4rem;}
.sidebar.left ul li:hover, .sidebar.left ul li:hover i::before{ color: var(--secondary-color)}
.sidebar.left ul li.active, .sidebar.left ul li.active i::before{ color: var(--secondary-color)}
.down-arrow-wrap{ display: none !important;}
.sidebar-left-nav{ display: none;}
.back-btn{ display: none;}
@media(max-width:1023px){
    
.sidebar.left{margin-top: 0; width:8px; height: 100%; position: relative;background:var(--primary-color);z-index:555; display: flex; flex-shrink: 0; flex-grow: 0; margin-top: 0; }
.sidebar-left-nav{left:8px; width:38px; min-height:86px !important;  background:var(--primary-color);  position: absolute; z-index: 555; top:50%; transform: translateY(-50%); display: flex; flex-direction: column; gap:12px; align-items: center; padding: 0; justify-content: center; }
.sidebar-left-nav.open{z-index: 888;}
.sidebar-left-nav::before{content: ''; width:38px; height:38px; position:absolute; top:-37px; left:0; z-index:2;background:url("../../images/left-sidebar-corner-top.svg") no-repeat 0 0; background-size:100%;display:inline-block;vertical-align:top;}
.sidebar-left-nav::after{content: ''; width:38px; height:38px; position:absolute; bottom:-37px; left:0; z-index:2;background:url("../../images/left-sidebar-corner-bottom.svg") no-repeat 0 0;background-size:100%;display:inline-block;vertical-align:top;}
.sidebar-left-nav > .sr-icon{ width:100%; cursor: pointer; display: flex; align-items: center;justify-content: center;}
.sidebar.left ul{ position: absolute; bottom:12px; left: 12px; background: var(--primary-color); padding: 10px; border-radius: 40px; width: 54px;}
.sidebar.left ul li i{ font-size: 1.5rem; }
.down-arrow-wrap{ display:block !important;}
.back-btn{ position: fixed;top:1.125rem;left:1.125rem;z-index: 999; color:rgba(255, 255, 255, 0.65); display: flex; gap: 5px; align-items: center; font-size: 10px; text-transform: uppercase;}
}