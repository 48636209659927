.authwrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  background: #0d0d0d;

  margin: 0 10px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.auth-container {
  max-width: 375px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 9999;
  user-select: none;
}
.auth-logo img {
  filter: drop-shadow(0px 0px 0px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 6px 14px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 25px 25px rgba(3, 255, 133, 0.19))
    drop-shadow(
      0px 57.00000762939453px 34.00000762939453px rgba(3, 255, 133, 0.11)
    )
    drop-shadow(0px 102.00001525878906px 41px rgba(3, 255, 133, 0.03))
    drop-shadow(0px 159px 44px rgba(3, 255, 133, 0));
}
.auth-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 84%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

input::placeholder {
  font-family: var(--primary-font);
}
.auth-form-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.auth-form-row {
  width: 100%;
  height: auto;
  display: flex;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--grey-grey-600);
  padding: 4px 8px 6px;
  border-radius: 2px;
  align-items: center;
  margin-top: 1.5rem;
}
.auth-form-row:first-child {
  margin-top: 0;
}
.auth-form-row:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: var(--secondary-color);
}
.auth-form-row.active {
  border-color: var(--status-green);
  background: rgba(0, 255, 133, 0.1);
  margin-top: 1.5rem !important;
}
.auth-form-row.error {
  border-color: var(--status-red);
  background: rgba(250, 90, 90, 0.2);
  margin-top: 1.5rem !important;
}
.af-title {
  flex: 0 0 80px;
  font-size: 12px;
  font-family: var(--primary-font);
  color: var(--secondary-color);
  text-transform: uppercase;
}
.auth-form-row:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: var(--secondary-color);
}
.auth-form-row input[type="text"],
.auth-form-row input[type="password"] {
    width: 100%;
    outline: 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    font-family: var(--primary-font-regular);
    /*color: var(--secondary-color) !important;*/
    color: var(--find) !important;
    text-transform: uppercase;
    background: transparent !important;
}
.auth-form-row input[type="password"] {
  text-transform: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--secondary-color);
  transition: background-color 5000000s ease-in-out 0s;
  color: var(--grey-grey-500);
}
::-webkit-input-placeholder {
  color: var(--grey-grey-500);
}
:-ms-input-placeholder {
  color: var(--grey-grey-500);
}
::placeholder {
  color: var(--grey-grey-500);
}
.auth-form-row:hover .af-title,
.auth-form-row.active .af-title,
.auth-form-row:hover input[type="text"],
.auth-form-row.active input[type="text"],
.auth-form-row.active input[type="password"],
.auth-form-row:hover input[type="password"] {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}
.auth-form-row input[type="password"] {
  margin-top: -2px;
}
.auth-form-wrapper > .error {
  width: 100%;
  color: var(--status-red);
  font-size: 10px;
  font-family: var(--primary-font-regular);
  margin-top: 2px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
}
.auth-form-wrapper > .success {
  width: 100%;
  color: var(--status-green);
  font-size: 10px;
  font-family: var(--primary-font-regular);
  margin-top: 2px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
}
.auth-button-wrap {
  width: 100%;
}
.auth-button-wrap .btn {
  background: transparent;
}
.auth-button-wrap .btn:hover,
.auth-button-wrap .btn:focus {
  background: var(--secondary-color);
  color: var(--primary-color);
}
.btn-focus {
  background: var(--secondary-color) !important;
  color: var(--primary-color);
}
.auth-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 1rem 0 0;
}
.auth-footer-text {
  text-transform: uppercase;
  font-family: var(--primary-font);
  font-size: 12px;
  color: var(--grey-grey-800);
}
.auth-footer-link {
  text-transform: uppercase;
  font-family: var(--primary-font);
  color: var(--secondary-color);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.auth-close {
  width: 76px;
  height: 28px;
  padding: 0 5px;
  border-radius: 4px;
  background: var(--secondary-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 1;
  cursor: pointer;
}
.authclose-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authclose-icon svg {
  width: 13px;
  height: 13px;
  fill: var(--primary-color);
}
.auth-close-text {
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: 104%;
  text-transform: uppercase;
  font-family: var(--primary-font);
}

.welcome-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.welcome-logo img {
  filter: drop-shadow(0px 0px 0px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 6px 14px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 25px 25px rgba(3, 255, 133, 0.19))
    drop-shadow(
      0px 57.00000762939453px 34.00000762939453px rgba(3, 255, 133, 0.11)
    )
    drop-shadow(0px 102.00001525878906px 41px rgba(3, 255, 133, 0.03))
    drop-shadow(0px 159px 44px rgba(3, 255, 133, 0));
}

.welcome-title {
  color: var(--secondary-color);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 84%;
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.welcome-footer-text {
  font-family: var(--primary-font-regular);
  font-size: 1rem;
  color: var(--grey-grey-800);
}

.fp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.fp-container img {
  filter: drop-shadow(0px 0px 0px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 6px 14px rgba(3, 255, 133, 0.22))
    drop-shadow(0px 25px 25px rgba(3, 255, 133, 0.19))
    drop-shadow(
      0px 57.00000762939453px 34.00000762939453px rgba(3, 255, 133, 0.11)
    )
    drop-shadow(0px 102.00001525878906px 41px rgba(3, 255, 133, 0.03))
    drop-shadow(0px 159px 44px rgba(3, 255, 133, 0));
}

.fp-header {
  width: 375px;
}
.fp-header h4 {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%; /* 53.76px */
  letter-spacing: -0.64px;
  text-transform: uppercase;
}

.fp-subtitle {
  align-self: stretch;
}

.fp-subtitle p {
  color: var(--grey-grey-400, #dadada);
  text-align: center;

  /* Paragraph/Medium Text */
  font-family: var(--primary-font-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.07px;
}

.fp-footer-wrap {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
}

.fp-footer {
  display: flex;
  padding: 24px 10px 16px 10px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.fp-footer p {
  color: var(--plex-dark-grey, #707070);
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.fp-footer .href {
  color: var(--plex-white, #fff);
  text-decoration-line: underline;
  cursor: pointer;
}

.fp-modal-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Use rgba for opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.fp-modal-wrap {
  display: inline-flex;
  padding: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;

  border-radius: var(--edge-4, 4px);
  background: var(--brand-white, #fff);
  box-shadow: 0px 0px 0px 0px rgba(37, 14, 14, 0.33),
    30px 22px 83px 0px rgba(37, 14, 14, 0.32),
    120px 90px 150px 0px rgba(37, 14, 14, 0.28),
    271px 202px 203px 0px rgba(37, 14, 14, 0.16),
    481px 359px 240px 0px rgba(37, 14, 14, 0.05),
    752px 561px 263px 0px rgba(37, 14, 14, 0.01);
}

.fp-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.fp-modal-header p {
  color: var(--brand-black, #000);
  text-align: center;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
  font-family: var(--secondary-font-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.09px;
}

.fp-modal-wrap p {
  color: var(--brand-black, #000);
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}
.fp-container > .error {
    width: 100%;
    color: var(--status-red);
    font-size: 10px;
    font-family: var(--primary-font-regular);
    margin-top: 2px;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    margin:-8px 0;
}
.margin-0{
    margin:0px !important;
    margin-top:0px !important;
}
#cPassword
{
    margin-top:0px !important;
}
.fp-container > .success {
    width: 100%;
    color: var(--status-green);
    font-size: 10px;
    font-family: var(--primary-font-regular);
    margin-top: 2px;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    margin: -8px 0;
}
.modal-button {
  display: flex;
  width: 279px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--grey-grey-600, #545454);
  background: var(--brand-white, #fff);
  cursor: pointer;
}

.modal-button:hover {
  display: flex;
  width: 279px;
  padding: var(--edge-12, 12px) 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--brand-white, #fff);
  background: var(--grey-grey-900, #1c1c1c);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
}

.modal-button:hover p {
  color: var(--brand-white, #fff);

  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.6);
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.af-title.reset {
  flex: 0 0 135px;
}

.auth-form-row.reset {
  margin-top: 0;
}

.auth-button-wrapper {
  display: flex;
  width: 241px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 32px;
}

.auth-buttons {
  display: flex;
  height: 39px;
  padding: 19px 28px;
  flex-direction: column;
  justify-content: center;
  /* gap: 7px; */
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--brand-white, #fff);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.6);
  align-content: flex-end;
  align-items: stretch;
  cursor: pointer;
}

.auth-button-content {
  height: 23px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-button-content p {
  color: var(--neutral-colors-black, #1d1c2b);

  /* Mono Labels/Medium Labels */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.button-separator {
  color: var(--plex-dark-grey, #707070);
  text-align: center;
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.align-self-center {
  align-self: center !important;
}
