.snackbar-container {
  display: flex;
  position: absolute;
  height: 70px;
  left: 10px;
  z-index: 999999;
  width: 340px;
  padding: 12px 8px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: rgba(28, 28, 28, 0.9);

  /* Notification/Blur+Shadow */
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.2),
    0px 32px 32px 0px rgba(0, 0, 0, 0.18), 0px 71px 43px 0px rgba(0, 0, 0, 0.1),
    0px 126px 50px 0px rgba(0, 0, 0, 0.03), 0px 197px 55px 0px rgba(0, 0, 0, 0),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(30px);
  bottom: 0;
  opacity: 1;
  transform: translateY(100vh); /* starts offscreen */
  transition: transform 0.3s ease-out, opacity 0.1s ease-out; /* added opacity to the transition */
}

.snackbar-entering,
.snackbar-entered {
  transform: translateY(0); /* moves to its original position */
}

.snackbar-removed {
  opacity: 0; /* fades out the snackbar */
  transform: translateY(30px); /* optionally moves the snackbar down a bit */
}

.snackbar-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.snackbar-text h3 {
  align-self: stretch;
  /* Headings/Heading 5 */
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.snackbar-text p {
  align-self: stretch;
  color: var(--grey-grey-400, #dadada);
  font-family: var(--secondary-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.snackbar-text.medium h3 {
  color: var(--status-orange, #ffa71a);
  /* Glow/Orange */
  text-shadow: 0px 0px 5px rgba(255, 167, 26, 0.85),
    0px 0px 1px rgba(255, 167, 26, 0.5), 0px 1px 1px rgba(255, 167, 26, 0.15),
    0px 1px 41px rgba(255, 167, 26, 0.02);
}

.snackbar-text.low h3 {
  color: var(--accents-64, #f2ef4c);

  /* Glow/Orange */
  text-shadow: 0px 0px 5px rgba(255, 167, 26, 0.85),
    0px 0px 1px rgba(255, 167, 26, 0.5), 0px 1px 1px rgba(255, 167, 26, 0.15),
    0px 1px 41px rgba(255, 167, 26, 0.02);
}

.snackbar-text.high h3 {
  color: var(--status-red, #ff4d4d);
  /* Glow/Red */
  text-shadow: 0px 0px 5px rgba(255, 77, 77, 0.85),
    0px 0px 1px rgba(255, 77, 77, 0.5), 0px 1px 1px rgba(255, 77, 77, 0.15),
    0px 1px 41px rgba(255, 77, 77, 0.02);
}

.snackbar-text.bonus h3 {
  color: var(--status-green, #00ff85);
  /* Glow/Green */
  text-shadow: 0px 4px 4px rgba(4, 233, 13, 0.5);
}

.snackbar-container:hover .snackbar-close-button {
  display: flex;
}

.snackbar-close-button {
  display: none;
  padding: 6.144px;
  align-items: flex-start;
  gap: 7.68px;
  position: absolute;
  left: -14.224px;
  top: -14.224px;
  cursor: pointer;
}

.scb-button {
  display: flex;
  width: 23.04px;
  height: 23.04px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 23.04px;
  background: var(--grey-grey-500, #808080);
}
