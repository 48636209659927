/* .sidebar right */
.sidebar-right{ width:8px; height: 100%; position: relative;background:var(--primary-color);z-index:555; display: flex; flex-shrink: 0; flex-grow: 0; }
.sidebar-right-nav{right:8px; width:38px; height:auto !important;  background:var(--primary-color);  position: absolute; z-index:555; top:50%; transform: translateY(-50%); display: flex; flex-direction: column; gap:1rem;  padding: 8px 0; }
.sidebar-right-nav::before{content: ''; width:38px; height:38px; position:absolute; top:-37px; left:0; z-index:2;background:url("../../images/right-bar-corner-top.svg") no-repeat 0 0; background-size:100%;display:inline-block;vertical-align:top;}
.sidebar-right-nav::after{content: ''; width:38px; height:38px; position:absolute; bottom:-37px; left:0; z-index:2;background:url("../../images/right-bar-corner-bottom.svg") no-repeat 0 0;background-size:100%;display:inline-block;vertical-align:top;}
.sidebar-right-nav p{writing-mode:vertical-rl; text-align: center; height: 100%; padding:0 0 0 5px; color: var(--secondary-color);font-family: var(--primary-font); font-weight: normal; font-size: .75rem; margin: 0; }
.sidebar-right-nav > .sr-icon{ width:100%; cursor: pointer;display: flex; align-items: center; justify-content: flex-end;}
.sidebar-right-nav.m{ display: none;}

@media(max-width:1023px){
    .sidebar-right-nav.d{ display: none !important;}
    .sidebar-right-nav.m{ display: flex !important; gap:12px; padding: 0 !important; min-height: 86px; justify-content: center; }
    .sidebar-right.open{ z-index:992;}
    .sidebar-right-nav.m .sr-icon{ width: 100%; justify-content: center;}
    .sidebar-right-nav.m .sr-icon.energy-icon img{transform: translateX(3px);}
}
