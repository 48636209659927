.profile-content-wrap{ width:100%; height: auto; display: flex; justify-content: space-between; align-items: flex-start; gap:1.5rem ; }
.p-content{ display: flex; flex-direction: column; gap: 1rem; align-self: stretch;}
.p-content .auth-button-wrap{ margin-top: auto; padding-bottom:3px;}

.p-details{flex: 1 1 415px;}
.detail-form-wrapper{ display: flex; flex-direction: column !important; }
.detail-form-wrapper > .error{ width:100%; color: var(--status-red); font-size: 10px; font-family: var(--primary-font-regular); margin-top: 2px;text-shadow: 0px 0px 6px  rgba(255, 255, 255, 0.60); text-transform: uppercase;}
.detail-form-wrapper > .success{ width:100%; color: var(--status-green); font-size: 10px; font-family: var(--primary-font-regular); margin-top: 2px;text-shadow: 0px 0px 6px  rgba(255, 255, 255, 0.60); text-transform: uppercase;}
.image-upload-wrap{ width:100%; display: flex; justify-content: flex-start; gap:8px; align-items: center;}
.image-upload-wrap .btn{ background: transparent; width:100px}
.image-upload-wrap .btn:hover{ background: var(--secondary-color);color: var(--primary-color);}

.p-faction{ flex: 1 1 511px; order:0}
.p-faction-content{ width:100%; height: auto; position: relative;}
.p-faction-content img{ border-radius:8px; width: 100%;}

.p-id-card{ flex:1 1 376px; order:1}

.p-id-card-content{ width:100%; display: flex; flex-direction: column; gap: 1.5rem; align-self: stretch; flex:1}
.id-car-wrap{ width:100%; height:auto}
.id-car-wrap img{width: 100%;}
.id-card-content{font-size:1rem;font-style: normal;font-weight: 400;line-height: 110%;letter-spacing: -0.16px; color: var(--secondary-color); font-family: var(--primary-font-regular);}
.id-card-btn-wrap{ width:100%; margin-top: auto; padding-bottom: 3px;}


.profile-nav-wrap{ width:100%; height: auto; display: flex; justify-content: center; align-items: center; position: fixed; bottom: 1rem; left: 0; z-index: 6;}
.profile-nav-wrap > ul{ max-width: 395px; width:90%; margin: 0 auto; padding:.875rem; display: flex; justify-content: space-between; align-items: center; gap: 12px; list-style-type: none; border-radius: 8px;background: rgba(0, 0, 0, 0.40);backdrop-filter: blur(12px);}
.profile-nav-wrap > ul > li {font-size: 12px;font-style: normal;line-height: normal;letter-spacing: 0.36px;text-transform: uppercase; color:var(--secondary-color); padding: 8px; cursor: pointer; border:1px solid transparent}
.profile-nav-wrap > ul > li:hover,.profile-nav-wrap > ul > li:focus,.profile-nav-wrap > ul > li:active, .profile-nav-wrap > ul > li.active{border-radius: 2px;border: 1px solid var(--status-green);background: rgba(0, 255, 133, 0.10);text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.60);}

.p-faction-info-wrap{ width: auto; display: flex; flex-direction: column; gap: 10px; position: absolute; bottom:1rem; left: 1rem; z-index: 1; transition: all 0.3s ease; opacity: 0.7;}
.p-faction-title{text-shadow: 0px 0px 3.4946069717407227px rgba(255, 255, 255, 0.60);color: var(--secondary-color);font-family:var(--secondary-font);font-size: 2.6209rem;font-style: normal;font-weight: 700;line-height: 84%; /* 35.226px */letter-spacing: -0.419px;text-transform: uppercase;}
.p-faction-btn-wrap .btn{width:108px;height: 36px;}
.licence-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    padding: 3px;
}




@media(max-width:1199px){
    .profile-content-wrap{ flex-wrap: wrap; padding-bottom: 4rem; justify-content: center; align-items: center;}
    .p-faction{ flex:1 1 100%; order: 1;}
    .p-id-card{ flex:0 1 376px;order:0}    
}

@media(max-width:767px){
    .profile-nav-wrap > ul{padding: 0.5rem; gap: 5px;}
    .profile-nav-wrap > ul li{ padding: 6px;}
    .p-faction-logo{ width: 70px;}
    .profile-content-wrap{ flex-wrap: wrap; padding-bottom:3rem;}
    
}
@media(max-width:550px){
    .profile-nav-wrap > ul > li{ font-size: 10px;}
    .p-faction-title{ font-size: 2rem;}
    .p-faction-logo{ width: 70px;}
    .p-id-card{ flex:1 1 376px}
    .p-details{flex: 1 1 415px;}
}